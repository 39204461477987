import React, { Component } from "react";
import LayoutPanelFormulario from "../../components/layouts/panels/LayoutPanelFormulario";
import HTTP from "../../helpers/HTTP";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import es from "../../helpers/ValidatorTranslate_es";
import Select from "react-select";

export default class CargosMinisterialesForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.inicial_state;
    this.init_validator();
  }

  inicial_state = {
    title: this.props.match.params.id
      ? "Actualización de Cargo Ministerial"
      : "Registro de Cargo Ministerial",

    nombre: "",
    tipo: "",
    categoria: "",
    categorias: [],
    posicion: "",
    tipos: [],
    loading: false,

    redirect: false,
    disabled_select_tipo: true,
    disabled_select_categoria: true,
  };
  handleInputChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    this.setState({ [idComponente]: valorComponente });
  };

  componentDidMount() {
    document.getElementById("nombre").focus();

    this.getById();
    this.setState({
      tipos: this.getTipos(),
      categorias: this.getCategorias(),
    });
  }

  getTipos() {
    let data = [];

    HTTP.findAll("generales/comodin/tipos_cargo_ministeriales").then(
      (result) => {
        result.forEach((element) => {
          data.push({ label: element.nombre, value: element.id });
        });
        this.setState({ disabled_select_tipo: false });
      }
    );

    return data;
  }

  getCategorias() {
    let data = [];

    HTTP.findAll("generales/categorias_cargos").then((result) => {
      result.forEach((element) => {
        data.push({ label: element.nombre, value: element.id });
      });
      this.setState({ disabled_select_categoria: false });
    });

    return data;
  }
  init_validator() {
    SimpleReactValidator.addLocale("esp", es);
    this.validator = new SimpleReactValidator({ locale: "esp" });
  }

  hide_alert_validator() {
    this.validator.hideMessages();
    this.forceUpdate();
  }

  getById() {
    if (this.props.match.params.id) {
      HTTP.findById(this.props.match.params.id, "cargo_ministerial").then(
        (result) => {
          if (result !== false) {
            this.setState(result);
          } else {
            this.setState({ redirect: true });
          }
        }
      );
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ loading: true });

      if (this.props.match.params.id) {
        const data = {
          code: this.props.match.params.id,
          nombre: this.state.nombre,
          tipo:
            this.state.tipo !== "" && this.state.tipo !== null
              ? this.state.tipo.value
              : null,
          categoria:
            this.state.categoria !== "" && this.state.categoria !== null
              ? this.state.categoria.value
              : null,
          posicion: this.state.posicion,
        };
        HTTP.update(
          data,
          "cargo ministerial",
          "cargos ministeriales",
          "cargo_ministerial"
        ).then((result) => {
          this.setState({ loading: false });
          if (result !== false) {
            this.setState({ redirect: true });
          }
        });
      } else {
        const data = {
          nombre: this.state.nombre,
          tipo:
            this.state.tipo !== "" && this.state.tipo !== null
              ? this.state.tipo.value
              : null,
          categoria:
            this.state.categoria !== "" && this.state.categoria !== null
              ? this.state.categoria.value
              : null,
          posicion: this.state.posicion,
        };
        HTTP.create(
          data,
          "cargo ministerial",
          "cargos ministeriales",
          "cargo_ministerial"
        ).then((result) => {
          this.setState({ loading: false });
          if (result !== false) {
            this.setState({ redirect: true });
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/recursos_humanos/cargos_ministeriales" />;
    }
    return (
      <React.Fragment>
        <LayoutPanelFormulario
          titulo={this.state.title}
          submit={this.onSubmit}
          buttons={
            <div className="card-footer text-center">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-outline-inverse mr-2 "
                  onClick={() => {
                    this.setState({ redirect: true });
                  }}
                  disabled={this.state.loading}
                >
                  <i className="fa fa-close mr-2"></i>CANCELAR
                </button>

                <button
                  type="submit"
                  className="btn btn-info"
                  disabled={this.state.loading}
                >
                  <i className="fa fa-save mr-2"></i>
                  {this.state.loading === false ? "GUARDAR" : "GUARDANDO..."}
                </button>
              </div>
            </div>
          }
        >
          <div className="form-body">
            <div className="row p-t-20">
              <div className="col-lg-3 form-group">
                <label htmlFor="">Nombre Cargo: (*)</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre"
                  id="nombre"
                  value={this.state.nombre}
                  onChange={this.handleInputChange}
                />
                {this.validator.message(
                  "nombre cargo",
                  this.state.nombre,
                  "required"
                ) && (
                  <span className="label label-light-danger">
                    {this.validator.message(
                      "nombre cargo",
                      this.state.nombre,
                      "required"
                    )}
                  </span>
                )}
              </div>
              <div className="col-lg-3 form-group">
                <label htmlFor="">Tipo Cargo: (*)</label>

                <Select
                  id="tipo"
                  name="tipo"
                  placeholder="Selecione una opción"
                  options={this.state.tipos}
                  value={this.state.tipo}
                  isClearable={true}
                  isDisabled={this.state.disabled_select_tipo}
                  onChange={(e) => {
                    this.setState({ tipo: e });
                  }}
                />
                {this.validator.message(
                  "Tipo documento",
                  this.state.tipo,
                  "required"
                ) && (
                  <span className="label label-light-danger">
                    {this.validator.message(
                      "Tipo documento",
                      this.state.tipo,
                      "required"
                    )}
                  </span>
                )}
              </div>
              <div className="col-lg-3 form-group">
                <label htmlFor="">Categoría Cargo: (*)</label>

                <Select
                  id="categoria"
                  name="categoria"
                  placeholder="Selecione una opción"
                  options={this.state.categorias}
                  value={this.state.categoria}
                  isClearable={true}
                  isDisabled={this.state.disabled_select_categoria}
                  onChange={(e) => {
                    this.setState({ categoria: e });
                  }}
                />
                {this.validator.message(
                  "categoría",
                  this.state.categoria,
                  "required"
                ) && (
                  <span className="label label-light-danger">
                    {this.validator.message(
                      "categoría",
                      this.state.categoria,
                      "required"
                    )}
                  </span>
                )}
              </div>
              <div className="col-lg-3 form-group">
                <label htmlFor="">Posición en informes: (*)</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Posición en informes"
                  id="posicion"
                  value={this.state.posicion}
                  onChange={this.handleInputChange}
                />
                {this.validator.message(
                  "posición en informes",
                  this.state.posicion,
                  "required"
                ) && (
                  <span className="label label-light-danger">
                    {this.validator.message(
                      "posición en informes",
                      this.state.posicion,
                      "required"
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
        </LayoutPanelFormulario>
      </React.Fragment>
    );
  }
}
