import React, { Component } from "react";
import HTTP from "../../helpers/HTTP";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import es from "../../helpers/ValidatorTranslate_es";
// import Request from "../../services/Request";
// import Alerts from "../../services/Alerts";
// import TablaFilter from "../../components/tablas/TablaFilter";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import LayoutPanelEmpty from "../../components/layouts/panels/LayoutPanelEmpty";
// import ExtFile from "../../services/ExtFile";
import Encrypt from "../../services/Encrypt";
// import Iframe from "react-iframe";
// import { saveAs } from "file-saver";
//import { isBrowser } from "react-device-detect";
import ContentLoader from "react-content-loader";
// import DateValidator from "../../services/DateValidator";
// import NormalizeText from "../../helpers/NormalizeText";
import { Tabs, Tab } from "react-bootstrap";
import PersonaFormulario from "../../components/Personas/PersonaFormulario";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import LayoutPanelFormulario from "../../components/layouts/panels/LayoutPanelFormulario";
import Request from "../../services/Request";
import Alerts from "../../services/Alerts";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TablaFilter from "../../components/tablas/TablaFilter";
import { Fragment } from "react";
export default class MebresiaIglesiaForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.inicial_state;
    this.toggle = this.toggle.bind(this);
    this.init_validator();
  }

  inicial_state = {
    loading: false,
    loadingData: false,
    tab_active: "datos_personales",
    isModalOpen: false,
    isForUpdate: false,

    idPersona: null,
    iglesia: null,
    redirect: false,
    disabled_select_tipo_documento: true,
    datos_iglesia_cargados: false,
    disabled_select_tipo_miembros: true,
    disabled_select_ministros: true,
    //estados para persona
    nombres: "",
    apellidos: "",
    apellido_casada: "",
    tipo_documento: "",
    numero_documento: "",
    nombre_conyugue: "",
    telefono: "",
    correo: "",
    sexo: "",
    estado_civil: "",
    nacionalidad: "",
    profesion: "",
    fotografia: null,
    direccion: "",
    departamento_nacimiento: "",
    municipio_nacimiento: "",
    canton_nacimiento: "",
    fecha_nacimiento: "",
    departamento_residencia: "",
    municipio_residencia: "",
    canton_residencia: "",
    //fin estado personas

    //estados miembros
    codigoMiembro: null,
    tipoMiembro: "",
    conviccionIglesia: 0,
    tipos_miembros: [],
    fechaRecibimiento: "",
    bautizado: 0,
    acuerdoDoctrina: 0,
    areasTrabajo: "",
    lugarBautismo: "",
    ministroBautismo: "",
    ministroPacto: "",
    lugarPacto: "",
    fechaPacto: "",
    ministros: [],
    fechaBautismo: "",
    codigo_persona: null,
    fecha_matrimonio: "",
    //fin estados miembros
  };

  handleInputChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    this.setState({ [idComponente]: valorComponente });
  };
  handleChecked = (e) => {
    const idComponente = e.target.id;

    if (document.getElementById(idComponente).checked) {
      this.setState({ [idComponente]: 1 });
    } else {
      this.setState({ [idComponente]: 0 });
    }
  };
  componentDidMount() {
    // document.getElementById("nombre").focus();
    this.getIglesia();
    this.setState({
      tipos_documentos: this.getTiposDocumentos(),
      tipos_miembros: this.getTiposMiembros(),
      ministros: this.getMinistros(),
    });
    if (this.props.match.params.codigo) {
      this.setState({
        isForUpdate: true, //Es para actualizar persona
      });
      this.getMiembroData();
    }
  }
  timer_ministros = null;
  getMinistrosParam = (inputValue, callback) => {
    const tempArray = [];

    if (inputValue !== "" && inputValue !== null) {
      clearTimeout(this.timer_ministros);
      this.timer_ministros = setTimeout(() => {
        HTTP.findById(inputValue, `pastores/select_ministros_obispos`).then(
          (data) => {
            if (data !== false) {
              data.forEach((element) => {
                tempArray.push({
                  label: element.nombre,
                  value: element.codigo,
                });
              });
              callback(tempArray);
            } else {
              callback([]);
            }
          }
        );
      }, 500);
    }
  };
  getMinistros() {
    let data = [];

    HTTP.findAll(`pastores/select_ministros_obispos`).then((result) => {
      result.forEach((element) => {
        data.push({
          label: element.nombre,
          value: element.codigo,
        });
      });

      this.setState({ disabled_select_ministros: false });
    });

    return data;
  }

  getTiposMiembros() {
    let data = [];

    HTTP.findAll("generales/tipo_miembros").then((result) => {
      result.forEach((element) => {
        data.push({ label: element.nombre, value: element.id });
      });
      this.setState({ disabled_select_tipo_miembros: false });
    });

    return data;
  }
  getTiposDocumentos() {
    let data = [];

    HTTP.findAll("generales/tipo_documento").then((result) => {
      result.forEach((element) => {
        data.push({ label: element.nombre, value: element.id });
      });
      this.setState({ disabled_select_tipo_documento: false });
    });

    return data;
  }
  componentWillUnmount() {}

  getIglesia() {
    if (this.props.match.params.iglesia) {
      HTTP.findById(this.props.match.params.iglesia, "iglesias/detalle").then(
        (result) => {
          if (result !== false) {
            this.setState({
              iglesia: result,
              datos_iglesia_cargados: true,
            });
          } else {
            this.setState({ redirect: true });
          }
        }
      );
    }
  }
  init_validator() {
    SimpleReactValidator.addLocale("esp", es);
    this.validator = new SimpleReactValidator({
      validators: {
        numeroExiste: {
          message: "El :attribute ya existe",
          rule: (val) => {
            return !val;
          },
        },
        duiInvalido: {
          message: "El número de DUI es inválido",
          rule: (val) => {
            return val;
          },
        },
      },
      locale: "esp",
    });
  }

  hide_alert_validator() {
    this.validator.hideMessages();
    this.forceUpdate();
  }
  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={800} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="700" />
        </ContentLoader>
      </React.Fragment>
    );
  }

  loadingNav() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 60" height={"25"} width={"100%"}>
          <rect x="0" y="3" rx="5" ry="5" width="100%" height="25" />
        </ContentLoader>
      </React.Fragment>
    );
  }
  async handleChangeTab(key) {
    await this.setState({ tab_active: key });
  }
  estadoNoEsNullNiVacio = (value) =>
    value !== null && value !== "" && typeof value !== "undefined";

  saveData = (keyElemento, valorElemento) => {
    this.setState({ [keyElemento]: valorElemento });
  };
  getMiembroData() {
    this.setState({
      loadingData: true,
    });
    HTTP.findById(this.props.match.params.codigo, "miembro").then((result) => {
      if (result !== false) {
        // if (result.estado !== false) {
        this.setState({
          tipoMiembro: this.state.tipos_miembros.find(
            (tipo) => tipo.value === result.tipoMiembro
          ),
          conviccionIglesia: result.conviccionIglesia,
          fechaRecibimiento: result.fechaRecibimiento,
          bautizado: result.bautizado,
          acuerdoDoctrina: result.acuerdoDoctrina,
          areasTrabajo: result.areasTrabajo,
          lugarBautismo: result.lugarBautismo,
          ministroBautismo: this.state.ministros.find(
            (ministro) => ministro.value === result.ministroBautismo
          ),
          ministroPacto: this.state.ministros.find(
            (ministro) => ministro.value === result.ministroPacto
          ),
          lugarPacto: result.lugarPacto,
          fechaPacto: result.fechaPacto,
          fechaBautismo: result.fechaBautismo,
          codigo_persona: result.persona,
          codigoMiembro: result.codigo,
          idPersona: result.idPersona,
          nombres: result.nombres !== null ? result.nombres : "",
          apellidos: result.apellidos !== null ? result.apellidos : "",
          telefono: result.telefono !== null ? result.telefono : "",
          sexo:
            result.sexo !== null
              ? { label: result.sexo, value: result.sexo_id }
              : "",
          correo: result.correo !== null ? result.correo : "",
          nacionalidad:
            result.nacionalidad !== null
              ? { label: result.nacionalidad, value: result.nacionalidad_id }
              : "",
          fecha_nacimiento:
            result.fecha_nacimiento !== null ? result.fecha_nacimiento : "",
          departamento_nacimiento:
            result.departemento_nacimiento !== null
              ? {
                  label: result.departemento_nacimiento,
                  value: result.departemento_nacimiento_id,
                }
              : "",
          municipio_nacimiento:
            result.municipio_nacimiento !== null
              ? {
                  label: result.municipio_nacimiento,
                  value: result.municipio_nacimiento_id,
                }
              : "",
          canton_nacimiento:
            result.canton_nacimiento !== null
              ? {
                  label: result.canton_nacimiento,
                  value: result.canton_nacimiento_id,
                }
              : "",
          departamento_residencia:
            result.departamento_residencia !== null
              ? {
                  label: result.departamento_residencia,
                  value: result.departamento_residencia_id,
                }
              : "",
          municipio_residencia:
            result.municipio_residencia !== null
              ? {
                  label: result.municipio_residencia,
                  value: result.municipio_residencia_id,
                }
              : "",
          canton_residencia:
            result.canton_residencia !== null
              ? {
                  label: result.canton_residencia,
                  value: result.canton_residencia_id,
                }
              : "",
          tipo_documento:
            result.tipo_documento !== null
              ? {
                  label: result.tipo_documento,
                  value: result.tipo_documento_id,
                }
              : "",
          numero_documento:
            result.numero_documento !== null ? result.numero_documento : "",
          estado_civil:
            result.estado_civil !== null
              ? { label: result.estado_civil, value: result.estado_civil_id }
              : "",
          profesion:
            result.profesion_oficio !== null
              ? {
                  label: result.profesion_oficio,
                  value: result.profesion_oficio_id,
                }
              : "",
          direccion: result.direccion !== null ? result.direccion : "",
          fecha_matrimonio:
            result.fecha_matrimonio !== null ? result.fecha_matrimonio : "",
          nombre_conyugue:
            result.nombre_conyugue !== null ? result.nombre_conyugue : "",
          apellido_casada:
            result.apellido_casada !== null ? result.apellido_casada : "",
        });
        this.setState({
          loadingData: false,
        });
        //this.getPersonaData(this.state.codigo_persona);
      } else {
        this.setState({ redirect: true });
      }
    });
  }
  getPersonaData(codigo) {
    HTTP.findById(codigo, "personas").then((result) => {
      if (result !== false) {
        // if (result.estado !== false) {
        this.setState({
          idPersona: result.estado.id,
          codigoPersona: result.estado.codigo,
          nombres: result.estado.nombres !== null ? result.estado.nombres : "",
          apellidos:
            result.estado.apellidos !== null ? result.estado.apellidos : "",
          telefono:
            result.estado.telefono !== null ? result.estado.telefono : "",
          sexo: result.estado.sexo !== null ? result.estado.sexo : "",
          correo: result.estado.correo !== null ? result.estado.correo : "",
          nacionalidad:
            result.estado.nacionalidad.label !== null
              ? result.estado.nacionalidad
              : "",
          fecha_nacimiento:
            result.estado.fecha_nacimiento !== null
              ? result.estado.fecha_nacimiento
              : "",
          departamento_nacimiento:
            result.estado.departemento_nacimiento.label !== null
              ? result.estado.departemento_nacimiento
              : "",
          municipio_nacimiento:
            result.estado.municipio_nacimiento.label !== null
              ? result.estado.municipio_nacimiento
              : "",
          canton_nacimiento:
            result.estado.canton_nacimiento.label !== null
              ? result.estado.canton_nacimiento
              : "",
          departamento_residencia:
            result.estado.departamento_residencia.label !== null
              ? result.estado.departamento_residencia
              : "",
          municipio_residencia:
            result.estado.municipio_residencia.label !== null
              ? result.estado.municipio_residencia
              : "",
          canton_residencia:
            result.estado.canton_residencia.label !== null
              ? result.estado.canton_residencia
              : "",
          tipo_documento:
            result.estado.tipo_documento.label !== null
              ? result.estado.tipo_documento
              : "",
          numero_documento:
            result.estado.numero_documento !== null
              ? result.estado.numero_documento
              : "",
          estado_civil:
            result.estado.estado_civil.label !== null
              ? result.estado.estado_civil
              : "",
          profesion:
            result.estado.profesion_oficio.label !== null
              ? result.estado.profesion_oficio
              : "",
          direccion:
            result.estado.direccion !== null ? result.estado.direccion : "",
          fecha_matrimonio:
            result.estado.fecha_matrimonio !== null
              ? result.estado.fecha_matrimonio
              : "",
          nombre_conyugue:
            result.estado.nombre_conyugue !== null
              ? result.estado.nombre_conyugue
              : "",
          apellido_casada:
            result.estado.apellido_casada !== null
              ? result.estado.apellido_casada
              : "",
        });
        Alerts.loading_reload(false);
      } else {
      }
    });
  }
  onSubmit = async (e) => {
    e.preventDefault();

    if (this.props.match.params.codigo) {
      if (this.validator.allValid()) {
        let miembro = {};
        this.estadoNoEsNullNiVacio(this.state.codigoMiembro) &&
          (miembro.codigoMiembro = this.state.codigoMiembro);
        this.estadoNoEsNullNiVacio(this.state.idPersona) &&
          (miembro.id = this.state.idPersona);
        this.estadoNoEsNullNiVacio(this.state.nombres) &&
          (miembro.nombre = this.state.nombres);
        this.estadoNoEsNullNiVacio(this.state.apellidos) &&
          (miembro.apellido = this.state.apellidos);
        this.estadoNoEsNullNiVacio(this.state.tipo_documento) &&
          (miembro.tipoDocumento = this.state.tipo_documento.value);
        this.estadoNoEsNullNiVacio(this.state.numero_documento) &&
          (miembro.numeroDocumento = this.state.numero_documento);
        this.estadoNoEsNullNiVacio(this.state.telefono) &&
          (miembro.telefono = this.state.telefono);
        this.estadoNoEsNullNiVacio(this.state.correo) &&
          (miembro.correo = this.state.correo);
        this.estadoNoEsNullNiVacio(this.state.sexo) &&
          (miembro.sexo = this.state.sexo.value);
        this.estadoNoEsNullNiVacio(this.state.estado_civil) &&
          (miembro.estadoCivil = this.state.estado_civil.value);
        this.estadoNoEsNullNiVacio(this.state.nacionalidad) &&
          (miembro.nacionalidad = this.state.nacionalidad.value);
        this.estadoNoEsNullNiVacio(this.state.profesion) &&
          (miembro.profesion = this.state.profesion.value);
        this.estadoNoEsNullNiVacio(this.state.fotografia) &&
          (miembro.fotoPerfil = this.state.fotografia);
        this.estadoNoEsNullNiVacio(this.state.direccion) &&
          (miembro.direccion = this.state.direccion);
        this.estadoNoEsNullNiVacio(this.state.departamento_nacimiento) &&
          (miembro.departamentoNacimiento =
            this.state.departamento_nacimiento.value);
        this.estadoNoEsNullNiVacio(this.state.municipio_nacimiento) &&
          (miembro.municipioNacimiento = this.state.municipio_nacimiento.value);
        this.estadoNoEsNullNiVacio(this.state.canton_nacimiento) &&
          (miembro.cantonNacimiento = this.state.canton_nacimiento.value);
        this.estadoNoEsNullNiVacio(this.state.fecha_nacimiento) &&
          (miembro.fechaNacimiento = this.state.fecha_nacimiento);
        this.estadoNoEsNullNiVacio(this.state.departamento_residencia) &&
          (miembro.departamentoResidencia =
            this.state.departamento_residencia.value);
        this.estadoNoEsNullNiVacio(this.state.municipio_residencia) &&
          (miembro.municipioResidencia = this.state.municipio_residencia.value);
        this.estadoNoEsNullNiVacio(this.state.canton_residencia) &&
          (miembro.cantonResidencia = this.state.canton_residencia.value);
        this.estadoNoEsNullNiVacio(this.state.licenciaMinisterial) &&
          this.estadoNoEsNullNiVacio(this.state.apellido_casada) &&
          (miembro.apellidoCasada = this.state.apellido_casada);
        this.estadoNoEsNullNiVacio(this.state.fecha_matrimonio) &&
          (miembro.fechaMatrimonio = this.state.fecha_matrimonio);
        this.estadoNoEsNullNiVacio(this.state.nombre_conyugue) &&
          (miembro.nombreConyugue = this.state.nombre_conyugue);
        this.estadoNoEsNullNiVacio(this.state.tipoMiembro) &&
          (miembro.tipoMiembro = this.state.tipoMiembro.value);
        this.estadoNoEsNullNiVacio(this.state.conviccionIglesia) &&
          (miembro.conviccionIglesia = this.state.conviccionIglesia);
        this.estadoNoEsNullNiVacio(this.state.fechaRecibimiento) &&
          (miembro.fechaRecibimiento = this.state.fechaRecibimiento);
        this.estadoNoEsNullNiVacio(this.state.bautizado) &&
          (miembro.bautizado = this.state.bautizado);
        this.estadoNoEsNullNiVacio(this.state.acuerdoDoctrina) &&
          (miembro.acuerdoDoctrina = this.state.acuerdoDoctrina);
        this.estadoNoEsNullNiVacio(this.state.areasTrabajo) &&
          (miembro.areasTrabajo = this.state.areasTrabajo);
        this.estadoNoEsNullNiVacio(this.state.lugarBautismo) &&
          (miembro.lugarBautismo = this.state.lugarBautismo);
        this.estadoNoEsNullNiVacio(this.state.ministroBautismo) &&
          (miembro.ministroBautismo = this.state.ministroBautismo.value);
        this.estadoNoEsNullNiVacio(this.state.ministroPacto) &&
          (miembro.ministroPacto = this.state.ministroPacto.value);
        this.estadoNoEsNullNiVacio(this.state.lugarPacto) &&
          (miembro.lugarPacto = this.state.lugarPacto);
        this.estadoNoEsNullNiVacio(this.state.fechaPacto) &&
          (miembro.fechaPacto = this.state.fechaPacto);
        this.estadoNoEsNullNiVacio(this.state.fechaBautismo) &&
          (miembro.fechaBautismo = this.state.fechaBautismo);
        HTTP.update(miembro, "miembro", "miembros", "miembro").then(
          (result) => {
            if (result === true) this.setState({ redirect: true });
          }
        );
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    } else {
      if (this.validator.allValid()) {
        const data = new FormData();
        this.estadoNoEsNullNiVacio(this.props.match.params.iglesia) &&
          data.append("iglesia", this.props.match.params.iglesia);
        this.estadoNoEsNullNiVacio(this.state.fechaRecibimiento) &&
          data.append("fechaRecibimiento", this.state.fechaRecibimiento);
        this.estadoNoEsNullNiVacio(this.state.tipoMiembro) &&
          data.append("tipoMiembro", this.state.tipoMiembro.value);
        this.estadoNoEsNullNiVacio(this.state.bautizado) &&
          data.append("bautizado", this.state.bautizado);
        this.estadoNoEsNullNiVacio(this.state.lugarBautismo) &&
          data.append("lugarBautismo", this.state.lugarBautismo);
        this.estadoNoEsNullNiVacio(this.state.ministroBautismo) &&
          data.append("ministroBautismo", this.state.ministroBautismo.value);
        this.estadoNoEsNullNiVacio(this.state.ministroBautismo) &&
          data.append(
            "nombreMinistroBautismo",
            this.state.ministroBautismo.label
          );
        this.estadoNoEsNullNiVacio(this.state.conviccionIglesia) &&
          data.append("conviccionIglesia", this.state.conviccionIglesia);
        this.estadoNoEsNullNiVacio(this.state.acuerdoDoctrina) &&
          data.append("acuerdoDoctrina", this.state.acuerdoDoctrina);
        this.estadoNoEsNullNiVacio(this.state.areasTrabajo) &&
          data.append("areasTrabajo", this.state.areasTrabajo);
        this.estadoNoEsNullNiVacio(this.state.lugarPacto) &&
          data.append("lugarPacto", this.state.lugarPacto);
        this.estadoNoEsNullNiVacio(this.state.fechaPacto) &&
          data.append("fechaPacto", this.state.fechaPacto);
        this.estadoNoEsNullNiVacio(this.state.ministroPacto) &&
          data.append("ministroPacto", this.state.ministroPacto.value);
        this.estadoNoEsNullNiVacio(this.state.fechaBautismo) &&
          data.append("fechaBautismo", this.state.fechaBautismo);

        this.estadoNoEsNullNiVacio(this.state.codigoPersona) &&
          data.append("persona", this.state.codigoPersona);
        this.estadoNoEsNullNiVacio(this.state.nombres) &&
          data.append("nombre", this.state.nombres);
        this.estadoNoEsNullNiVacio(this.state.apellidos) &&
          data.append("apellido", this.state.apellidos);
        this.estadoNoEsNullNiVacio(this.state.tipo_documento) &&
          data.append("tipoDocumento", this.state.tipo_documento.value);
        this.estadoNoEsNullNiVacio(this.state.numero_documento) &&
          data.append("numeroDocumento", this.state.numero_documento);
        this.estadoNoEsNullNiVacio(this.state.telefono) &&
          data.append("telefono", this.state.telefono);
        this.estadoNoEsNullNiVacio(this.state.correo) &&
          data.append("correo", this.state.correo);
        this.estadoNoEsNullNiVacio(this.state.sexo) &&
          data.append("sexo", this.state.sexo.value);
        this.estadoNoEsNullNiVacio(this.state.estado_civil) &&
          data.append("estadoCivil", this.state.estado_civil.value);
        this.estadoNoEsNullNiVacio(this.state.nacionalidad) &&
          data.append("nacionalidad", this.state.nacionalidad.value);
        this.estadoNoEsNullNiVacio(this.state.profesion) &&
          data.append("profesion", this.state.profesion.value);
        this.estadoNoEsNullNiVacio(this.state.fotografia) &&
          data.append("fotoPerfil", this.state.fotografia);
        this.estadoNoEsNullNiVacio(this.state.direccion) &&
          data.append("direccion", this.state.direccion);
        this.estadoNoEsNullNiVacio(this.state.departamento_nacimiento) &&
          data.append(
            "departamentoNacimiento",
            this.state.departamento_nacimiento.value
          );
        this.estadoNoEsNullNiVacio(this.state.municipio_nacimiento) &&
          data.append(
            "municipioNacimiento",
            this.state.municipio_nacimiento.value
          );
        this.estadoNoEsNullNiVacio(this.state.canton_nacimiento) &&
          data.append("cantonNacimiento", this.state.canton_nacimiento.value);
        this.estadoNoEsNullNiVacio(this.state.fecha_nacimiento) &&
          data.append("fechaNacimiento", this.state.fecha_nacimiento);
        this.estadoNoEsNullNiVacio(this.state.departamento_residencia) &&
          data.append(
            "departamentoResidencia",
            this.state.departamento_residencia.value
          );
        this.estadoNoEsNullNiVacio(this.state.municipio_residencia) &&
          data.append(
            "municipioResidencia",
            this.state.municipio_residencia.value
          );
        this.estadoNoEsNullNiVacio(this.state.canton_residencia) &&
          data.append("cantonResidencia", this.state.canton_residencia.value);
        this.estadoNoEsNullNiVacio(this.state.fecha_matrimonio) &&
          data.append("fechaMatrimonio", this.state.fecha_matrimonio);
        this.estadoNoEsNullNiVacio(this.state.nombre_conyugue) &&
          data.append("nombreConyugue", this.state.nombre_conyugue);
        this.estadoNoEsNullNiVacio(this.state.apellido_casada) &&
          data.append("apellidoCasada", this.state.apellido_casada);
        Request.POST_FILE("miembro", data).then((result) => {
          if (result !== false) {
            if (result.status === 201) {
              Alerts.alertEmpty(
                "¡Miembro guardado con éxito",
                "Administración de membresía",
                "success"
              );
              this.setState({ redirect: true });
            } else {
              Alerts.alertEmpty(
                "¡Miembro no se ha podido guardar",
                "Administración de membresía",
                "error"
              );
            }
          }
        });
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  };
  resetPersona = () => {
    this.setState({
      idPersona: null,
      codigoPersona: null,
      nombres: "",
      apellidos: "",
      telefono: "",
      sexo: "",
      correo: "",
      correo_electronico_actual: "",
      nacionalidad: "",
      fecha_nacimiento: "",
      departamento_nacimiento: "",
      municipio_nacimiento: "",
      canton_nacimiento: "",
      departamento_residencia: "",
      municipio_residencia: "",
      canton_residencia: "",
      tipo_documento: "",
      numero_documento: "",
      estado_civil: "",
      profesion: "",
      direccion: "",
      fecha_matrimonio: "",
      nombre_conyugue: "",
      apellido_casada: "",
      isForUpdate: false,
    });
  };
  render() {
    if (this.state.redirect) {
      if (Encrypt.getSession("iglesias_admin")) {
        return (
          <Redirect
            to={`/organizacion/iglesias/membresia/${this.props.match.params.iglesia}`}
          />
        );
      } else {
        if (Encrypt.getSession("iglesias_admin")) {
          return (
            <Redirect
              to={`/organizacion/iglesias/membresia/${this.props.match.params.iglesia}`}
            />
          );
        } else {
          return (
            <Redirect
              to={`/membresia/iglesia/${this.props.match.params.iglesia}`}
            />
          );
        }
      }
    }

    if (Encrypt.getSession("codigo_iglesia_membresia")) {
      if (
        Encrypt.getSession("codigo_iglesia_membresia") !==
        this.props.match.params.iglesia
      ) {
        return (
          <Redirect
            to={`/membresia/iglesia/${this.props.match.params.iglesia}`}
          />
        );
      }
    } else {
      return <Redirect to={`/membresia`} />;
    }

    return (
      <React.Fragment>
        <LayoutPanelFormulario
          titulo={
            this.state.datos_iglesia_cargados === true
              ? this.props.match.params.codigo
                ? `Actualización de miembros ${
                    this.state.iglesia !== null
                      ? this.state.iglesia.nombre
                      : null
                  }`
                : `Ingreso de miembros ${
                    this.state.iglesia !== null
                      ? this.state.iglesia.nombre
                      : null
                  }`
              : this.loadingNav()
          }
          titulo_panel="Formularios de Datos de miembros"
          submit={this.onSubmit}
          tools={
            !this.props.match.params.codigo && (
              <Fragment>
                <button
                  type="button"
                  className="btn btn-outline-info ml-2 "
                  onClick={this.toggle}
                >
                  <i className="fa fa-search mr-2"></i>
                  Buscar persona
                </button>
                {this.state.idPersona !== null && this.state.idPersona !== "" && (
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 "
                    onClick={this.resetPersona}
                  >
                    <i className="fa fa-trash mr-2"></i>
                    Quitar persona
                  </button>
                )}
              </Fragment>
            )
          }
          buttons={
            <div className="card-footer text-center">
              <button
                type="button"
                className="btn btn-outline-inverse mr-2 "
                onClick={() => {
                  this.setState({ redirect: true });
                }}
                disabled={this.state.loading || this.state.loadingData}
              >
                <i className="fa fa-close mr-2"></i>CANCELAR
              </button>

              <button
                type="submit"
                className="btn btn-info"
                disabled={this.state.loading || this.state.loadingData}
              >
                <i className="fa fa-save mr-2"></i>
                GUARDAR
              </button>
            </div>
          }
        >
          {this.state.loadingData ? (
            this.loadingData()
          ) : (
            <div className="row">
              <div className="col-12">
                <Tabs
                  activeKey={this.state.tab_active}
                  onSelect={this.handleChangeTab.bind(this)}
                  id="tabs"
                >
                  <Tab eventKey={"datos_personales"} title={"Datos Personales"}>
                    <div className="card-body">
                      <PersonaFormulario
                        change={this.handleInputChange}
                        state={this.state}
                        save={this.saveData}
                        validator={this.validator}
                        forUpdate={this.state.isForUpdate}
                      />
                    </div>
                  </Tab>
                  <Tab eventKey={"datos_miembro"} title={"Datos Membresía"}>
                    <div className="card-body">
                      <h3 className="box-title">Datos Generales</h3>
                      <hr className="mt-0 mb-4"></hr>
                      <div className="row">
                        <div className="col-lg-4 form-group">
                          <label htmlFor="">Tipo Miembro: (*)</label>

                          <Select
                            id="tipoMiembro"
                            name="tipoMiembro"
                            placeholder="Selecione una opción"
                            options={this.state.tipos_miembros}
                            value={this.state.tipoMiembro}
                            isClearable={true}
                            isDisabled={
                              this.state.disabled_select_tipo_miembros
                            }
                            onChange={(e) => {
                              this.setState({
                                tipoMiembro: e,
                              });
                            }}
                          />
                          {this.validator.message(
                            "tipo miembro",
                            this.state.tipoMiembro,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validator.message(
                                "tipo miembro",
                                this.state.tipoMiembro,
                                "required"
                              )}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-4 form-group text-center">
                          <input
                            type="checkbox"
                            className="form-check-input filled-in chk-col-light-blue"
                            id="conviccionIglesia"
                            onChange={() => {
                              if (
                                document.getElementById("conviccionIglesia")
                                  .checked
                              ) {
                                this.setState({
                                  conviccionIglesia: 1,
                                });
                              } else {
                                this.setState({
                                  conviccionIglesia: 0,
                                });
                              }
                            }}
                            checked={this.state.conviccionIglesia}
                          />
                          <label
                            className="form-check-label mt-4"
                            htmlFor="conviccionIglesia"
                          >
                            Tiene la Convicción de la Iglesia
                          </label>
                        </div>

                        <div className="col-lg-4 form-group text-center">
                          <input
                            type="checkbox"
                            className="form-check-input filled-in chk-col-light-blue"
                            id="acuerdoDoctrina"
                            onChange={() => {
                              if (
                                document.getElementById("acuerdoDoctrina")
                                  .checked
                              ) {
                                this.setState({
                                  acuerdoDoctrina: 1,
                                });
                              } else {
                                this.setState({
                                  acuerdoDoctrina: 0,
                                });
                              }
                            }}
                            checked={this.state.acuerdoDoctrina}
                          />
                          <label
                            className="form-check-label mt-4"
                            htmlFor="acuerdoDoctrina"
                          >
                            Está de acuerdo con la Doctrina
                          </label>
                        </div>

                        <div className="col-lg-6 form-group">
                          <label htmlFor="">Áreas donde desea trabajar: </label>
                          <textarea
                            name="areasTrabajo"
                            id="areasTrabajo"
                            cols="30"
                            rows="4"
                            value={this.state.areasTrabajo}
                            className="form-control"
                            onChange={this.handleInputChange}
                            placeholder="Escriba las áreas"
                          ></textarea>
                        </div>
                      </div>
                      <h3 className="box-title">Datos de Bautismo</h3>
                      <hr className="mt-0 mb-4"></hr>
                      <div className="row">
                        <div className="col-lg-3 form-group text-center">
                          <input
                            type="checkbox"
                            className="form-check-input filled-in chk-col-light-blue"
                            id="bautizado"
                            onChange={() => {
                              if (
                                document.getElementById("bautizado").checked
                              ) {
                                this.setState({
                                  bautizado: 1,
                                });
                              } else {
                                this.setState({
                                  bautizado: 0,
                                });
                              }
                            }}
                            checked={this.state.bautizado}
                          />
                          <label
                            className="form-check-label mt-4"
                            htmlFor="bautizado"
                          >
                            Bautizado
                          </label>
                        </div>
                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Ministro Bautismo: (*)</label>

                          <AsyncSelect
                            id="ministroBautismo"
                            name="ministroBautismo"
                            placeholder="Seleccione una opción"
                            value={this.state.ministroBautismo}
                            isClearable={true}
                            loadOptions={this.getMinistrosParam}
                            defaultOptions={this.state.ministros}
                            isDisabled={this.state.disabled_select_ministros}
                            ref="select_nacionalidad"
                            onChange={(e) => {
                              this.setState({ ministroBautismo: e });
                            }}
                            noOptionsMessage={(e) => {
                              return "No existen datos";
                            }}
                          />
                          {/* {this.validator.message(
                          "ministro bautismo",
                          this.state.ministroBautismo,
                          "required"
                        ) && (
                          <span className="label label-light-danger">
                            {this.validator.message(
                              "ministro bautismo",
                              this.state.ministroBautismo,
                              "required"
                            )}
                          </span>
                        )} */}
                        </div>
                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Lugar Bautismo: </label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Lugar Bautismo"
                            id="lugarBautismo"
                            value={this.state.lugarBautismo}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Fecha Bautismo: </label>

                          <input
                            type="date"
                            className="form-control"
                            id="fechaBautismo"
                            value={this.state.fechaBautismo}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                      <h3 className="box-title">Datos de Pacto</h3>
                      <hr className="mt-0 mb-4"></hr>
                      <div className="row">
                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Ministro que le añadió : </label>

                          <AsyncSelect
                            id="ministroPacto"
                            name="ministroPacto"
                            placeholder="Seleccione una opción"
                            value={this.state.ministroPacto}
                            isClearable={true}
                            loadOptions={this.getMinistrosParam}
                            defaultOptions={this.state.ministros}
                            isDisabled={this.state.disabled_select_ministros}
                            ref="select_nacionalidad"
                            onChange={(e) => {
                              this.setState({ ministroPacto: e });
                            }}
                            noOptionsMessage={(e) => {
                              return "No existen datos";
                            }}
                          />
                        </div>
                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Lugar Pacto: </label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Lugar pacto"
                            id="lugarPacto"
                            value={this.state.lugarPacto}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Fecha pacto: </label>

                          <input
                            placeholder="dd/mm/yyyy"
                            type="date"
                            className="form-control"
                            id="fechaPacto"
                            value={this.state.fechaPacto}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Fecha Recibimiento: (*)</label>

                          <input
                            type="date"
                            className="form-control"
                            id="fechaRecibimiento"
                            value={this.state.fechaRecibimiento}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          )}
        </LayoutPanelFormulario>
        {this.UIModalPersonas()}
      </React.Fragment>
    );
  }
  toggle() {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  }
  rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.preventDefault();
      this.getPersonaData(row.codigo);
      this.setState({
        isForUpdate: true,
      });
      this.toggle();
    },
  };
  UIModalPersonas() {
    return (
      <Modal
        size="lg"
        style={{ maxWidth: "1600px", width: "65%" }}
        isOpen={this.state.isModalOpen}
        returnFocusAfterClose={false}
      >
        <ModalHeader toggle={this.toggle}>Buscador de personas</ModalHeader>
        <ModalBody>
          <TablaFilter
            ref="tabla"
            ruta={"miembros/personas/aptas"}
            rowEvents={this.rowEvents}
            identificador={"modulo_id"}
            columns={[
              {
                dataField: "codigo",
                text: "CÓDIGO",
                formatter: (cellContent, row) => (
                  <p className="text-center">{cellContent}</p>
                ),
                headerStyle: () => ({ textAlign: "center" }),
              },
              {
                dataField: "nombre",
                text: "Nombre",
                sort: true,
                formatter: (cellContent, row) => (
                  <p className="ml-4">{cellContent?.toUpperCase()}</p>
                ),
                headerStyle: () => ({ textAlign: "center" }),
              },
              {
                dataField: "telefono",
                text: "Teléfono",
                sort: true,
                formatter: (cellContent, row) => (
                  <p className="ml-4">{cellContent}</p>
                ),
                headerStyle: () => ({ textAlign: "center" }),
              },
              {
                dataField: "correo",
                text: "Correo",
                sort: true,
                formatter: (cellContent, row) => (
                  <p className="ml-4">{cellContent}</p>
                ),
                headerStyle: () => ({ textAlign: "center" }),
              },
              {
                dataField: "numeroDocumento",
                text: "No. documento",
                sort: true,
                formatter: (cellContent, row) => (
                  <p className="ml-4">{cellContent}</p>
                ),
                headerStyle: () => ({ textAlign: "center" }),
              },
            ]}
            titulo_tabla="Escriba la persona que desea buscar"
          />
        </ModalBody>
        <ModalFooter className="text-right"></ModalFooter>
      </Modal>
    );
  }
}
