import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import LayoutPanelTable from "../../../../../components/layouts/panels/LayoutPanelTable";
import TablaFilter from "../../../../../components/tablas/TablaFilter";

import HTTP from "../../../../../helpers/HTTP";
import Encrypt from "../../../../../services/Encrypt";

export default class HistoricoPresentacionInformesGestiones extends Component {
  constructor(prosp) {
    super();
    this.state = this.inicialState;
  }

  inicialState = {
    datos_iglesia_cargados: false,
    iglesia: null,
  };

  componentWillUnmount() {
    Encrypt.destroyItemSession("historico_iglesia_presentacion_informe");
  }
  columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "codigo",
      text: "Código",
      hidden: false,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "8%", textAlign: "center" };
      },
    },
    {
      dataField: "descripcion",
      text: "Descripción",
      sort: true,
      formatter: (cellContent, row) => {
        return <p className="ml-4">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "fecha_publicacion",
      text: "Fecha Publicación",
      sort: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
    {
      dataField: "fecha_recibir_fin",
      text: "Fecha Limite Entrega",
      sort: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
    {
      dataField: "opciones",
      text: "Opciones",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-center">
            <button
              onClick={() => {
                Encrypt.setSession(
                  "historico_iglesia_presentacion_informe_cod_iglesia",
                  this.props.match.params.iglesia
                );
                Encrypt.setSession(
                  "historico_iglesia_presentacion_informe_cod_gestion",
                  row.codigo
                );
                this.props.history.push(
                  `/historico/presentacion_informes/gestion/detalles/${row.codigo}/${this.props.match.params.iglesia}`
                );
              }}
              className="btn btn-outline-info "
            >
              <i className="fa fa-eye mr-1"></i> Ver Detalles
            </button>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
  ];

  componentDidMount() {
    //document.getElementById("buscar").focus();
    this.getIglesia();
  }

  getIglesia() {
    if (this.props.match.params.iglesia) {
      HTTP.findById(this.props.match.params.iglesia, "iglesias/detalle").then(
        (result) => {
          if (result !== false) {
            this.setState({
              iglesia: result,
              datos_iglesia_cargados: true,
            });
          } else {
            this.setState({ redirect: true });
          }
        }
      );
    }
  }
  render() {
    if (Encrypt.getSession("historico_iglesia_presentacion_informe")) {
      if (
        Encrypt.getSession("historico_iglesia_presentacion_informe") !==
        this.props.match.params.iglesia
      ) {
        return <Redirect to={`/historico/presentacion_informes/iglesia`} />;
      }
    } else {
      return <Redirect to={`/historico/presentacion_informes/iglesia`} />;
    }
    return (
      <React.Fragment>
        <LayoutPanelTable
          titulo={`Histórico de gestiones ${
            this.state.iglesia !== null ? "de" : ""
          } ${this.state.iglesia !== null ? this.state.iglesia?.nombre : ""}`}
          buttons={
            <div className="text-center">
              <button
                onClick={() => {
                  this.props.history.push(
                    `/historico/presentacion_informes/iglesia`
                  );
                }}
                className="btn btn-default btn-lg"
              >
                <i className="fa fa-arrow-left mr-1"></i>Salir
              </button>
            </div>
          }
        >
          <div className="form-body">
            <div className="row p-t-20">
              <div className="col-12 form-group">
                <TablaFilter
                  ref="tabla"
                  ruta={`gestion_historico/iglesia/${this.props.match.params.iglesia}`}
                  rowEvents={this.rowEvents}
                  identificador={"id"}
                  columns={this.columns}
                  titulo_tabla="Escriba el registro que desea buscar"
                />
              </div>
            </div>
          </div>
        </LayoutPanelTable>
      </React.Fragment>
    );
  }
}
