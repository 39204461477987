import React, { Component } from "react";
import HTTP from "../../../../../helpers/HTTP";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import es from "../../../../../helpers/ValidatorTranslate_es";
import Request from "../../../../../services/Request";
import Alerts from "../../../../../services/Alerts";

import TablaFilter from "../../../../../components/tablas/TablaFilter";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import LayoutPanelEmpty from "../../../../../components/layouts/panels/LayoutPanelEmpty";
import Encrypt from "../../../../../services/Encrypt";
import Iframe from "react-iframe";
import { saveAs } from "file-saver";
import { isBrowser } from "react-device-detect";
import ContentLoader from "react-content-loader";

export default class HistoricoPresentacionInformesDetalle extends Component {
  constructor(props) {
    super(props);
    this.state = this.inicial_state;
    this.ActionModalArchivo = this.ActionModalArchivo.bind(this);
    this.ActionModalMostrarComprobante =
      this.ActionModalMostrarComprobante.bind(this);
    this.ActionModalVistaPreviaInforme =
      this.ActionModalVistaPreviaInforme.bind(this);
    this.init_validator();
  }

  inicial_state = {
    title: "Panel para presentación de informes",
    nombre: "",
    loading: false,
    tab_active: "",
    gestiones: [],
    actualizando: false,
    iglesia: null,
    redirect: false,
    tipo_form: 1,
    modal_vista_informe: false,
    modal_mostrar: false,
    comprobante_deposito: null,
    nombre_informe_descarga: null,
    imagen_comprobante: null,
    archivo_informe: null,
    datos_iglesia_cargados: false,
    datos_gestion_cargados: false,
    gestion_vigente: false,
  };

  async ActionModalArchivo(tipo = 1, flag = true) {
    if (this.state.gestion_vigente === true) {
      await this.setState((prevState) => ({
        modal_archivo: !prevState.modal_archivo,
        tipo_form: tipo,
      }));

      if (tipo === 2) {
        this.ActionModalMostrarComprobante(false);
      }
      if (flag) {
      } else {
        this.setState({
          comprobante_deposito: null,
        });
      }
    } else {
      Alerts.alertEmpty(
        "¡La gestión ha superado la fecha limite para envío!",
        "Administración de informes",
        "info"
      );
    }
  }

  async ActionModalMostrarComprobante(flag = true) {
    await this.setState((prevState) => ({
      modal_mostrar: !prevState.modal_mostrar,
    }));
    if (flag) {
    } else {
      this.setState({
        imagen_comprobante: null,
      });
    }
  }
  async ActionModalVistaPreviaInforme(flag = true) {
    await this.setState((prevState) => ({
      modal_vista_informe: !prevState.modal_vista_informe,
    }));
    // if (flag) {
    // } else {
    //   this.setState({
    //     comprobante_deposito: null,
    //   });
    // }
  }
  handleInputChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    this.setState({ [idComponente]: valorComponente });
  };

  componentDidMount() {
    // document.getElementById("nombre").focus();
    this.getIglesia();
    this.getGestion();
  }

  componentWillUnmount() {
    Encrypt.destroyItemSession(
      "historico_iglesia_presentacion_informe_cod_iglesia"
    );
    Encrypt.destroyItemSession(
      "historico_iglesia_presentacion_informe_cod_gestion"
    );
  }

  getGestion() {
    if (this.props.match.params.iglesia && this.props.match.params.gestion) {
      HTTP.findAll(
        `gestiones_historico/disponible/iglesia/${this.props.match.params.iglesia}/${this.props.match.params.gestion}`
      ).then(async (result) => {
        if (result !== false) {
          await this.setState({
            gestiones: result,

            datos_gestion_cargados: true,
          });
        } else {
          this.setState({ redirect: true });
        }
      });
    }
  }
  getGestionRecarga() {
    if (this.props.match.params.id) {
      HTTP.findById(
        this.props.match.params.id,
        "gestiones/disponibles/iglesia"
      ).then((result) => {
        if (result !== false) {
          this.setState({
            gestiones: result,
            tab_active: Encrypt.getSession("presentacion_gestion"),
          });
        } else {
          this.setState({ redirect: true });
        }
      });
    }
  }

  getIglesia() {
    if (this.props.match.params.iglesia) {
      HTTP.findById(this.props.match.params.iglesia, "iglesias/detalle").then(
        (result) => {
          if (result !== false) {
            this.setState({
              iglesia: result,
              datos_iglesia_cargados: true,
            });
          } else {
            this.setState({ redirect: true });
          }
        }
      );
    }
  }
  init_validator() {
    SimpleReactValidator.addLocale("esp", es);
    this.validator = new SimpleReactValidator({ locale: "esp" });
  }

  hide_alert_validator() {
    this.validator.hideMessages();
    this.forceUpdate();
  }

  updateZona() {
    if (this.validator.allValid()) {
      this.setState({ actualizando: true });
      const data = {
        code: this.props.match.params.id,
        nombre: this.state.nombre,
      };
      HTTP.update(data, "zona", "zonas", "zonas").then((result) => {
        this.setState({ actualizando: false });

        if (result !== false) {
          this.setState({ redirect: true });
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  asignacionDepartamentos() {
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
      });
      if (this.props.match.params.id) {
        const data = {
          code: this.props.match.params.id,
          nombre: this.state.nombre,
        };
        HTTP.update(data, "zona", "zonas", "zonas").then((result) => {
          this.setState({ actualizando: false });

          if (result !== false) {
            this.props.history.push(
              `/organizacion/zonas/asignacion/${this.props.match.params.id}`
            );
          }
        });
      } else {
        const data = {
          nombre: this.state.nombre,
        };
        Request.POST("zonas", data).then((result) => {
          this.setState({
            loading: false,
          });
          if (result !== false) {
            if (result.status === 201) {
              this.props.history.push(
                `/organizacion/zonas/asignacion/${result.data.zona}`
              );
            } else {
              Alerts.alertEmpty(
                "¡No se pudo crear!",
                "Administración de zonas",
                "error"
              );
            }
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  enviarInforme(codigo_informe, gestion) {
    Alerts.QuestionYesNo(
      "¿Está seguro que desea enviar el informe?",
      "¡Una vez enviado no podrá actualizarlo!",
      "question"
    ).then((resp) => {
      if (resp) {
        const data = {
          codigoInforme: codigo_informe,
        };
        Alerts.loading_reload(true);
        Request.PUT("informe/enviar", data).then((result) => {
          Alerts.loading_reload(false);

          if (result !== false) {
            if (result.status === 200) {
              Alerts.alertEmpty(
                "¡Informe enviado con éxito!",
                "Administración de informes",
                "success"
              );

              this.refs[gestion].clear();
              this.forceUpdate();
            } else {
              Alerts.alertEmpty(
                "¡El informe no pudo ser enviado porque ha ocurrido un error!",
                "Administración de informes",
                "error"
              );
            }
          } else {
            Alerts.alertEmpty(
              "¡El informe no pudo ser enviado porque ha ocurrido un error!",
              "Administración de informes",
              "error"
            );
          }
        });
      }
    });
  }
  columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "confidencial",
      text: "id",
      hidden: true,
    },
    {
      dataField: "ruta",
      text: "id",
      hidden: true,
    },
    {
      dataField: "codigo_usuario_envio",
      text: "id",
      hidden: true,
    },
    {
      dataField: "informe_ide",
      text: "id",
      hidden: true,
    },
    {
      dataField: "informe",
      text: "Nombre Informe",

      formatter: (cellContent, row) => {
        return <p className="ml-4">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "mes",
      text: "Mes",

      formatter: (cellContent, row) => {
        return <p className="ml-4">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "2%", textAlign: "center" };
      },
    },
    {
      dataField: "idMes",
      text: "idMes",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="ml-4">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "2%", textAlign: "center" };
      },
    },
    {
      dataField: "fecha_creacion",
      text: "Fecha Creación",

      formatter: (cellContent, row) => {
        if (cellContent !== null) {
          return <p className="text-center">{cellContent?.toUpperCase()}</p>;
        } else {
          return <p className="text-center">N/A</p>;
        }
      },
      headerStyle: () => {
        return { width: "6%", textAlign: "center" };
      },
    },
    {
      dataField: "fecha_envio",
      text: "Fecha Envio",

      formatter: (cellContent, row) => {
        if (cellContent !== null) {
          return <p className="text-center">{cellContent?.toUpperCase()}</p>;
        } else {
          return <p className="text-center">N/A</p>;
        }
      },
      headerStyle: () => {
        return { width: "5%", textAlign: "center" };
      },
    },
    {
      dataField: "usuario_envio",
      text: "Usuario Envío",

      formatter: (cellContent, row) => {
        if (cellContent !== null) {
          return <p className="text-center">{cellContent?.toUpperCase()}</p>;
        } else {
          return <p className="text-center">N/A</p>;
        }
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
    {
      dataField: "opciones",
      text: "Opciones",

      formatter: (cellContent, row) => {
        if (row.estado === 0) {
          return (
            <div className="text-center">
              <span>N/A</span>
            </div>
          );
        } else if (row.estado === 1) {
          return (
            <div className="text-center">
              <span>N/A</span>
            </div>
          );
        } else if (row.estado === 2) {
          return (
            <div className="text-center">
              {isBrowser === true ? (
                row.confidencial === 0 ? (
                  <button
                    className="btn btn-outline-secondary"
                    onClick={this.getVistaPreviaInforme.bind(
                      this,
                      row.informe_ide,
                      row.informe
                    )}
                  >
                    <i className="fa fa-eye mr-2"></i>VISTA PREVIA
                  </button>
                ) : (
                  <span>SIN VISTA PREVIA</span>
                )
              ) : row.confidencial === 0 ? (
                <button
                  className="btn btn-outline-secondary"
                  onClick={this.descargarVistaPreviaInforme.bind(
                    this,
                    row.informe_ide,
                    row.informe
                  )}
                >
                  <i className="fa fa-download mr-2"></i>DESCARGAR
                </button>
              ) : (
                <span>SIN VISTA PREVIA</span>
              )}
            </div>
          );
        }
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
    {
      dataField: "estado",
      text: "ESTADO",

      formatter: (cellContent, row) => {
        if (cellContent === 0) {
          return (
            <p className="text-center">
              <span className="label label-light-danger"> NO FUE ENVIADO</span>
            </p>
          );
        } else if (cellContent === 1) {
          return (
            <p className="text-center">
              <span className="label label-light-info">DIGITANTO</span>
            </p>
          );
        } else if (cellContent === 2) {
          return (
            <p className="text-center">
              <span className="label label-success text-inverse">ENVIADO</span>
            </p>
          );
        }
      },
      headerStyle: () => {
        return { width: "8%", textAlign: "center" };
      },
    },
  ];

  getVistaPreviaInforme(ide_informe, nombre_informe) {
    Alerts.loading_reload(true);

    const data = {
      codigoGestion: this.props.match.params.gestion,
      codigoIglesia: this.props.match.params.iglesia,
      codigoInforme: ide_informe,
    };
    Request.POST_GET_PDF(`gestion_historico/pdf/informes`, data).then(
      async (result) => {
        Alerts.loading_reload(false);
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);

            await this.setState({
              archivo_informe: url,
              nombre_informe_descarga: `${nombre_informe
                .toUpperCase()
                .replaceAll(" ", "_")}-${this.state.iglesia.nombre
                .toUpperCase()
                .replaceAll(" ", "_")}-${this.props.match.params.gestion}`,
            });
            if (this.state.modal_vista_informe === false) {
              this.ActionModalVistaPreviaInforme(true);
            }
          }
        }
      }
    );
  }
  descargarVistaPreviaInforme(ide_informe, nombre_informe) {
    Alerts.loading_reload(true);

    const data = {
      codigoGestion: this.props.match.params.gestion,
      codigoIglesia: this.props.match.params.iglesia,
      codigoInforme: ide_informe,
    };
    Request.POST_GET_PDF(`gestion/pdf/informes`, data).then(async (result) => {
      Alerts.loading_reload(false);
      if (result !== false) {
        if (result.status === 200) {
          let blob = new Blob([result.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);

          await this.setState({
            archivo_informe: url,
            nombre_informe_descarga: `${nombre_informe
              .toUpperCase()
              .replaceAll(" ", "_")}-${this.state.iglesia.nombre
              .toUpperCase()
              .replaceAll(" ", "_")}-${this.props.match.params.gestion}`,
          });
          saveAs(
            this.state.archivo_informe,
            `${this.state.nombre_informe_descarga}.pdf`
          );
        }
      }
    });
  }
  downloadInforme() {
    saveAs(
      this.state.archivo_informe,
      `${this.state.nombre_informe_descarga}.pdf`
    );
  }
  printerInforme() {
    window.setTimeout(() => {
      document.querySelector("#archivo_informe").contentWindow.print();
    }, 500);
  }
  mostrarComprobante() {
    Alerts.loading_reload(true);
    Request.GET_IMAGE(
      `gestion_historico/comprobante/${this.props.match.params.gestion}`,
      this.props.match.params.iglesia
    ).then(async (result) => {
      Alerts.loading_reload(false);

      if (result !== false) {
        if (result.status === 200) {
          let blob = new Blob([result.data], {
              type: result.headers["content-type"],
            }),
            url = window.URL.createObjectURL(blob);

          await this.setState({ imagen_comprobante: url });
          this.ActionModalMostrarComprobante();
        }
      }
    });
  }

  imprimirComprobante() {
    const image = document.getElementById("img-comprobante").cloneNode();
    image.style.maxWidth = "60%";

    // Create a fake iframe
    const iframe = document.createElement("iframe");

    // Make it hidden
    iframe.style.height = 0;
    iframe.style.visibility = "hidden";
    iframe.style.width = 0;

    document.body.appendChild(iframe);
    const body = iframe.contentDocument.body;
    body.style.textAlign = "center";
    body.appendChild(image);
    iframe.contentWindow.print();

    setTimeout(() => {
      iframe.parentNode.removeChild(iframe);
    }, 500);
  }

  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 60" height={"auto"} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="300" />
        </ContentLoader>
      </React.Fragment>
    );
  }
  render() {
    if (
      !Encrypt.getSession(
        "historico_iglesia_presentacion_informe_cod_iglesia"
      ) ||
      !Encrypt.getSession("historico_iglesia_presentacion_informe_cod_gestion")
    ) {
      return (
        <Redirect
          to={`/historico/presentacion_informes/gestiones/${this.props.match.params.iglesia}`}
        />
      );
    } else {
      if (
        Encrypt.getSession(
          "historico_iglesia_presentacion_informe_cod_iglesia"
        ) !== this.props.match.params.iglesia ||
        Encrypt.getSession(
          "historico_iglesia_presentacion_informe_cod_gestion"
        ) !== this.props.match.params.gestion
      ) {
        return (
          <Redirect
            to={`/historico/presentacion_informes/gestiones/${this.props.match.params.iglesia}`}
          />
        );
      }
    }
    return (
      <React.Fragment>
        {/* ============================================================== */}
        {/* Bread crumb and right sidebar toggle */}
        {/* ============================================================== */}
        <div className="row page-titles">
          <div className="col-md-5 align-self-center">
            <h3 className="text-themecolor">{this.state.title}</h3>
          </div>
        </div>
        {/* ============================================================== */}
        {/* End Bread crumb and right sidebar toggle */}
        {/* ============================================================== */}
        {/* ============================================================== */}
        {/* Container fluid  */}
        {/* ============================================================== */}
        <div className="container-fluid">
          {/* ============================================================== */}
          {/* Start Page Content */}
          {/* ============================================================== */}
          {/* Row */}
          <div className="row">
            {/* Column */}
            <div className="col-lg-2 col-xlg-2 col-md-3">
              <div className="card">
                <div className="card-body">
                  {this.state.datos_gestion_cargados === true &&
                  this.state.datos_iglesia_cargados === true ? (
                    <center className="m-t-30">
                      {" "}
                      {/* <img
                      src="../assets/images/users/5.jpg"
                      className="img-circle"
                      width={150}
                    /> */}
                      <h4 className="card-title m-t-10">
                        {this.state.iglesia !== null
                          ? this.state.iglesia.nombre
                          : null}
                      </h4>
                      <h6 className="card-subtitle">
                        {this.state.iglesia !== null
                          ? this.state.iglesia.codigo
                          : null}
                      </h6>
                    </center>
                  ) : (
                    this.loadingData()
                  )}
                </div>
                <div>
                  <hr />
                </div>
                {this.state.datos_gestion_cargados === true &&
                this.state.datos_iglesia_cargados === true ? (
                  <div className="card-body">
                    <small className="text-muted">Pastor:</small>
                    <h6>
                      {this.state.iglesia !== null
                        ? this.state.iglesia?.pastor !== "" &&
                          this.state.iglesia?.pastor !== null
                          ? this.state.iglesia.pastor
                          : "SIN PASTOR ASIGNADO"
                        : "SIN PASTOR ASIGNADO"}
                    </h6>
                    <small className="text-muted">Zona:</small>
                    <h6>
                      {this.state.iglesia !== null
                        ? this.state.iglesia.zona
                        : "SIN ZONA ASIGNADA"}
                    </h6>
                    <small className="text-muted p-t-15 db">Distrito:</small>
                    <h6>
                      {this.state.iglesia !== null
                        ? this.state.iglesia.distrito
                        : null}
                    </h6>
                    <small className="text-muted p-t-15 db">
                      Departamento - Municipio:
                    </small>
                    <h6>
                      {this.state.iglesia !== null
                        ? this.state.iglesia.departamento
                        : null}
                      -
                      {this.state.iglesia !== null
                        ? this.state.iglesia.municipio
                        : null}
                    </h6>
                    <small className="text-muted p-t-15 db">Dirección:</small>
                    <h6>
                      {this.state.iglesia !== null
                        ? this.state.iglesia.direccion
                        : null}
                    </h6>

                    <small className="text-muted p-t-15 db">Teléfono:</small>
                    <h6>
                      {this.state.iglesia !== null
                        ? this.state.iglesia?.telefono !== null &&
                          this.state.iglesia?.telefono !== ""
                          ? this.state.iglesia?.telefono
                          : "SIN TELÉFONO ASIGNADO"
                        : "SIN TELÉFONO ASIGNADO"}
                    </h6>
                  </div>
                ) : (
                  <div className="card-body">{this.loadingData()}</div>
                )}
              </div>
            </div>
            {/* Column */}
            {/* Column */}
            {this.state.datos_gestion_cargados === true &&
            this.state.datos_iglesia_cargados === true ? (
              <div className="col-lg-10 col-xlg-10 col-md-9">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="table-responsive">
                          <table className="table table-bordered table-bordered-table color-bordered-table muted-bordered-table">
                            <thead>
                              <tr>
                                <th className="text-center">Código Gestión</th>
                                <th className="text-center">Descripción</th>

                                <th className="text-center">
                                  Fecha Publicación
                                </th>
                                {/* <th className="text-center">
                                            Fecha de Inicio para Envío
                                          </th> */}
                                <th className="text-center">
                                  Fecha Límite para Envío
                                </th>
                                <th className="text-center">
                                  Comprobante Remesa
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{this.state.gestiones.codigo}</td>
                                <td>
                                  {this.state.gestiones.descripcion?.toUpperCase()}
                                </td>
                                <td>
                                  {this.state.gestiones.fecha_publicacion}
                                </td>

                                {/* <td>
                                            {this.state.gestiones.fecha_recibir_inicio}
                                          </td> */}
                                <td>
                                  {this.state.gestiones.fecha_recibir_fin}
                                </td>
                                {this.state.gestiones.comprobante === 1 ? (
                                  <td className="text-center">
                                    {" "}
                                    <button
                                      type="button"
                                      onClick={this.mostrarComprobante.bind(
                                        this
                                      )}
                                      className="btn btn-outline-info mr-2"
                                    >
                                      <i className="fa fa-eye mr-2"></i>
                                      Mostrar
                                    </button>
                                  </td>
                                ) : null}
                                {this.state.gestiones.comprobante === 0 ? (
                                  <td className="text-center">
                                    <span className="label label-light-danger font-18">
                                      NO SE ENVIÓ
                                    </span>
                                  </td>
                                ) : null}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <h4 className="card-title m-t-10">
                          Informes solicitados
                        </h4>
                        <TablaFilter
                          buscador={false}
                          ref={"tabla"}
                          ruta={`gestion_historico/iglesia/informes/${this.props.match.params.gestion}/${this.props.match.params.iglesia}`}
                          rowEvents={this.rowEvents}
                          identificador={"id"}
                          columns={this.columns}
                          titulo_tabla="Escriba el registro que desea buscar"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-center">
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-lg"
                      onClick={() => {
                        Encrypt.setSession(
                          "historico_iglesia_presentacion_informe",
                          this.props.match.params.iglesia
                        );
                        // Encrypt.setSession(
                        //   "historico_iglesia_presentacion_informe_cod_gestion",
                        //   this.props.match.params.gestion
                        // );
                        this.props.history.push(
                          `/historico/presentacion_informes/gestiones/${this.state.iglesia.codigo}`
                        );
                      }}
                    >
                      <i className="fa fa-arrow-left"></i> SALIR
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-lg-10 col-xlg-10 col-md-9">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">{this.loadingData()}</div>
                    </div>
                    <div className="row">
                      <div className="col-12">{this.loadingData()}</div>
                    </div>
                    <div className="row">
                      <div className="col-12">{this.loadingData()}</div>
                    </div>
                  </div>
                  <div className="card-footer text-center">
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-lg"
                      onClick={() => {
                        Encrypt.setSession(
                          "historico_iglesia_presentacion_informe",
                          this.props.match.params.iglesia
                        );
                        // Encrypt.setSession(
                        //   "historico_iglesia_presentacion_informe_cod_gestion",
                        //   this.props.match.params.gestion
                        // );
                        this.props.history.push(
                          `/historico/presentacion_informes/gestiones/${this.state.iglesia.codigo}`
                        );
                      }}
                    >
                      <i className="fa fa-arrow-left"></i> SALIR
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* Column */}
          </div>
          {/* Row */}
        </div>
        {/* ============================================================== */}
        {/* End Container fluid  */}
        {/* ============================================================== */}

        {/*MODAL PARA MOSTRAR */}
        <Modal
          size="lg"
          style={{ maxWidth: "1800px", width: "80%" }}
          isOpen={this.state.modal_mostrar}
          centered={true}
        >
          <ModalHeader
            toggle={this.ActionModalMostrarComprobante.bind(this, false)}
          >
            Comprobante de Depósito
          </ModalHeader>
          <ModalBody>
            <LayoutPanelEmpty
              titulo_panel="Vista Previa comprobante"
              buttons={
                <div>
                  <button
                    type="button"
                    onClick={this.imprimirComprobante.bind(this)}
                    className="btn btn-info mr-2"
                  >
                    <i className="fa fa-print mr-2"></i>
                    Imprimir
                  </button>
                </div>
              }
            >
              <div className="row">
                <div className="col-lg-12 text-center">
                  <img
                    src={this.state.imagen_comprobante}
                    className="img img-preview "
                    width="70%"
                    height="auto"
                    id="img-comprobante"
                    alt="imagen"
                  />
                </div>
              </div>
            </LayoutPanelEmpty>
          </ModalBody>
          <ModalFooter className="text-right"></ModalFooter>
        </Modal>
        {/*FIN MODAL */}

        {/*MODAL VISTA PREVIA INFORME */}
        <Modal
          size="lg"
          style={{ maxWidth: "1200px", width: "75%" }}
          isOpen={this.state.modal_vista_informe}
          centered={true}
        >
          <ModalHeader
            toggle={this.ActionModalVistaPreviaInforme.bind(this, false)}
          >
            Panel de Visualización previa de informe enviado
          </ModalHeader>
          <ModalBody>
            <LayoutPanelEmpty
              titulo_panel="Informe"
              buttons={
                <div>
                  <button
                    type="button"
                    onClick={this.downloadInforme.bind(this)}
                    className="btn btn-outline-secondary mr-2"
                  >
                    <i className="fa fa-download mr-2"></i>
                    Descargar
                  </button>
                  <button
                    type="button"
                    onClick={this.printerInforme.bind(this)}
                    className="btn btn-outline-secondary mr-2"
                  >
                    <i className="fa fa-print mr-2"></i>
                    Imprimir
                  </button>
                </div>
              }
            >
              <div className="row">
                <div className="col-lg-12 text-center">
                  {this.state.archivo_informe !== null ? (
                    <Iframe
                      id="archivo_informe"
                      url={`${this.state.archivo_informe}#toolbar=0&navpanes=0&statusbar=0`}
                      width="90%"
                      height="550px"
                      display="initial"
                      position="relative"
                    />
                  ) : null}
                </div>
              </div>
            </LayoutPanelEmpty>
          </ModalBody>
          <ModalFooter className="text-right"></ModalFooter>
        </Modal>
        {/*FIN MODAL */}

        {this.state.imagen_comprobante !== null ? (
          <iframe
            id="iframe_comprobante"
            title="comprobante"
            style={{ visibility: "hidden" }}
            frameBorder="0"
          ></iframe>
        ) : null}
      </React.Fragment>
    );
  }
}
