import React, { Fragment } from "react";
import { ModalTitle } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { isMobile } from "react-device-detect";
import HTTP from "../../helpers/HTTP";
class IglesiaInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      iglesia: null,
    };
  }

  refreshIglesiaData(codigo) {
    HTTP.findById(codigo, "iglesias/detalle").then((result) => {
      if (result !== false) {
        this.setState({
          iglesia: result,
        });
      } else {
      }
    });
  }
  toggle(codigo) {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
    this.refreshIglesiaData(codigo);
  }
  render() {
    return (
      <Fragment>
        <Modal size="lg" isOpen={this.state.isOpen}>
          <ModalHeader className="bg-info" toggle={this.toggle}>
            <ModalTitle className="text-light" as="h3">
              Datos
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-body">
                    <h3 class="box-title">Información general</h3>
                    <hr class="m-t-0 m-b-40" />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label  col-md-3">Código:</label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {" "}
                              {this.state.iglesia?.codigo}{" "}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label  col-md-3">Nombre:</label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {" "}
                              {this.state.iglesia?.nombre}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label  col-md-3">Pastor:</label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {" "}
                              {this.state.iglesia?.pastor}{" "}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label  col-md-3">
                            Teléfono:
                          </label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {" "}
                              {this.state.iglesia?.telefono}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-3">
                            Tipo de iglesia :
                          </label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {" "}
                              {this.state.iglesia?.tipo_iglesia}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 class="box-title">Información organizacional</h3>
                    <hr class="m-t-0 m-b-40" />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-5">
                            Región:
                          </label>
                          <div class="col-md-7">
                            <p class="form-control-static">
                              {/* {this.state.iglesia?.departamento} */}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-3">
                            Zona:
                          </label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {this.state.iglesia?.zona}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-3">Distrito:</label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {this.state.iglesia?.distrito}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-3">
                            Fecha afiliación:
                          </label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {this.state.iglesia?.fecha_ordenamiento}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    <h3 class="box-title">Información geográfica</h3>
                    <hr class="m-t-0 m-b-40" />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-5">
                            Departamento:
                          </label>
                          <div class="col-md-7">
                            <p class="form-control-static">
                              {this.state.iglesia?.departamento}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-3">
                            Municipio:
                          </label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {this.state.iglesia?.municipio}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-3">Cantón:</label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {this.state.iglesia?.canton}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-3">
                            Dirección:
                          </label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {this.state.iglesia?.direccion}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-actions">
                    {this.state.iglesia?.telefono && (
                      <Fragment>
                        <h3 class="box-title">Acciones</h3>
                        <hr class="m-t-0 m-b-40" />
                      </Fragment>
                    )}

                    <div class="d-flex align-content-center justify-content-center flex-wrap">
                      {this.state.iglesia?.telefono && (
                        <a
                          href={
                            "https://wa.me/503" +
                            this.state.iglesia.telefono?.replace("-", "")
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          class="btn btn-info mr-1"
                        >
                          <i class="fa fa-whatsapp"></i> Whatsapp
                        </a>
                      )}
                      {isMobile && this.state.iglesia?.telefono && (
                        <a
                          href={"tel:" + this.state.iglesia.telefono}
                          target="_blank"
                          rel="noopener noreferrer"
                          class="btn btn-info mr-1"
                        >
                          <i class="fa fa-phone"></i> Llamar
                        </a>
                      )}
                      {/* <a
                        href={"mailto:" + this.state.correo}
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-info"
                      >
                        <i class="fa fa-envelope"></i> Enviar correo
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="text-right"></ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default IglesiaInfoModal;
