import React, { Component, Fragment } from "react";
import LayoutPanelFormulario from "../../components/layouts/panels/LayoutPanelFormulario";
import SimpleSelect from "react-select";
import HTTP from "../../helpers/HTTP";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import es from "../../helpers/ValidatorTranslate_es";
import Request from "../../services/Request";
import Alerts from "../../services/Alerts";
import PersonaFormulario from "../../components/Personas/PersonaFormulario";
import ContentLoader from "react-content-loader";

export default class PersonasForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.initial_state;
    this.mailExists = this.mailExists.bind(this);
    this.deleteAccess = this.deleteAccess.bind(this);
    this.onPersonaNotExists = this.onPersonaNotExists.bind(this);
    this.onPersonaLoaded = this.onPersonaLoaded.bind(this);
    this.init_validator();
  }

  initial_state = {
    title: this.props.match.params.codigo
      ? "Actualización de persona"
      : "Registro de persona",

    //Para creacion de usuario
    createUser: false,
    userId: "",
    iglesia: null,
    correo_electronico: "",
    rol: null,
    alias: "",

    //auxiliares PErsonas

    //Auxiliares usuarios
    iglesias: [],
    roles: [],

    //Auxiliares para edicion
    creatingUser: false,
    forUpdate: false,
    hasUser: false,

    mailExists: false,

    //persona
    documentExists: null,
    phoneExists: null,

    loading: false,
    redirect: false,
    loadingData: false,

    nombres: "",
    apellidos: "",
    apellido_casada: "",
    tipo_documento: "",
    numero_documento: "",
    nombre_conyugue: "",
    telefono: "",
    correo: "",
    sexo: "",
    estado_civil: "",
    nacionalidad: "",
    profesion: "",
    fotografia: null,
    direccion: "",
    departamento_nacimiento: "",
    municipio_nacimiento: "",
    canton_nacimiento: "",
    fecha_nacimiento: "",
    departamento_residencia: "",
    municipio_residencia: "",
    canton_residencia: "",
    codigo_persona: null,
    fecha_matrimonio: "",
    disable_email: false,
    correo_electronico_actual: "",
  };
  timer_cuentas = null;
  init_validator() {
    SimpleReactValidator.addLocale("esp", es);
    this.validator = new SimpleReactValidator({
      validators: {
        numeroExiste: {
          message: "El :attribute ya existe",
          rule: (val) => {
            return !val;
          },
        },
        duiInvalido: {
          message: "El número de DUI es inválido",
          rule: (val) => {
            return val;
          },
        },
      },
      //element: message=><span className="label label-light-danger">{message}</span>,
      locale: "esp",
    });
  }
  //NEED
  handleInputChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    this.setState({ [idComponente]: valorComponente });
  };
  handleInputChangeCorreoCredencioales = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;

    this.setState({ correo: valorComponente });

    this.setState({ [idComponente]: valorComponente });
  };

  //Cuando los componentes se han montado
  componentDidMount() {
    if (this.props.match.params.codigo) {
      //Alerts.loading_reload(true);
      this.setState({
        isForUpdate: true, //Es para actualizar persona
        loadingData: true,
      });
      this.getPersonaData(this.props.match.params.codigo);
    }
  }
  getPersonaData(codigo) {
    HTTP.findById(codigo, "personas").then((result) => {
      if (result !== false) {
        // if (result.estado !== false) {
        this.setState({
          idPersona: result.estado.id,
          codigo_persona: result.estado.codigo,
          nombres: result.estado.nombres !== null ? result.estado.nombres : "",
          apellidos:
            result.estado.apellidos !== null ? result.estado.apellidos : "",
          telefono:
            result.estado.telefono !== null ? result.estado.telefono : "",
          sexo: result.estado.sexo.label !== null ? result.estado.sexo : "",
          correo: result.estado.correo !== null ? result.estado.correo : "",
          correo_electronico_actual:
            result.estado.correo !== null ? result.estado.correo : "",
          nacionalidad:
            result.estado.nacionalidad.label !== null
              ? result.estado.nacionalidad
              : "",
          fecha_nacimiento:
            result.estado.fecha_nacimiento !== null
              ? result.estado.fecha_nacimiento
              : "",
          departamento_nacimiento:
            result.estado.departemento_nacimiento.label !== null
              ? result.estado.departemento_nacimiento
              : "",
          municipio_nacimiento:
            result.estado.municipio_nacimiento.label !== null
              ? result.estado.municipio_nacimiento
              : "",
          canton_nacimiento:
            result.estado.canton_nacimiento.label !== null
              ? result.estado.canton_nacimiento
              : "",
          departamento_residencia:
            result.estado.departamento_residencia.label !== null
              ? result.estado.departamento_residencia
              : "",
          municipio_residencia:
            result.estado.municipio_residencia.label !== null
              ? result.estado.municipio_residencia
              : "",
          canton_residencia:
            result.estado.canton_residencia.label !== null
              ? result.estado.canton_residencia
              : "",
          tipo_documento:
            result.estado.tipo_documento.label !== null
              ? result.estado.tipo_documento
              : "",
          numero_documento:
            result.estado.numero_documento !== null
              ? result.estado.numero_documento
              : "",
          estado_civil:
            result.estado.estado_civil.label !== null
              ? result.estado.estado_civil
              : "",
          profesion:
            result.estado.profesion_oficio.label !== null
              ? result.estado.profesion_oficio
              : "",
          direccion:
            result.estado.direccion !== null ? result.estado.direccion : "",
          fecha_matrimonio:
            result.estado.fecha_matrimonio !== null
              ? result.estado.fecha_matrimonio
              : "",
          nombre_conyugue:
            result.estado.nombre_conyugue !== null
              ? result.estado.nombre_conyugue
              : "",
          apellido_casada:
            result.estado.apellido_casada !== null
              ? result.estado.apellido_casada
              : "",
        });
        this.getPersonUserIfExists();
        this.setState({ loadingData: false });
        //Alerts.loading_reload(false);
      } else {
      }
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();

    if (this.props.match.params.codigo) {
      if (this.validator.allValid()) {
        if (
          this.state.mailExists === false &&
          this.refs.formPersona.state.duiExiste === false &&
          this.refs.formPersona.state.mailExists === false &&
          this.refs.formPersona.state.duiEsValido === true
        ) {
          Alerts.loading_reload(true);

          let persona = {};
          this.estadoNoEsNullNiVacio(this.state.codigo_persona) &&
            (persona.id = this.state.codigo_persona);
          this.estadoNoEsNullNiVacio(this.state.nombres) &&
            (persona.nombre = this.state.nombres);
          this.estadoNoEsNullNiVacio(this.state.apellidos) &&
            (persona.apellido = this.state.apellidos);
          this.estadoNoEsNullNiVacio(this.state.tipo_documento) &&
            (persona.tipoDocumento = this.state.tipo_documento.value);
          this.estadoNoEsNullNiVacio(this.state.numero_documento) &&
            (persona.numeroDocumento = this.state.numero_documento);
          this.estadoNoEsNullNiVacio(this.state.telefono) &&
            (persona.telefono = this.state.telefono);
          this.estadoNoEsNullNiVacio(this.state.correo) &&
            (persona.correo = this.state.correo);
          this.estadoNoEsNullNiVacio(this.state.sexo) &&
            (persona.sexo = this.state.sexo.value);
          this.estadoNoEsNullNiVacio(this.state.estado_civil) &&
            (persona.estadoCivil = this.state.estado_civil.value);
          this.estadoNoEsNullNiVacio(this.state.nacionalidad) &&
            (persona.nacionalidad = this.state.nacionalidad.value);
          this.estadoNoEsNullNiVacio(this.state.profesion) &&
            (persona.profesion = this.state.profesion.value);
          this.estadoNoEsNullNiVacio(this.state.fotografia) &&
            (persona.fotoPerfil = this.state.fotografia);
          this.estadoNoEsNullNiVacio(this.state.direccion) &&
            (persona.direccion = this.state.direccion);
          this.estadoNoEsNullNiVacio(this.state.departamento_nacimiento) &&
            (persona.departamentoNacimiento =
              this.state.departamento_nacimiento.value);
          this.estadoNoEsNullNiVacio(this.state.municipio_nacimiento) &&
            (persona.municipioNacimiento =
              this.state.municipio_nacimiento.value);
          this.estadoNoEsNullNiVacio(this.state.canton_nacimiento) &&
            (persona.cantonNacimiento = this.state.canton_nacimiento.value);
          this.estadoNoEsNullNiVacio(this.state.fecha_nacimiento) &&
            (persona.fechaNacimiento = this.state.fecha_nacimiento);
          this.estadoNoEsNullNiVacio(this.state.departamento_residencia) &&
            (persona.departamentoResidencia =
              this.state.departamento_residencia.value);
          this.estadoNoEsNullNiVacio(this.state.municipio_residencia) &&
            (persona.municipioResidencia =
              this.state.municipio_residencia.value);
          this.estadoNoEsNullNiVacio(this.state.canton_residencia) &&
            (persona.cantonResidencia = this.state.canton_residencia.value);
          this.estadoNoEsNullNiVacio(this.state.licenciaMinisterial) &&
            this.estadoNoEsNullNiVacio(this.state.apellido_casada) &&
            (persona.apellidoCasada = this.state.apellido_casada);
          this.estadoNoEsNullNiVacio(this.state.fecha_matrimonio) &&
            (persona.fechaMatrimonio = this.state.fecha_matrimonio);
          this.estadoNoEsNullNiVacio(this.state.nombre_conyugue) &&
            (persona.nombreConyugue = this.state.nombre_conyugue);
          this.estadoNoEsNullNiVacio(this.state.tipoMiembro);

          HTTP.update(persona, "persona", "personas", "personas").then(
            (result) => {
              Alerts.loading_reload(false);

              if (result === true) this.setState({ redirect: true });
            }
          );
        } else {
          // alertas

          if (this.state.mailExists === true) {
            Alerts.alertEmpty(
              "¡Correo ingresado ya existe en el sistema!",
              "Pruebe con un correo diferente",
              "warning"
            );
          }

          if (this.refs.formPersona.state.duiExiste === true) {
            Alerts.alertEmpty(
              "¡Número documento ingresado ya existe en el sistema!",
              "Pruebe con un número de documento distinto",
              "warning"
            );
          }

          if (this.refs.formPersona.state.mailExists === true) {
            Alerts.alertEmpty(
              "¡Correo ingresado ya existe!",
              "Pruebe con un correo diferente",
              "warning"
            );
          }

          if (this.refs.formPersona.state.duiEsValido === false) {
            Alerts.alertEmpty(
              "¡Número de DUI no es correcto!",
              "Ingreselo nuevamente",
              "warning"
            );
          }
        }
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    } else {
      if (this.validator.allValid()) {
        if (
          this.state.mailExists === false &&
          this.refs.formPersona.state.duiExiste === false &&
          this.refs.formPersona.state.mailExists === false &&
          this.refs.formPersona.state.duiEsValido === true
        ) {
          Alerts.loading_reload(true);

          const data = new FormData();
          this.estadoNoEsNullNiVacio(this.state.codigo_persona) &&
            data.append("codigoPersona", this.state.codigoPersona);
          this.estadoNoEsNullNiVacio(this.state.nombres) &&
            data.append("nombre", this.state.nombres);
          this.estadoNoEsNullNiVacio(this.state.apellidos) &&
            data.append("apellido", this.state.apellidos);
          this.estadoNoEsNullNiVacio(this.state.tipo_documento) &&
            data.append("tipoDocumento", this.state.tipo_documento.value);
          this.estadoNoEsNullNiVacio(this.state.numero_documento) &&
            data.append("numeroDocumento", this.state.numero_documento);
          this.estadoNoEsNullNiVacio(this.state.telefono) &&
            data.append("telefono", this.state.telefono);
          this.estadoNoEsNullNiVacio(this.state.correo) &&
            data.append("correo", this.state.correo);
          this.estadoNoEsNullNiVacio(this.state.sexo) &&
            data.append("sexo", this.state.sexo.value);
          this.estadoNoEsNullNiVacio(this.state.estado_civil) &&
            data.append("estadoCivil", this.state.estado_civil.value);
          this.estadoNoEsNullNiVacio(this.state.nacionalidad) &&
            data.append("nacionalidad", this.state.nacionalidad.value);
          this.estadoNoEsNullNiVacio(this.state.profesion) &&
            data.append("profesion", this.state.profesion.value);
          this.estadoNoEsNullNiVacio(this.state.fotografia) &&
            data.append("fotoPerfil", this.state.fotografia);
          this.estadoNoEsNullNiVacio(this.state.direccion) &&
            data.append("direccion", this.state.direccion);
          this.estadoNoEsNullNiVacio(this.state.departamento_nacimiento) &&
            data.append(
              "departamentoNacimiento",
              this.state.departamento_nacimiento.value
            );
          this.estadoNoEsNullNiVacio(this.state.municipio_nacimiento) &&
            data.append(
              "municipioNacimiento",
              this.state.municipio_nacimiento.value
            );
          this.estadoNoEsNullNiVacio(this.state.canton_nacimiento) &&
            data.append("cantonNacimiento", this.state.canton_nacimiento.value);
          this.estadoNoEsNullNiVacio(this.state.fecha_nacimiento) &&
            data.append("fechaNacimiento", this.state.fecha_nacimiento);
          this.estadoNoEsNullNiVacio(this.state.departamento_residencia) &&
            data.append(
              "departamentoResidencia",
              this.state.departamento_residencia.value
            );
          this.estadoNoEsNullNiVacio(this.state.municipio_residencia) &&
            data.append(
              "municipioResidencia",
              this.state.municipio_residencia.value
            );
          this.estadoNoEsNullNiVacio(this.state.canton_residencia) &&
            data.append("cantonResidencia", this.state.canton_residencia.value);
          this.estadoNoEsNullNiVacio(this.state.fecha_matrimonio) &&
            data.append("fechaMatrimonio", this.state.fecha_matrimonio);
          this.estadoNoEsNullNiVacio(this.state.nombre_conyugue) &&
            data.append("nombreConyugue", this.state.nombre_conyugue);
          this.estadoNoEsNullNiVacio(this.state.apellido_casada) &&
            data.append("apellidoCasada", this.state.apellido_casada);

          if (this.state.createUser === true) {
            data.append("createUser", 1);
            data.append("iglesia", null);
            data.append("correo_electronico", this.state.correo_electronico);
            data.append("rol", this.state.rol.value);
            data.append("alias", this.state.alias);
          }
          Request.POST_FILE("personas", data).then((result) => {
            Alerts.loading_reload(false);

            if (result !== false) {
              if (result.status === 200) {
                Alerts.alertEmpty(
                  "Persona guardada con éxito",
                  "Administración de personas",
                  "success"
                );
                this.setState({ redirect: true });
              } else {
                Alerts.alertEmpty(
                  "Persona no se ha podido guardar",
                  "Administración de personas",
                  "error"
                );
              }
            }
          });
        } else {
          //alertas

          if (this.state.mailExists === true) {
            Alerts.alertEmpty(
              "¡Correo ingresado ya existe en el sistema!",
              "Pruebe con un correo diferente",
              "warning"
            );
          }

          if (this.refs.formPersona.state.duiExiste === true) {
            Alerts.alertEmpty(
              "¡Número documento ingresado ya existe en el sistema!",
              "Pruebe con un número de documento distinto",
              "warning"
            );
          }

          if (this.refs.formPersona.state.mailExists === true) {
            Alerts.alertEmpty(
              "¡Correo ingresado ya existe!",
              "Pruebe con un correo diferente",
              "warning"
            );
          }

          if (this.refs.formPersona.state.duiEsValido === false) {
            Alerts.alertEmpty(
              "¡Número de DUI no es correcto!",
              "Ingreselo nuevamente",
              "warning"
            );
          }
        }
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  };
  //USUARIO
  validatorMessageUser(name, state, extraOptions = "") {
    return (
      this.validator.message(
        name,
        this.state[state],
        "required" + extraOptions
      ) && (
        <span className="label label-light-danger">
          {this.validator.message(
            name,
            this.state[state],
            "required" + extraOptions
          )}
        </span>
      )
    );
  }
  //Par usuarios

  getRoles() {
    let data = [];

    HTTP.findAll("roles/select").then((result) => {
      result.forEach((element) => {
        data.push({
          label: element.name,
          value: element.rol_id,
        });
      });
    });

    return data;
  }

  getPersonUserIfExists() {
    HTTP.findById(this.props.match.params.codigo, "usuarios/all").then(
      (result) => {
        this.setState({
          hasUser: result.length ? true : false,
          userId: result.length ? result[0].id : 0,
        });
        Alerts.loading_reload(false);
      }
    );
  }
  deleteAccess() {
    const data = {
      code: this.state.userId,
    };
    HTTP.disable(data, "usuario", "Usuarios", "usuarios").then((result) => {
      this.setState({ redirect: result });
    });
  }
  getIglesias() {
    let data = [];

    HTTP.findAll("iglesias/select").then((result) => {
      result.forEach((element) => {
        data.push({
          label: element.nombre,
          value: element.id,
          codigo: element.codigo,
        });
      });
    });

    return data;
  }

  getIglesiasForSelect = (inputValue, callback) => {
    const tempArray = [];

    if (inputValue !== "" && inputValue !== null) {
      clearTimeout(this.timer_cuentas);
      this.timer_cuentas = setTimeout(() => {
        HTTP.findById(inputValue, "iglesias/select").then((data) => {
          if (data !== false) {
            data.forEach((element) => {
              tempArray.push({
                label: element.nombre,
                value: element.id,
                codigo: element.codigo,
              });
            });
            callback(tempArray);
          } else {
            callback([]);
          }
        });
      }, 500);
    }
  };

  timerMailExist = null;
  mailExists() {
    clearTimeout(this.timerMailExist);
    if (this.state.correo_electronico !== "") {
      if (this.props.match.params.codigo) {
        if (
          this.state.correo_electronico !== this.state.correo_electronico_actual
        ) {
          this.timerMailExist = setTimeout(() => {
            Request.GET(
              "usuarios/validar/correo",
              this.state.correo_electronico
            ).then((result) => {
              if (result.status === 200) {
                this.setState({ mailExists: result.data.valor === 1 });
              }
            });
          }, 800);
        }
      } else {
        this.timerMailExist = setTimeout(() => {
          Request.GET(
            "usuarios/validar/correo",
            this.state.correo_electronico
          ).then((result) => {
            if (result.status === 200) {
              this.setState({ mailExists: result.data.valor === 1 });
            }
          });
        }, 800);
      }
    }
  }

  onPersonaNotExists() {
    this.setState({ redirect: true });
  }
  onPersonaLoaded() {
    this.getPersonUserIfExists();
  }
  estadoNoEsNullNiVacio = (value) => value !== null && value !== "";
  saveData = (keyElemento, valorElemento) => {
    this.setState({ [keyElemento]: valorElemento });
  };
  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={800} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="700" />
        </ContentLoader>
      </React.Fragment>
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/recursos_humanos/personas" />;
    }

    return (
      <React.Fragment>
        <LayoutPanelFormulario
          titulo={this.state.title}
          buttons={
            <div className="card-footer text-center">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-outline-inverse mr-2 "
                  onClick={() => {
                    this.setState({ redirect: true });
                  }}
                  disabled={this.state.loading || this.state.loadingData}
                >
                  <i className="fa fa-close mr-2"></i>CANCELAR
                </button>

                <button
                  type="button"
                  className="btn btn-info"
                  onClick={this.onSubmit}
                  disabled={this.state.loading || this.state.loadingData}
                >
                  <i className="fa fa-save mr-2"></i>
                  {this.state.loading === false ? "GUARDAR" : "GUARDANDO..."}
                </button>
              </div>
            </div>
          }
          tools={""}
        >
          {this.state.loadingData ? (
            this.loadingData()
          ) : (
            <Fragment>
              <PersonaFormulario
                change={this.handleInputChange}
                state={this.state}
                save={this.saveData}
                validator={this.validator}
                forUpdate={this.state.isForUpdate}
                params={this.props.match.params}
                ref="formPersona"
              />
              <div className="form-body">
                <div className="row">
                  <div
                    className="col-lg-6 form-group"
                    hidden={this.props.match.params.codigo}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input filled-in chk-col-light-blue"
                      id="usa_sistema"
                      checked={this.state.createUser}
                      onChange={(e) => {
                        this.setState({
                          createUser: e.target.checked,
                          roles: this.getRoles(),
                          disable_email: e.target.checked,
                        });

                        if (
                          this.state.correo !== "" &&
                          this.state.correo !== "" &&
                          this.validator.fieldValid("Correo Electrónico")
                        ) {
                          this.setState({
                            correo_electronico: this.state.correo,
                          });
                        }

                        if (!e.target.checked) {
                          this.setState({
                            correo_electronico: "",
                            alias: "",
                            rol: "",
                          });
                        }
                      }}
                    />
                    <label
                      className="form-check-label mt-4"
                      htmlFor="usa_sistema"
                    >
                      Crear usuario de acceso al sistema{" "}
                    </label>
                  </div>
                  <div
                    className="col-lg-6 form-group"
                    hidden={!this.state.hasUser}
                  >
                    <div className="alert alert-warning" role="alert">
                      <div className="mb-2">
                        Esta persona tiene un usuario con acceso al sistema
                      </div>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={this.deleteAccess}
                      >
                        ELIMINAR ACCESO
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row" hidden={!this.state.createUser}>
                  <div className="col-lg-3 form-group">
                    <label htmlFor="alias">Alias:(*)</label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Alias"
                      id="alias"
                      name="alias"
                      disabled={!this.state.createUser}
                      value={this.state.alias}
                      onChange={this.handleInputChange}
                    />
                    {this.state.createUser &&
                      this.validatorMessageUser("alias", "alias")}
                  </div>
                  <div className="col-lg-3 form-group">
                    <label htmlFor="correo_electronico">
                      Correo Electrónico:(*)
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="ejemplo@mail.com"
                      id="correo_electronico"
                      name="correo_electronico"
                      value={this.state.correo_electronico}
                      onChange={this.handleInputChangeCorreoCredencioales}
                      onKeyUp={this.mailExists}
                    />
                    {this.state.createUser &&
                      this.validatorMessageUser(
                        "Correo electrónico",
                        "correo_electronico",
                        "|email"
                      )}
                    {this.state.mailExists === true ||
                    this.refs.formPersona?.state.mailExists === true ? (
                      <span className="label label-light-danger">
                        Este correo electrónico ya existe
                      </span>
                    ) : null}
                  </div>

                  <div className="col-lg-3 form-group">
                    <label htmlFor="rol">Rol: (*)</label>
                    <SimpleSelect
                      id="rol"
                      name="rol"
                      placeholder="Seleccione una opción"
                      value={this.state.rol}
                      options={this.state.roles}
                      onChange={(e) => {
                        // this.handleFocusSelect(true)
                        this.setState({
                          rol: e,
                        });
                      }}
                      noOptionsMessage={() => {
                        return "No existen datos";
                      }}
                    />
                    {this.state.createUser &&
                      this.validatorMessageUser("rol", "rol")}
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </LayoutPanelFormulario>
      </React.Fragment>
    );
  }
}
