import React, { Fragment } from "react";
import { ModalTitle } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { isMobile } from "react-device-detect";
import HTTP from "../../helpers/HTTP";
class PersonaInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  refreshPersonaData(codigo) {
    HTTP.findById(codigo, "personas").then((result) => {
      if (result !== false) {
        this.setState({
          codigo_persona: result.estado.codigo,
          nombres: result.estado.nombres !== null ? result.estado.nombres : "",
          apellidos:
            result.estado.apellidos !== null ? result.estado.apellidos : "",
          telefono:
            result.estado.telefono !== null ? result.estado.telefono : "",
          sexo:
            result.estado.sexo.label !== null ? result.estado.sexo.label : "",
          correo: result.estado.correo !== null ? result.estado.correo : "",
          direccion:
            result.estado.direccion !== null ? result.estado.direccion : "",
        });
      } else {
      }
    });
  }
  toggle(codigo) {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
    this.refreshPersonaData(codigo);
  }
  render() {
    return (
      <Fragment>
        <Modal size="lg" isOpen={this.state.isOpen}>
          <ModalHeader className="bg-info" toggle={this.toggle}>
            <ModalTitle className="text-light" as="h3">
              Datos
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-body">
                    <h3 class="box-title">Información básica</h3>
                    <hr class="m-t-0 m-b-40" />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label  col-md-3">Nombre:</label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {" "}
                              {this.state.nombres}{" "}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label  col-md-3">
                            Apellido:
                          </label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {" "}
                              {this.state.apellidos}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-3">Género:</label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {" "}
                              {this.state.sexo}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-3">
                            Teléfono:
                          </label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {this.state.telefono}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-3">
                            Correo Electrónico:
                          </label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {this.state.correo}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="control-label col-md-3">
                            Dirección:
                          </label>
                          <div class="col-md-9">
                            <p class="form-control-static">
                              {this.state.direccion}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-actions">
                    <h3 class="box-title">Acciones</h3>
                    <hr class="m-t-0 m-b-40" />
                    <div class="d-flex align-content-center justify-content-center flex-wrap">
                      <a
                        href={
                          "https://wa.me/503" +
                          this.state.telefono?.replace("-", "")
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-info mr-1"
                      >
                        <i class="fa fa-whatsapp"></i> Whatsapp
                      </a>
                      {isMobile && (
                        <a
                          href={"tel:" + this.state.telefono}
                          target="_blank"
                          rel="noopener noreferrer"
                          class="btn btn-info mr-1"
                        >
                          <i class="fa fa-phone"></i> Llamar
                        </a>
                      )}
                      <a
                        href={"mailto:" + this.state.correo}
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-info"
                      >
                        <i class="fa fa-envelope"></i> Enviar correo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="text-right"></ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default PersonaInfoModal;
