import React from "react";

function Footer(props) {
  var date = new Date();
  var year = date.getFullYear();
  return (
    <React.Fragment>
      <footer className="footer">
        © {year} {process.env.REACT_APP_COPY}
        <div className="pull-right">
          Hecho con ❤️ en San Miguel, El Salvador.
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
