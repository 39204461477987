import React, { Component, Fragment } from "react";
import Select from "react-select";
import HTTP from "../../helpers/HTTP";
import AsyncSelect from "react-select/async";
import ExtFile from "../../services/ExtFile";
import Alerts from "../../services/Alerts";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputMask from "react-input-mask";
import { isDUI } from "sivar-utils";
import Request from "../../services/Request";
export default class PersonaFormulario extends Component {
  constructor(props) {
    super(props);
    this.toggleImageForm = this.toggleImageForm.bind(this);
    this.documentNumberExists = this.documentNumberExists.bind(this);
    this.mailExistsPersona = this.mailExistsPersona.bind(this);
    this.state = this.inicial_state;
  }
  inicial_state = {
    tipos_documentos: [],
    sexos: [],
    nacionalidades: [],
    departamentos: [],
    municipios_nacimiento: [],
    cantones_nacimiento: [],
    municipios_residencia: [],
    cantones_residencia: [],
    estados_civiles: [],
    profesiones: [],
    disabled_select_tipo_documento: true,
    disabled_select_sexo: true,
    disabled_select_nacionalidad: true,
    disabled_select_departamento: true,
    disabled_select_municipio: true,
    disabled_select_canton: true,
    disabled_select_estado_civil: true,
    disabled_select_profesion: true,

    modalImageIsOpen: false,
    es_dui: false,
    duiExiste: false,
    duiEsValido: true,
    mailExists: false,
  };
  componentDidMount() {
    this.setState({
      tipos_documentos: this.getTiposDocumentos(),
      sexos: this.getSexos(),
      nacionalidades: this.getNacionalidades(),
      departamentos: this.getDepartamentos(),
      estados_civiles: this.getEstadosCiviles(),
      profesiones: this.getProfesiones(),
    });
  }

  getTiposDocumentos() {
    let data = [];

    HTTP.findAll("generales/tipo_documento").then((result) => {
      result.forEach((element) => {
        if (this.props.notShowMinorsOptions) {
          element.nombre !== "MENOR DE EDAD" &&
            data.push({ label: element.nombre, value: element.id });
        } else {
          data.push({ label: element.nombre, value: element.id });
        }
      });
      this.setState({ disabled_select_tipo_documento: false });
    });

    return data;
  }

  getEstadosCiviles() {
    let data = [];

    HTTP.findAll("generales/estado_civil").then((result) => {
      result.forEach((element) => {
        data.push({ label: element.nombre, value: element.id });
      });
      this.setState({ disabled_select_estado_civil: false });
    });

    return data;
  }
  getSexos() {
    let data = [];

    HTTP.findAll("generales/comodin/sexo_persona").then((result) => {
      result.forEach((element) => {
        data.push({ label: element.nombre, value: element.id });
      });
      this.setState({ disabled_select_sexo: false });
    });

    return data;
  }
  timer_nacionalidades = null;
  getNacionalidadesParam = (inputValue, callback) => {
    const tempArray = [];

    if (inputValue !== "" && inputValue !== null) {
      clearTimeout(this.timer_nacionalidades);
      this.timer_nacionalidades = setTimeout(() => {
        HTTP.findById(inputValue, `generales/nacionalidad`).then((data) => {
          if (data !== false) {
            data.forEach((element) => {
              tempArray.push({
                label: element.nombre,
                value: element.id,
              });
            });
            callback(tempArray);
          } else {
            callback([]);
          }
        });
      }, 500);
    }
  };
  getNacionalidades() {
    let data = [];

    HTTP.findAll(`generales/nacionalidad`).then((result) => {
      result.forEach((element) => {
        data.push({
          label: element.nombre,
          value: element.id,
        });

        this.props.save("nacionalidad", {
          label: "SALVADOREÑA",
          value: "55",
        });
      });

      this.setState({ disabled_select_nacionalidad: false });
    });

    return data;
  }

  timer_profesiones = null;
  getProfesionesParam = (inputValue, callback) => {
    const tempArray = [];

    if (inputValue !== "" && inputValue !== null) {
      clearTimeout(this.timer_profesiones);
      this.timer_profesiones = setTimeout(() => {
        HTTP.findById(inputValue, `generales/profesiones`).then((data) => {
          if (data !== false) {
            data.forEach((element) => {
              tempArray.push({
                label: element.nombre,
                value: element.id,
              });
            });
            callback(tempArray);
          } else {
            callback([]);
          }
        });
      }, 500);
    }
  };
  getProfesiones() {
    let data = [];

    HTTP.findAll(`generales/profesiones`).then((result) => {
      result.forEach((element) => {
        data.push({
          label: element.nombre,
          value: element.id,
        });
      });

      this.setState({ disabled_select_profesion: false });
    });

    return data;
  }

  ///PARA OBTENER DEPARTAMENTOS
  getDepartamentos() {
    let data = [];

    HTTP.findAll(`generales/departamentos`).then((result) => {
      result.forEach((element) => {
        data.push({
          label: element.nombre,
          value: element.id,
        });
      });

      this.setState({ disabled_select_departamento: false });
    });

    return data;
  }

  ///PARA OBTENER Municipio

  getMunicipiosNacimientoParam = (inputValue, callback) => {
    const tempArray = [];

    if (this.state.departamento !== "" && this.state.departamento !== null) {
      if (inputValue !== "" && inputValue !== null) {
        clearTimeout(this.timer_cuentas);
        this.timer_cuentas = setTimeout(() => {
          HTTP.findById(
            inputValue,
            `generales/municipios/${this.props.state.departamento_nacimiento.value}`
          ).then((data) => {
            if (data !== false) {
              data.forEach((element) => {
                tempArray.push({
                  label: element.nombre,
                  value: element.id,
                });
              });
              callback(tempArray);
            } else {
              callback([]);
            }
          });
        }, 500);
      }
    } else {
      callback([]);
    }
  };
  getMunicipiosResidenciaParam = (inputValue, callback) => {
    const tempArray = [];

    if (this.state.departamento !== "" && this.state.departamento !== null) {
      if (inputValue !== "" && inputValue !== null) {
        clearTimeout(this.timer_cuentas);
        this.timer_cuentas = setTimeout(() => {
          HTTP.findById(
            inputValue,
            `generales/municipios/${this.props.state.departamento_residencia.value}`
          ).then((data) => {
            if (data !== false) {
              data.forEach((element) => {
                tempArray.push({
                  label: element.nombre,
                  value: element.id,
                });
              });
              callback(tempArray);
            } else {
              callback([]);
            }
          });
        }, 500);
      }
    } else {
      callback([]);
    }
  };
  getMunicipios(departamento) {
    let data = [];

    HTTP.findAll(`generales/municipios/${departamento}`).then(
      async (result) => {
        result.forEach((element) => {
          data.push({
            label: element.nombre,
            value: element.id,
          });
        });

        await this.setState({ disabled_select_municipio: false });
        this.forceUpdate();
      }
    );

    return data;
  }

  getCantonesResidenciaParam = (inputValue, callback) => {
    const tempArray = [];

    if (this.state.canton !== "" && this.state.canton !== null) {
      if (inputValue !== "" && inputValue !== null) {
        clearTimeout(this.timer_cuentas);
        this.timer_cuentas = setTimeout(() => {
          HTTP.findById(
            inputValue,
            `generales/cantones/${this.props.state.municipio_residencia.value}`
          ).then((data) => {
            if (data !== false) {
              data.forEach((element) => {
                tempArray.push({
                  label: element.nombre,
                  value: element.id,
                });
              });
              callback(tempArray);
            } else {
              callback([]);
            }
          });
        }, 500);
      }
    } else {
      callback([]);
    }
  };
  getCantonesNacimientoParam = (inputValue, callback) => {
    const tempArray = [];

    if (this.state.canton !== "" && this.state.canton !== null) {
      if (inputValue !== "" && inputValue !== null) {
        clearTimeout(this.timer_cuentas);
        this.timer_cuentas = setTimeout(() => {
          HTTP.findById(
            inputValue,
            `generales/cantones/${this.props.state.municipio_nacimiento.value}`
          ).then((data) => {
            if (data !== false) {
              data.forEach((element) => {
                tempArray.push({
                  label: element.nombre,
                  value: element.id,
                });
              });
              callback(tempArray);
            } else {
              callback([]);
            }
          });
        }, 500);
      }
    } else {
      callback([]);
    }
  };
  getCantones(municipio) {
    let data = [];

    HTTP.findAll(`generales/cantones/${municipio}`).then(async (result) => {
      result.forEach((element) => {
        data.push({
          label: element.nombre,
          value: element.id,
        });
      });

      await this.setState({ disabled_select_canton: false });
      this.forceUpdate();
    });

    return data;
  }
  handleFileChange = (e) => {
    // const idComponente = e.target.id;
    if (e.target.files[0] !== undefined) {
      let name = e.target.files[0].name;

      if (ExtFile.isImage(name)) {
        this.props.save("fotografia", e.target.files[0]);
      } else {
        Alerts.alertEmpty(
          "¡El archivo seleccionado no es válido!",
          "Solo se permiten archivos PNG, JPG, JPEG",
          "warning"
        );
        e.target.value = null;
        this.props.save("fotografia", null);
      }
    } else {
      this.props.save("fotografia", null);
    }
  };
  changeFoto = async (e) => {
    this.setState({ changingFoto: true });
    const data = new FormData();
    data.append("fotoPerfil", this.props.state.fotografia);
    //Añadimos el id de la persona
    data.append("userId", this.props.state.idPersona);
    HTTP.create(data, "persona", "personas", "personas/avatar").then(
      (result) => {
        this.setState({ changingFoto: false });
        this.toggleImageForm();
      }
    );
  };
  toggleImageForm() {
    this.setState((prevState) => ({
      modalImageIsOpen: !prevState.modalImageIsOpen,
    }));
  }

  timerDocumentExist = null;
  documentNumberExists() {
    clearTimeout(this.timerDocumentExist);

    if (
      this.props.state.numero_documento
        ?.replaceAll("_", "")
        .replace("-", "") !== ""
    ) {
      this.timerDocumentExist = setTimeout(() => {
        Request.GET(
          "personas/document",
          this.props.state.numero_documento
        ).then((result) => {
          if (result.status === 200) {
            this.setState({ duiExiste: true });
          } else if (result.status === 404) {
            this.setState({ duiExiste: false });
          }
        });
        if (this.state.es_dui) {
          this.setState({
            duiEsValido: isDUI(this.props.state.numero_documento),
          });
        }
      }, 800);
    } else {
      this.setState({ duiExiste: false, duiEsValido: true });
    }
  }

  timerMailCorreo = null;
  mailExistsPersona() {
    clearTimeout(this.timerMailCorreo);
    if (typeof this.props.params?.codigo) {
      if (this.props.state.correo !== "") {
        if (
          this.props.state.correo !== this.props.state.correo_electronico_actual
        ) {
          this.timerMailCorreo = setTimeout(() => {
            Request.GET(
              "usuarios/validar/correo",
              this.props.state.correo
            ).then((result) => {
              if (result.status === 200) {
                this.setState({ mailExists: result.data.valor > 0 });
              }
            });
          }, 500);
        }
      }
    } else {
      if (this.props.state.correo !== "") {
        if (
          this.props.state.correo_electronico_actual !== "" &&
          this.props.state.correo_electronico_actual !== null
        ) {
          if (
            this.props.state.correo !==
            this.props.state.correo_electronico_actual
          ) {
            this.timerMailCorreo = setTimeout(() => {
              Request.GET(
                "usuarios/validar/correo",
                this.props.state.correo
              ).then((result) => {
                if (result.status === 200) {
                  this.setState({ mailExists: result.data.valor > 0 });
                }
              });
            }, 500);
          }
        } else {
          this.timerMailCorreo = setTimeout(() => {
            Request.GET(
              "usuarios/validar/correo",
              this.props.state.correo
            ).then((result) => {
              if (result.status === 200) {
                this.setState({ mailExists: result.data.valor > 0 });
              }
            });
          }, 500);
        }
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <h3 className="box-title">Datos Generales</h3>
        <hr className="mt-0 mb-4"></hr>
        <div className="row">
          <div className="col-lg-3 form-group">
            <label htmlFor="">Estado Civil:</label>

            <Select
              id="estado_civil"
              name="estado_civil"
              placeholder="Selecione una opción"
              options={this.state.estados_civiles}
              value={this.props.state.estado_civil}
              isClearable={true}
              isDisabled={this.state.disabled_select_estado_civil}
              onChange={(e) => {
                this.props.save("estado_civil", e);
              }}
            />
            {/*  {this.props.validator.message(
              "estado civil",
              this.props.state.estado_civil,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "estado civil",
                  this.props.state.estado_civil,
                  "required"
                )}
              </span>
            )} */}
          </div>
          <div className="col-lg-3 form-group">
            <label htmlFor="">Nombres: (*)</label>
            <input
              type="text"
              id="nombres"
              value={this.props.state.nombres}
              className="form-control"
              onChange={this.props.change}
              onFocus={this.handleFocus}
              placeholder="Escriba los nombres"
            />
            {this.props.validator.message(
              "Nombres",
              this.props.state.nombres,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "Nombres",
                  this.props.state.nombres,
                  "required"
                )}
              </span>
            )}
          </div>

          <div className="col-lg-3 form-group">
            <label htmlFor="">Apellidos: (*)</label>
            <input
              type="text"
              id="apellidos"
              value={this.props.state.apellidos}
              className="form-control"
              onChange={this.props.change}
              onFocus={this.handleFocus}
              placeholder="Escriba los apellidos"
            />
            {this.props.validator.message(
              "Apellidos",
              this.props.state.apellidos,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "Apellidos",
                  this.props.state.apellidos,
                  "required"
                )}
              </span>
            )}
          </div>
          <div className="col-lg-3 form-group">
            <label htmlFor="">Apellido de casada:</label>
            <input
              type="text"
              id="apellido_casada"
              value={this.props.state.apellido_casada}
              className="form-control"
              onChange={this.props.change}
              placeholder="Escriba el apellido de casada"
              disabled={this.props.state.estado_civil?.value !== 2}
            />
          </div>
          <div className="col-lg-3 form-group">
            <label htmlFor="">Tipo Documento:</label>

            <Select
              id="tipo_documento"
              name="tipo_documento"
              placeholder="Selecione una opción"
              options={this.state.tipos_documentos}
              value={this.props.state.tipo_documento}
              isClearable={true}
              isDisabled={this.state.disabled_select_tipo_documento}
              onChange={(e) => {
                this.props.save("tipo_documento", e);
                this.props.save("numero_documento", "");

                if (e !== null) {
                  if (e.value === 1) {
                    this.setState({ es_dui: true });
                  } else {
                    this.setState({ es_dui: false });
                  }
                } else {
                  this.setState({ es_dui: false });
                  this.props.save("numero_documento", "");
                }
              }}
            />
            {/*  {this.props.validator.message(
              "Tipo documento",
              this.props.state.tipo_documento,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "Tipo documento",
                  this.props.state.tipo_documento,
                  "required"
                )}
              </span>
            )} */}
          </div>

          <div className="col-lg-3 form-group">
            <label htmlFor="">
              Número Documento:{" "}
              {this.props.state.tipo_documento !== "" &&
              this.props.state.tipo_documento
                ? this.props.state.tipo_documento.value === 1 ||
                  this.props.state.tipo_documento.value === 2
                  ? `(*)`
                  : null
                : ``}
            </label>

            {this.state.es_dui === false ? (
              <input
                type="text"
                id="numero_documento"
                value={this.props.state.numero_documento}
                className="form-control"
                onChange={this.props.change}
                onFocus={this.handleFocus}
                placeholder="Número Documento"
                disabled={
                  this.props.state.tipo_documento?.value === 4 ||
                  this.props.state.tipo_documento?.value === 3 ||
                  this.props.state.tipo_documento === "" ||
                  this.props.state.tipo_documento === null
                }
              />
            ) : (
              <Fragment>
                <InputMask
                  type="text"
                  className="form-control"
                  placeholder="Número Documento"
                  id="numero_documento"
                  mask="99999999-9"
                  value={this.props.state.numero_documento}
                  onChange={this.props.change}
                  //disabled={this.state.disabled_numero_documento}
                  onKeyUp={this.documentNumberExists}
                ></InputMask>
                {/* {this.props.validator.message(
                  "numero documento",
                  this.state.duiExiste,
                  "numeroExiste"
                ) && (
                  <span className="label label-light-danger">
                    {this.props.validator.message(
                      "numero de documento",
                      this.state.duiExiste,
                      "numeroExiste"
                    )}
                  </span>
                )}
                {this.props.validator.message(
                  "numero documento",
                  this.state.duiEsValido,
                  "duiInvalido"
                ) && (
                  <span className="label label-light-danger">
                    {this.props.validator.message(
                      "numero de documento",
                      this.state.duiEsValido,
                      "duiInvalido"
                    )}
                  </span>
                )} */}
                {this.state.duiEsValido === false && (
                  <span className="label label-light-danger">
                    Numero de documento inválido
                  </span>
                )}
                {this.state.duiExiste && (
                  <span className="label label-light-danger">
                    Numero de DUI ya existe
                  </span>
                )}
              </Fragment>
              //this.state.duiExiste
            )}

            {this.props.validator.message(
              "número documento",
              this.props.state.tipo_documento !== null &&
                this.props.state.tipo_documento !== "" &&
                this.props.state.tipo_documento?.value !== 4 &&
                this.props.state.tipo_documento?.value !== 3
                ? this.props.state.numero_documento
                : true,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "número documento",
                  this.props.state.tipo_documento !== null &&
                    this.props.state.tipo_documento !== "" &&
                    this.props.state.tipo_documento?.value !== 4 &&
                    this.props.state.tipo_documento?.value !== 3
                    ? this.props.state.numero_documento
                    : true,
                  "required"
                )}
              </span>
            )}

            {/*            {this.props.validator.message(
              "número documento",
              this.props.state.tipo_documento !== "" &&
                this.props.state.tipo_documento
                ? this.props.state.tipo_documento.value === 1 ||
                  this.props.state.tipo_documento.value === 2
                  ? this.props.state.numero_documento
                  : true
                : this.props.state.numero_documento,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "número documento",
                  this.props.state.tipo_documento !== "" &&
                    this.props.state.tipo_documento
                    ? this.props.state.tipo_documento.value === 1 ||
                      this.props.state.tipo_documento.value === 2
                      ? this.props.state.numero_documento
                      : true
                    : this.props.state.numero_documento,
                  "required"
                )}
              </span>
            )} */}
          </div>
          <div className="col-lg-3 form-group">
            <label htmlFor="">Teléfono: </label>

            <InputMask
              type="text"
              id="telefono"
              value={this.props.state.telefono}
              className="form-control"
              onChange={this.props.change}
              onFocus={this.handleFocus}
              placeholder="Número de teléfono"
              mask="9999-9999"

              //disabled={this.state.disabled_numero_documento}
              // onKeyUp={this.validar_documentos.bind(
              //   this,
              //   this.state.numero_documento
              // )}
            ></InputMask>
          </div>

          <div className="col-lg-3 form-group">
            <label htmlFor="">Correo Electrónico: </label>
            <input
              type="text"
              id="correo"
              value={this.props.state.correo}
              className="form-control"
              onChange={this.props.change}
              placeholder="E-Mail"
              disabled={this.props.state.disable_email}
              onKeyUp={this.mailExistsPersona}
            />
            {this.state.mailExists === true ? (
              <span className="label label-light-danger">
                Este correo electrónico ya existe
              </span>
            ) : null}
            {this.props.validator.message(
              "Correo Electrónico",
              this.props.state.correo,
              "email"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "Correo Electrónico",
                  this.props.state.correo,
                  "email"
                )}
              </span>
            )}
          </div>

          <div className="col-lg-3 form-group">
            <label htmlFor="">Sexo: (*)</label>

            <Select
              id="sexo"
              name="sexo"
              placeholder="Selecione una opción"
              options={this.state.sexos}
              value={this.props.state.sexo}
              isClearable={true}
              isDisabled={this.state.disabled_select_sexo}
              onChange={(e) => {
                this.props.save("sexo", e);
              }}
            />
            {this.props.validator.message(
              "Sexo",
              this.props.state.sexo,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "Sexo",
                  this.props.state.sexo,
                  "required"
                )}
              </span>
            )}
          </div>

          <div className="col-lg-3 form-group">
            <label htmlFor="">Fecha de matrimonio:</label>
            <input
              type="date"
              id="fecha_matrimonio"
              value={this.props.state.fecha_matrimonio}
              className="form-control"
              onChange={this.props.change}
              disabled={this.props.state.estado_civil?.value !== 2}
            />
          </div>
          <div className="col-lg-3 form-group">
            <label htmlFor="">Nombre del conyugue:</label>
            <input
              type="text"
              id="nombre_conyugue"
              value={this.props.state.nombre_conyugue}
              className="form-control"
              placeholder={"Conyugue"}
              onChange={this.props.change}
              disabled={this.props.state.estado_civil?.value !== 2}
            />
          </div>

          <div className="col-lg-3 form-group">
            <label htmlFor="">Nacionalidad: (*)</label>

            <AsyncSelect
              id="nacionalidad"
              name="nacionalidad"
              placeholder="Seleccione una opción"
              value={this.props.state.nacionalidad}
              isClearable={true}
              loadOptions={this.getNacionalidadesParam}
              defaultOptions={this.state.nacionalidades}
              isDisabled={this.state.disabled_select_nacionalidad}
              ref="select_nacionalidad"
              onChange={(e) => {
                this.props.save("nacionalidad", e);
              }}
              noOptionsMessage={(e) => {
                return "No existen datos";
              }}
            />
            {this.props.validator.message(
              "nacionalidad",
              this.props.state.nacionalidad,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "nacionalidad",
                  this.props.state.nacionalidad,
                  "required"
                )}
              </span>
            )}
          </div>
          <div className="col-lg-3 form-group">
            <label htmlFor="">Profesion U Oficio: (*)</label>

            <AsyncSelect
              id="profesion"
              name="profesion"
              placeholder="Seleccione una opción"
              value={this.props.state.profesion}
              isClearable={true}
              loadOptions={this.getProfesionesParam}
              defaultOptions={this.state.profesiones}
              isDisabled={this.state.disabled_select_profesion}
              ref="select_profesion"
              onChange={(e) => {
                this.props.save("profesion", e);
              }}
              noOptionsMessage={(e) => {
                return "No existen datos";
              }}
            />
            {this.props.validator.message(
              "profesion",
              this.props.state.profesion,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "profesion",
                  this.props.state.profesion,
                  "required"
                )}
              </span>
            )}
          </div>
          <div
            className="col-lg-3 form-group"
            hidden={this.props.forUpdate && true}
          >
            <label htmlFor="fotografia">Fotografía:</label>
            <input
              type="file"
              accept=".png,.jpeg,.jpg"
              className="form-control"
              placeholder="Imagen"
              id="fotografia"
              name="fotografia"
              onChange={this.handleFileChange}
            />
          </div>

          <div
            className="col-lg-6 form-group"
            hidden={!this.props.forUpdate && true}
          >
            <div className="alert alert-info  " role="alert">
              <div className="mb-2">Cambiar la foto de perfil</div>
              <button
                type="button"
                className="btn btn-info"
                onClick={this.toggleImageForm}
              >
                <i className="fa fa-pencil mr-2"></i>
                CAMBIAR FOTO DE PERFIL
              </button>
            </div>
          </div>
          <div className="col-lg-6 form-group">
            <label htmlFor="">Dirección: </label>
            <textarea
              name="direccion"
              id="direccion"
              cols="30"
              rows="4"
              value={this.props.state.direccion}
              className="form-control"
              onChange={this.props.change}
              placeholder="Escriba la Dirección de residencia"
            ></textarea>
          </div>
        </div>
        <h3 className="box-title">Datos de Nacimiento</h3>
        <hr className="mt-0 mb-4"></hr>
        <div className="row">
          <div className="col-lg-3 form-group">
            <label htmlFor="">Departamento Nacimiento:</label>

            <Select
              id="departamento_nacimiento"
              name="departamento_nacimiento"
              placeholder="Seleccione una opción"
              value={this.props.state.departamento_nacimiento}
              isClearable={true}
              options={this.state.departamentos}
              // isDisabled={this.state.disabled_select_zona}
              onChange={async (e) => {
                this.props.save("departamento_nacimiento", e);
                this.props.save("municipio_nacimiento", "");
                this.props.save("canton_nacimiento", "");

                this.setState({
                  municipios_nacimiento: [],

                  cantones_nacimiento: [],
                });
                this.refs.select_municipio.blur();
                this.refs.select_canton.blur();
                if (e !== null) {
                  await this.setState({
                    municipios_nacimiento: this.getMunicipios(e.value),
                  });
                } else {
                  this.props.save("municipio_nacimiento", "");
                  this.props.save("canton_nacimiento", "");

                  this.setState({
                    municipios_nacimiento: [],

                    cantones_nacimiento: [],
                  });
                  this.refs.select_municipio.blur();
                  this.refs.select_canton.blur();
                }
              }}
            />
            {/*   {this.props.validator.message(
              "departamento nacimiento",
              this.props.state.departamento_nacimiento,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "departamento nacimiento",
                  this.props.state.departamento_nacimiento,
                  "required"
                )}
              </span>
            )} */}
          </div>
          <div className="col-lg-3 form-group">
            <label htmlFor="">Municipio Nacimiento:</label>

            <AsyncSelect
              id="municipio_nacimiento"
              name="municipio_nacimiento"
              placeholder="Seleccione una opción"
              value={this.props.state.municipio_nacimiento}
              isClearable={true}
              loadOptions={this.getMunicipiosNacimientoParam}
              defaultOptions={this.state.municipios_nacimiento}
              isDisabled={
                this.props.state.departamento_nacimiento !== "" &&
                this.props.state.departamento_nacimiento !== null
                  ? false
                  : true
              }
              ref="select_municipio"
              onChange={async (e) => {
                // this.handleFocusSelect(true)
                this.props.save("municipio_nacimiento", e);
                this.props.save("canton_nacimiento", "");
                this.setState({
                  cantones_nacimiento: [],
                });
                this.refs.select_canton.blur();

                if (e !== null) {
                  await this.setState({
                    cantones_nacimiento: this.getCantones(e.value),
                  });
                } else {
                  this.props.save("canton_nacimiento", "");

                  this.setState({
                    cantones_nacimiento: [],
                  });
                  this.refs.select_canton.blur();
                }
              }}
              noOptionsMessage={(e) => {
                return "No existen datos";
              }}
            />
            {/* {this.props.validator.message(
              "Municipio Nacimiento",
              this.props.state.municipio_nacimiento,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "Municipio Nacimiento",
                  this.props.state.municipio_nacimiento,
                  "required"
                )}
              </span>
            )} */}
          </div>

          <div className="col-lg-3 form-group">
            <label htmlFor="">Cantón Nacimiento: </label>

            <AsyncSelect
              id="canton_nacimiento"
              name="canton_nacimiento"
              placeholder="Seleccione una opción"
              value={this.props.state.canton_nacimiento}
              isClearable={true}
              loadOptions={this.getCantonesNacimientoParam}
              defaultOptions={this.state.cantones_nacimiento}
              isDisabled={
                this.props.state.municipio_nacimiento !== "" &&
                this.props.state.municipio_nacimiento !== null &&
                this.props.state.departamento_nacimiento !== "" &&
                this.props.state.departamento_nacimiento !== null
                  ? false
                  : true
              }
              ref="select_canton"
              onChange={(e) => {
                this.props.save("canton_nacimiento", e);
              }}
              noOptionsMessage={(e) => {
                return "No existen datos";
              }}
            />
          </div>
          <div className="col-lg-3 form-group">
            <label htmlFor="">Fecha Nacimiento:</label>

            <input
              type="date"
              className="form-control"
              id="fecha_nacimiento"
              value={this.props.state.fecha_nacimiento}
              onChange={this.props.change}
            />
            {/*  {this.props.validator.message(
              "fecha nacimiento",
              this.props.state.fecha_nacimiento,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "fecha nacimiento",
                  this.props.state.fecha_nacimiento,
                  "required"
                )}
              </span>
            )} */}
          </div>
        </div>
        <h3 className="box-title">Datos de Residencia</h3>
        <hr className="mt-0 mb-4"></hr>
        <div className="row">
          <div className="col-lg-3 form-group">
            <label htmlFor="">Departamento Residencia:</label>

            <Select
              id="departamento_residencia"
              name="departamento_residencia"
              placeholder="Seleccione una opción"
              value={this.props.state.departamento_residencia}
              isClearable={true}
              options={this.state.departamentos}
              // isDisabled={this.state.disabled_select_zona}
              onChange={async (e) => {
                this.props.save("departamento_residencia", e);
                this.props.save("municipio_residencia", "");
                this.props.save("canton_residencia", "");

                this.setState({
                  municipios_residencia: [],

                  cantones_residencia: [],
                });
                this.refs.select_municipio_residencia.blur();
                this.refs.select_canton_residencia.blur();
                if (e !== null) {
                  await this.setState({
                    municipios_residencia: this.getMunicipios(e.value),
                  });
                } else {
                  this.props.save("municipio_residencia", "");
                  this.props.save("canton_residencia", "");

                  this.setState({
                    municipios_residencia: [],

                    cantones_residencia: [],
                  });
                  this.refs.select_municipio_residencia.blur();
                  this.refs.select_canton_residencia.blur();
                }
              }}
            />
            {/* {this.props.validator.message(
              "departamento residencia",
              this.props.state.departamento_residencia,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "departamento residencia",
                  this.props.state.departamento_residencia,
                  "required"
                )}
              </span>
            )} */}
          </div>
          <div className="col-lg-3 form-group">
            <label htmlFor="">Municipio Residencia:</label>

            <AsyncSelect
              id="municipio_residencia"
              name="municipio_residencia"
              placeholder="Seleccione una opción"
              value={this.props.state.municipio_residencia}
              isClearable={true}
              loadOptions={this.getMunicipiosResidenciaParam}
              defaultOptions={this.state.municipios_residencia}
              isDisabled={
                this.props.state.departamento_residencia !== "" &&
                this.props.state.departamento_residencia !== null
                  ? false
                  : true
              }
              ref="select_municipio_residencia"
              onChange={async (e) => {
                // this.handleFocusSelect(true)
                this.props.save("municipio_residencia", e);
                this.props.save("canton_residencia", "");
                this.setState({
                  cantones_residencia: [],
                });
                this.refs.select_canton_residencia.blur();

                if (e !== null) {
                  await this.setState({
                    cantones_residencia: this.getCantones(e.value),
                  });
                } else {
                  this.props.save("canton_residencia", "");

                  this.setState({
                    cantones_residencia: [],
                  });
                  this.refs.select_canton_residencia.blur();
                }
              }}
              noOptionsMessage={(e) => {
                return "No existen datos";
              }}
            />
            {/* {this.props.validator.message(
              "Municipio residencia",
              this.props.state.municipio_residencia,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.props.validator.message(
                  "Municipio residencia",
                  this.props.state.municipio_residencia,
                  "required"
                )}
              </span>
            )} */}
          </div>

          <div className="col-lg-3 form-group">
            <label htmlFor="">Cantón Residencia: </label>

            <AsyncSelect
              id="canton_residencia"
              name="canton_residencia"
              placeholder="Seleccione una opción"
              value={this.props.state.canton_residencia}
              isClearable={true}
              loadOptions={this.getCantonesResidenciaParam}
              defaultOptions={this.state.cantones_residencia}
              isDisabled={
                this.props.state.municipio_residencia !== "" &&
                this.props.state.municipio_residencia !== null &&
                this.props.state.departamento_residencia !== "" &&
                this.props.state.departamento_residencia !== null
                  ? false
                  : true
              }
              ref="select_canton_residencia"
              onChange={(e) => {
                this.props.save("canton_residencia", e);
              }}
              noOptionsMessage={(e) => {
                return "No existen datos";
              }}
            />
          </div>
        </div>
        {this.UIChangeFotoModal()}
      </React.Fragment>
    );
  }
  UIChangeFotoModal() {
    return (
      <Modal
        size="lg"
        style={{ maxWidth: "80%", width: "50%" }}
        isOpen={this.state.modalImageIsOpen}
        centered={true}
      >
        <ModalHeader toggle={this.toggleImageForm}>
          Cambiar foto de perfil
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label for="fotoPerfil">Nueva foto de perfil</label>
              <input
                type="file"
                accept=".png,.jpeg,.jpg"
                className="form-control"
                placeholder="Imagen"
                id="fotoPerfil"
                name="fotoPerfil"
                onChange={this.handleFileChange}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="text-right">
          <button
            type="button"
            className="btn btn-info"
            onClick={this.changeFoto}
            disabled={this.state.changingFoto}
          >
            <i className="fa fa-edit mr-2"></i>
            {this.state.changingFoto ? "GUARDANDO" : "GUARDAR"}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
