import React, { Component } from "react";
import { Fragment } from "react";
import LayoutPanelFormulario from "../../components/layouts/panels/LayoutPanelFormulario";
import HTTP from "../../helpers/HTTP";
import SimpleReactValidator from "simple-react-validator";
import es from "../../helpers/ValidatorTranslate_es";
import Request from "../../services/Request";
import Alerts from "../../services/Alerts";
import { Redirect } from "react-router";
import UIInput from "../../components/UICommons/UIInput";
import UIAsynSelect from "../../components/UICommons/UIAsynSelect";
import UITextarea from "../../components/UICommons/UITextarea";
import PersonaFormulario from "../../components/Personas/PersonaFormulario";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TablaFilter from "../../components/tablas/TablaFilter";
import ContentLoader from "react-content-loader";

export default class PastoresForm extends Component {
  initialState = {
    controlFormEdition: false,
    canEditForms: true,
    forUpdate: this.props.match.params.codigo ? true : false,
    redirect: false,
    loading: false,
    personaExists: false,
    loadingData: false,
    isForUpdate: false,
    //datos de la persona
    //estados para persona
    idPersona: null,
    codigoPersona: null,
    nombres: "",
    apellidos: "",
    tipo_documento: "",
    numero_documento: "",
    telefono: "",
    correo: "",
    sexo: "",
    estado_civil: "",
    fecha_matrimonio: "",
    nombre_conyugue: "",
    apellido_casada: "",
    nacionalidad: "",
    profesion: "",
    fotografia: null,
    direccion: "",
    departamento_nacimiento: "",
    municipio_nacimiento: "",
    canton_nacimiento: "",
    fecha_nacimiento: "",
    departamento_residencia: "",
    municipio_residencia: "",
    canton_residencia: "",

    //datos del pastor a guardar
    licenciaMinisterial: "",
    nivelPastoral: null,
    nivelAcademico: null,
    fechaInicioPastoral: "",

    //Datos adicionales a los anteriores
    idPastor: "",
    codigoPastor: "",
    biografia: "",

    //Auxiliares Pastores
    nivelesPastorales: null,
    nivelesAcademicos: null,

    //Existencia de documentos
    licenciaMinisterialExists: false,

    isModalOpen: false,
  };
  constructor(props) {
    super(props);
    //declaraciones de funciones
    //this.setPersona = this.setPersona.bind(this);
    //this.switchFormsEdition = this.switchFormsEdition.bind(this);
    this.licenciaMinisterialExists = this.licenciaMinisterialExists.bind(this);
    this.guardarPastor = this.guardarPastor.bind(this);
    this.init_validator();
    this.toggle = this.toggle.bind(this);
    this.state = this.initialState;
  }
  componentDidMount() {
    if (this.props.match.params.codigo) {
      this.setState({
        isForUpdate: true, //Es para actualizar persona
      });
      this.getPastorData();
    }
  }
  getPersonaData(codigo) {
    HTTP.findById(codigo, "personas").then((result) => {
      if (result !== false) {
        // if (result.estado !== false) {
        this.setState({
          idPersona: result.estado.id,
          codigoPersona:
            result.estado.codigo !== null ? result.estado.codigo : "",
          nombres: result.estado.nombres !== null ? result.estado.nombres : "",
          apellidos:
            result.estado.apellidos !== null ? result.estado.apellidos : "",
          telefono:
            result.estado.telefono !== null ? result.estado.telefono : "",
          sexo: result.estado.sexo !== null && result.estado.sexo.label !== null ? result.estado.sexo : "",
          correo: result.estado.correo !== null ? result.estado.correo : "",
          correo_electronico_actual:
            result.estado.correo !== null ? result.estado.correo : "",
          nacionalidad:
            result.estado.nacionalidad !== null && result.estado.nacionalidad.label !== null
              ? result.estado.nacionalidad
              : "",
          fecha_nacimiento:
            result.estado.fecha_nacimiento !== null
              ? result.estado.fecha_nacimiento
              : "",
          departamento_nacimiento:
            result.estado.departemento_nacimiento !== null && result.estado.departemento_nacimiento.label !== null
              ? result.estado.departemento_nacimiento
              : "",
          municipio_nacimiento:
            result.estado.municipio_nacimiento !== null && result.estado.municipio_nacimiento.label !== null
              ? result.estado.municipio_nacimiento
              : "",
          canton_nacimiento:
            result.estado.canton_nacimiento !== null && result.estado.canton_nacimiento.label !== null
              ? result.estado.canton_nacimiento
              : "",
          departamento_residencia:
            result.estado.departamento_residencia !== null && result.estado.departamento_residencia.label !== null
              ? result.estado.departamento_residencia
              : "",
          municipio_residencia:
            result.estado.municipio_residencia !== null && result.estado.municipio_residencia.label !== null
              ? result.estado.municipio_residencia
              : "",
          canton_residencia:
            result.estado.canton_residencia !== null && result.estado.canton_residencia.label !== null
              ? result.estado.canton_residencia
              : "",
          tipo_documento:
            result.estado.tipo_documento !== null && result.estado.tipo_documento.label !== null
              ? result.estado.tipo_documento
              : "",
          numero_documento:
            result.estado.numero_documento !== null
              ? result.estado.numero_documento
              : "",
          estado_civil:
            result.estado.estado_civil !== null  && result.estado.estado_civil.label !== null
              ? result.estado.estado_civil
              : "",
          profesion:
            result.estado.profesion_oficio !== null && result.estado.profesion_oficio.label !== null
              ? result.estado.profesion_oficio
              : "",
          direccion:
            result.estado.direccion !== null ? result.estado.direccion : "",
          fecha_matrimonio:
            result.estado.fecha_matrimonio !== null
              ? result.estado.fecha_matrimonio
              : "",
          nombre_conyugue:
            result.estado.nombre_conyugue !== null
              ? result.estado.nombre_conyugue
              : "",
          apellido_casada:
            result.estado.apellido_casada !== null
              ? result.estado.apellido_casada
              : "",
        });
      } else {
      }
    });
  }
  getPastorData() {
    //Alerts.loading_reload(true);
    this.setState({ loadingData: true });
    HTTP.findById(this.props.match.params.codigo, "pastores").then(
      async (result) => {
        if (result !== false) {
          // if (result.estado !== false) {
          this.setState({
            idPastor: result.data.id,
            codigoPastor: result.data.codigo,
            licenciaMinisterial:
              result.data.licencia_ministerial !== null
                ? result.data.licencia_ministerial
                : "",
            nivelPastoral: result.data.nivel_pastoral,
            nivelAcademico: result.data.nivel_academico,
            fechaInicioPastoral:
              result.data.fecha_inicio_pastoral !== null
                ? result.data.fecha_inicio_pastoral
                : "",
            biografia:
              result.data.biografia !== null ? result.data.biografia : "",
          });
          /* this.refs.personaForm.setForUpdateForm(true);
        this.refs.personaForm.fillFromPersona(result.data.persona, () => {
          Alerts.loading_reload(false);
        }); */
          await this.getPersonaData(result.data.persona);
          //Alerts.loading_reload(false);
          this.setState({ loadingData: false });
        } else {
        }
      }
    );
  }
  saveData = (keyElemento, valorElemento) => {
    this.setState({ [keyElemento]: valorElemento });
  };

  timerLicenciaMinisterialExists = null;
  licenciaMinisterialExists() {
    clearTimeout(this.timerLicenciaMinisterialExists);
    if (this.state.licenciaMinisterial !== "") {
      this.timerLicenciaMinisterialExists = setTimeout(() => {
        Request.GET("pastores", this.state.licenciaMinisterial).then(
          (result) => {
            if (result.status === 200 && result.data.data) {
              this.setState({ licenciaMinisterialExists: true });
            } else {
              this.setState({ licenciaMinisterialExists: false });
            }
          }
        );
      }, 800);
    }
  }
  init_validator() {
    SimpleReactValidator.addLocale("esp", es);
    this.validator = new SimpleReactValidator({
      validators: {
        numeroExiste: {
          message: "El :attribute ya existe",
          rule: (val) => {
            return !val;
          },
        },
        duiInvalido: {
          message: "El número de DUI es inválido",
          rule: (val) => {
            return val;
          },
        },
      },
      locale: "esp",
    });
  }
  validatorMessage(name, state, extraOptions = "") {
    return (
      this.validator.message(
        name,
        this.state[state],
        "required" + extraOptions
      ) && (
        <span className="label label-light-danger">
          {this.validator.message(
            name,
            this.state[state],
            "required" + extraOptions
          )}
        </span>
      )
    );
  }
  handleInputChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    this.setState({ [idComponente]: valorComponente });
  };
  //Verifica que estado no es nulo (null) ni vacio ("")
  estadoNoEsNullNiVacio = (value) => value !== null && value !== "";
  guardarPastor = () => {
    if (this.validator.allValid()) {
      Alerts.loading_reload(true);
      //Si hay codigo en los params es para actualizar
      if (this.props.match.params.codigo) {
        let nuevoPastor = {};
        this.estadoNoEsNullNiVacio(this.state.codigoPastor) &&
          (nuevoPastor.codigoPastor = this.state.codigoPastor);
        this.estadoNoEsNullNiVacio(this.state.codigoPersona) &&
          (nuevoPastor.id = this.state.codigoPersona);
        this.estadoNoEsNullNiVacio(this.state.nombres) &&
          (nuevoPastor.nombre = this.state.nombres);
        this.estadoNoEsNullNiVacio(this.state.apellidos) &&
          (nuevoPastor.apellido = this.state.apellidos);
        this.estadoNoEsNullNiVacio(this.state.tipo_documento) &&
          (nuevoPastor.tipoDocumento = this.state.tipo_documento.value);
        this.estadoNoEsNullNiVacio(this.state.numero_documento) &&
          (nuevoPastor.numeroDocumento = this.state.numero_documento);
        this.estadoNoEsNullNiVacio(this.state.telefono) &&
          (nuevoPastor.telefono = this.state.telefono);
        this.estadoNoEsNullNiVacio(this.state.correo) &&
          (nuevoPastor.correo = this.state.correo);
        this.estadoNoEsNullNiVacio(this.state.sexo) &&
          (nuevoPastor.sexo = this.state.sexo.value);
        this.estadoNoEsNullNiVacio(this.state.estado_civil) &&
          (nuevoPastor.estadoCivil = this.state.estado_civil.value);
        this.estadoNoEsNullNiVacio(this.state.nacionalidad) &&
          (nuevoPastor.nacionalidad = this.state.nacionalidad.value);
        this.estadoNoEsNullNiVacio(this.state.profesion) &&
          (nuevoPastor.profesion = this.state.profesion.value);
        this.estadoNoEsNullNiVacio(this.state.fotografia) &&
          (nuevoPastor.fotoPerfil = this.state.fotografia);
        this.estadoNoEsNullNiVacio(this.state.direccion) &&
          (nuevoPastor.direccion = this.state.direccion);
        this.estadoNoEsNullNiVacio(this.state.departamento_nacimiento) &&
          (nuevoPastor.departamentoNacimiento =
            this.state.departamento_nacimiento.value);
        this.estadoNoEsNullNiVacio(this.state.municipio_nacimiento) &&
          (nuevoPastor.municipioNacimiento =
            this.state.municipio_nacimiento.value);
        this.estadoNoEsNullNiVacio(this.state.canton_nacimiento) &&
          (nuevoPastor.cantonNacimiento = this.state.canton_nacimiento.value);
        this.estadoNoEsNullNiVacio(this.state.fecha_nacimiento) &&
          (nuevoPastor.fechaNacimiento = this.state.fecha_nacimiento);
        this.estadoNoEsNullNiVacio(this.state.departamento_residencia) &&
          (nuevoPastor.departamentoResidencia =
            this.state.departamento_residencia.value);
        this.estadoNoEsNullNiVacio(this.state.municipio_residencia) &&
          (nuevoPastor.municipioResidencia =
            this.state.municipio_residencia.value);
        this.estadoNoEsNullNiVacio(this.state.canton_residencia) &&
          (nuevoPastor.cantonResidencia = this.state.canton_residencia.value);
        this.estadoNoEsNullNiVacio(this.state.licenciaMinisterial) &&
          (nuevoPastor.licenciaMinisterial = this.state.licenciaMinisterial);
        this.estadoNoEsNullNiVacio(this.state.nivelPastoral) &&
          (nuevoPastor.nivelPastoral = this.state.nivelPastoral.value);
        this.estadoNoEsNullNiVacio(this.state.nivelAcademico) &&
          (nuevoPastor.nivelAcademico = this.state.nivelAcademico.value);
        this.estadoNoEsNullNiVacio(this.state.fechaInicioPastoral) &&
          (nuevoPastor.fechaInicioPastoral = this.state.fechaInicioPastoral);
        this.estadoNoEsNullNiVacio(this.state.idPastor) &&
          (nuevoPastor.idPastor = this.state.idPastor);
        this.estadoNoEsNullNiVacio(this.state.codigoPastor) &&
          (nuevoPastor.codigoPastor = this.state.codigoPastor);
        this.estadoNoEsNullNiVacio(this.state.biografia) &&
          (nuevoPastor.biografia = this.state.biografia);
        this.estadoNoEsNullNiVacio(this.state.apellido_casada) &&
          (nuevoPastor.apellidoCasada = this.state.apellido_casada);
        this.estadoNoEsNullNiVacio(this.state.fecha_matrimonio) &&
          (nuevoPastor.fechaMatrimonio = this.state.fecha_matrimonio);
        this.estadoNoEsNullNiVacio(this.state.nombre_conyugue) &&
          (nuevoPastor.nombreConyugue = this.state.nombre_conyugue);

        HTTP.update(nuevoPastor, "pastor", "pastores", "pastores").then(
          (result) => {
            if (result === true) this.setState({ redirect: true });
          }
        );
      } else {
        let nuevoPastor = new FormData();

        this.estadoNoEsNullNiVacio(this.state.codigoPersona) &&
          nuevoPastor.append("personaCode", this.state.codigoPersona);

        this.estadoNoEsNullNiVacio(this.state.nombres) &&
          nuevoPastor.append("nombre", this.state.nombres);
        this.estadoNoEsNullNiVacio(this.state.apellidos) &&
          nuevoPastor.append("apellido", this.state.apellidos);
        this.estadoNoEsNullNiVacio(this.state.tipo_documento) &&
          nuevoPastor.append("tipoDocumento", this.state.tipo_documento.value);
        this.estadoNoEsNullNiVacio(this.state.numero_documento) &&
          nuevoPastor.append("numeroDocumento", this.state.numero_documento);
        this.estadoNoEsNullNiVacio(this.state.telefono) &&
          nuevoPastor.append("telefono", this.state.telefono);
        this.estadoNoEsNullNiVacio(this.state.correo) &&
          nuevoPastor.append("correo", this.state.correo);
        this.estadoNoEsNullNiVacio(this.state.sexo) &&
          nuevoPastor.append("sexo", this.state.sexo.value);
        this.estadoNoEsNullNiVacio(this.state.estado_civil) &&
          nuevoPastor.append("estadoCivil", this.state.estado_civil.value);
        this.estadoNoEsNullNiVacio(this.state.nacionalidad) &&
          nuevoPastor.append("nacionalidad", this.state.nacionalidad.value);
        this.estadoNoEsNullNiVacio(this.state.profesion) &&
          nuevoPastor.append("profesion", this.state.profesion.value);
        this.estadoNoEsNullNiVacio(this.state.fotografia) &&
          nuevoPastor.append("fotoPerfil", this.state.fotografia);
        this.estadoNoEsNullNiVacio(this.state.direccion) &&
          nuevoPastor.append("direccion", this.state.direccion);
        this.estadoNoEsNullNiVacio(this.state.departamento_nacimiento) &&
          nuevoPastor.append(
            "departamentoNacimiento",
            this.state.departamento_nacimiento.value
          );
        this.estadoNoEsNullNiVacio(this.state.municipio_nacimiento) &&
          nuevoPastor.append(
            "municipioNacimiento",
            this.state.municipio_nacimiento.value
          );
        this.estadoNoEsNullNiVacio(this.state.canton_nacimiento) &&
          nuevoPastor.append(
            "cantonNacimiento",
            this.state.canton_nacimiento.value
          );
        this.estadoNoEsNullNiVacio(this.state.fecha_nacimiento) &&
          nuevoPastor.append("fechaNacimiento", this.state.fecha_nacimiento);
        this.estadoNoEsNullNiVacio(this.state.departamento_residencia) &&
          nuevoPastor.append(
            "departamentoResidencia",
            this.state.departamento_residencia.value
          );
        this.estadoNoEsNullNiVacio(this.state.municipio_residencia) &&
          nuevoPastor.append(
            "municipioResidencia",
            this.state.municipio_residencia.value
          );
        this.estadoNoEsNullNiVacio(this.state.canton_residencia) &&
          nuevoPastor.append(
            "cantonResidencia",
            this.state.canton_residencia.value
          );
        this.estadoNoEsNullNiVacio(this.state.licenciaMinisterial) &&
          nuevoPastor.append(
            "licenciaMinisterial",
            this.state.licenciaMinisterial
          );
        this.estadoNoEsNullNiVacio(this.state.nivelPastoral) &&
          nuevoPastor.append("nivelPastoral", this.state.nivelPastoral.value);
        this.estadoNoEsNullNiVacio(this.state.nivelAcademico) &&
          nuevoPastor.append("nivelAcademico", this.state.nivelAcademico.value);
        this.estadoNoEsNullNiVacio(this.state.fechaInicioPastoral) &&
          nuevoPastor.append(
            "fechaInicioPastoral",
            this.state.fechaInicioPastoral
          );
        this.estadoNoEsNullNiVacio(this.state.idPastor) &&
          nuevoPastor.append("idPastor", this.state.idPastor);
        this.estadoNoEsNullNiVacio(this.state.codigoPastor) &&
          nuevoPastor.append("codigoPastor", this.state.codigoPastor);
        this.estadoNoEsNullNiVacio(this.state.biografia) &&
          nuevoPastor.append("biografia", this.state.biografia);

        this.estadoNoEsNullNiVacio(this.state.apellido_casada) &&
          nuevoPastor.append("apellidoCasada", this.state.apellido_casada);
        this.estadoNoEsNullNiVacio(this.state.fecha_matrimonio) &&
          nuevoPastor.append("fechaMatrimonio", this.state.fecha_matrimonio);
        this.estadoNoEsNullNiVacio(this.state.nombre_conyugue) &&
          nuevoPastor.append("nombreConyugue", this.state.nombre_conyugue);

        HTTP.create(nuevoPastor, "pastor", "Pastores", "pastores").then(
          (result) => {
            this.setState({ loading: false });
            if (result !== false) {
              this.setState({ redirect: true });
            }
          }
        );
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  resetPersona = () => {
    this.setState({
      idPersona: null,
      codigoPersona: null,
      nombres: "",
      apellidos: "",
      telefono: "",
      sexo: "",
      correo: "",
      correo_electronico_actual: "",
      nacionalidad: "",
      fecha_nacimiento: "",
      departamento_nacimiento: "",
      municipio_nacimiento: "",
      canton_nacimiento: "",
      departamento_residencia: "",
      municipio_residencia: "",
      canton_residencia: "",
      tipo_documento: "",
      numero_documento: "",
      estado_civil: "",
      profesion: "",
      direccion: "",
      fecha_matrimonio: "",
      nombre_conyugue: "",
      apellido_casada: "",
      isForUpdate: false,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/recursos_humanos/pastores" />;
    }

    return (
      <Fragment>
        <LayoutPanelFormulario
          titulo="Administración de pastores"
          tools={
            !this.props.match.params.codigo && (
              <Fragment>
                <button
                  type="button"
                  className="btn btn-outline-info ml-2 "
                  onClick={this.toggle}
                >
                  <i className="fa fa-search mr-2"></i>
                  Buscar persona
                </button>
                {this.state.codigoPersona !== null &&
                  this.state.codigoPersona !== "" && (
                    <button
                      type="button"
                      className="btn btn-outline-info ml-2 "
                      onClick={this.resetPersona}
                    >
                      <i className="fa fa-trash mr-2"></i>
                      Quitar persona
                    </button>
                  )}
              </Fragment>
            )
          }
          buttons={this.UIBottomButtonsMainCard()}
          titulo_panel={
            this.props.match.params.codigo
              ? "Actualizar pastor"
              : "Registrar pastor"
          }
        >
          {this.state.loadingData ? (
            this.loadingData()
          ) : (
            <Fragment>
              {/* Parte para datos del pastor arriba*/}
              {this.UIFormDataPastorTop()}
              {/* Componente del formulario persona */}
              {/* Parte segunda del formulario de pastor */}
              {this.UIFormDataPastorBottom()}
              <PersonaFormulario
                change={this.handleInputChange}
                state={this.state}
                save={this.saveData}
                validator={this.validator}
                forUpdate={this.state.isForUpdate}
                notShowMinorsOptions={true}
                params={this.props.match.params}
              />
            </Fragment>
          )}
        </LayoutPanelFormulario>
        {this.UIModalPersonas()}
      </Fragment>
    );
  }
  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={800} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="700" />
        </ContentLoader>
      </React.Fragment>
    );
  }
  UIFormDataPastorTop() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-lg-12">
            <h3 className="box-title">Datos del pastor</h3>
            <hr className="mt-0 mb-4"></hr>
          </div>
          <UIInput
            id="licenciaMinisterial"
            value={this.state.licenciaMinisterial}
            onChange={this.handleInputChange}
            label="Licencia ministerial:"
            placeholder="Licencia ministerial"
            /*  afterInput={[
              this.state.licenciaMinisterialExists && (
                <span className="label label-light-danger">
                  Esta licencia ministerial ya existe
                </span>
              ),
              this.validatorMessage(
                "licencia ministerial",
                "licenciaMinisterial"
              ),
            ]} */
            inputProps={
              this.state.forUpdate || {
                onKeyUp: this.licenciaMinisterialExists,
              }
            }
          ></UIInput>
          <UIAsynSelect
            label="Nivel pastoral: (*)"
            apiPath="nivel_pastor/select"
            id="nivelPastoral"
            value={this.state.nivelPastoral}
            onChange={(e) => {
              this.setState({ nivelPastoral: e });
            }}
            afterSelect={this.validatorMessage(
              "Nivel pastoral",
              "nivelPastoral"
            )}
          ></UIAsynSelect>
          <UIAsynSelect
            label="Nivel académico: (*)"
            apiPath="nivel_academico/select"
            id="nivelAcademico"
            value={this.state.nivelAcademico}
            onChange={(e) => {
              this.setState({ nivelAcademico: e });
            }}
            afterSelect={this.validatorMessage(
              "Nivel académico",
              "nivelAcademico"
            )}
          ></UIAsynSelect>

          <UIInput
            type="date"
            id="fechaInicioPastoral"
            placeholder="dd-mm-yyyy"
            value={this.state.fechaInicioPastoral}
            onChange={this.handleInputChange}
            label="Fecha de inicio pastoral:"
            /* afterInput={this.validatorMessage(
              "Fecha de inicio pastoral",
              "fechaInicioPastoral"
            )} */
          ></UIInput>
        </div>
      </Fragment>
    );
  }
  UIFormDataPastorBottom() {
    return (
      <div className="row">
        <UITextarea
          id="biografia"
          placeholder="Nació en..."
          value={this.state.biografia}
          onChange={this.handleInputChange}
          label="Biografia del pastor:"
        ></UITextarea>
      </div>
    );
  }
  UIBottomButtonsMainCard() {
    return (
      <div className="card-footer text-center">
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-outline-inverse mr-2 "
            onClick={() => {
              this.setState({ redirect: true });
            }}
            disabled={this.state.loading || this.state.loadingData}
          >
            <i className="fa fa-close mr-2"></i>CANCELAR
          </button>

          <button
            type="button"
            className="btn btn-info"
            onClick={this.guardarPastor}
            disabled={this.state.loading || this.state.loadingData}
          >
            <i className="fa fa-save mr-2"></i>
            {this.state.loading === false ? "GUARDAR" : "GUARDANDO..."}
          </button>
        </div>
      </div>
    );
  }
  toggle() {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  }
  rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.preventDefault();
      this.getPersonaData(row.codigo);
      this.setState({
        isForUpdate: true,
      });
      this.toggle();
    },
  };
  UIModalPersonas() {
    return (
      <Modal
        size="lg"
        style={{ maxWidth: "1600px", width: "65%" }}
        isOpen={this.state.isModalOpen}
        returnFocusAfterClose={false}
      >
        <ModalHeader toggle={this.toggle}>Buscador de personas</ModalHeader>
        <ModalBody>
          <TablaFilter
            ref="tabla"
            ruta={"pastores/personas/aptas"}
            rowEvents={this.rowEvents}
            identificador={"modulo_id"}
            columns={[
              {
                dataField: "id",
                text: "ID",
                hidden: true,
                formatter: (cellContent, row) => {
                  return <p className="text-center">{cellContent}</p>;
                },
                headerStyle: () => {
                  return { width: "15%", textAlign: "center" };
                },
              },
              {
                dataField: "codigo",
                text: "CÓDIGO",
                formatter: (cellContent, row) => {
                  return <p className="text-center">{cellContent}</p>;
                },
                headerStyle: () => {
                  return { width: "15%", textAlign: "center" };
                },
              },
              {
                dataField: "nombre",
                text: "Nombre",
                sort: true,
                formatter: (cellContent, row) => {
                  return <p className="ml-4">{cellContent?.toUpperCase()}</p>;
                },
                headerStyle: () => {
                  return { width: "20%", textAlign: "center" };
                },
              },
              {
                dataField: "correo",
                text: "Correo",
                sort: true,
                formatter: (cellContent, row) => {
                  return cellContent ? (
                    <p className="text-center">{cellContent}</p>
                  ) : (
                    <p className="text-center">
                      <span className="label label-light-warning ">
                        No asignado
                      </span>
                    </p>
                  );
                },
                headerStyle: () => {
                  return { width: "20%", textAlign: "center" };
                },
              },
              {
                dataField: "numero_documento",
                text: "Documento",
                sort: true,
                formatter: (cellContent, row) => {
                  return cellContent ? (
                    <p className="text-center">{cellContent}</p>
                  ) : (
                    <p className="text-center">
                      <span className="label label-light-warning ">
                        No asignado
                      </span>
                    </p>
                  );
                },
                headerStyle: () => {
                  return { width: "20%", textAlign: "center" };
                },
              },
            ]}
            titulo_tabla="Escriba la persona que desea buscar"
          />
        </ModalBody>
        <ModalFooter className="text-right"></ModalFooter>
      </Modal>
    );
  }
}
