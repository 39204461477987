import React, { Component } from "react";
import { Fragment } from "react";
import { Redirect } from "react-router-dom";
import LayoutPanelTable from "../../components/layouts/panels/LayoutPanelTable";
import DefaultImage from "../../components/utils/images/default.png";
import HTTP from "../../helpers/HTTP";
import Alerts from "../../services/Alerts";
import Request from "../../services/Request";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import es from "../../helpers/ValidatorTranslate_es";
import SimpleReactValidator from "simple-react-validator";
import { saveAs } from "file-saver";
import Iframe from "react-iframe";
import ContentLoader from "react-content-loader";
class MembresiaPerfil extends Component {
  constructor(props) {
    super(props);
    SimpleReactValidator.addLocale("esp", es);
    this.validator = new SimpleReactValidator({ locale: "esp" });
    this.state = {
      profilePicture: DefaultImage,
      nombre: "",
      correo: "",
      iglesia: "",
      telefono: "",
      fallecido: null,
      desligado: null,
      estado: null,
      fechaNacimiento: "",
      fechaFallecimiento: "",
      fechaDefuncion: "", //fecha para guardar en miembros
      edad: 0,
      genero: "",
      nombreIglesia: "",

      isModalOpen: false,
      archivo_informe: null,
      dataLoaded: false,
    };
  }

  componentDidMount() {
    this.getMiembroData();
  }
  getImagen() {
    Request.GET_IMAGE("miembro/imagen", this.props.match.params.codigo).then(
      (result) => {
        this.setState({ dataLoaded: true });
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
              }),
              url = window.URL.createObjectURL(blob);

            this.setState({ profilePicture: url });
          }
        }
      }
    );
  }
  getMiembroData() {
    this.setState({ dataLoaded: false });
    HTTP.findById(this.props.match.params.codigo, "miembro").then(
      async (result) => {
        if (result !== false) {
          // if (result.estado !== false) {

          const nacimiento = new Date(result.fecha_nacimiento);
          const fechaActual =
            result.estadoMiembro === 4
              ? new Date(result.fechaFallecimiento)
              : new Date();

          this.setState({
            nombre: result.nombres + " " + result.apellidos,
            correo: result.correo,
            iglesia: result.iglesia,
            telefono: result.telefono,
            direccion: result.direccion,
            estado: result.estadoMiembro,
            fechaNacimiento:
              result.fecha_nacimiento === null
                ? null
                : nacimiento.toLocaleString(),
            edad:
              result.fecha_nacimiento === null
                ? null
                : fechaActual.getFullYear() - nacimiento.getFullYear(),
            genero: result.sexo,
            nombreIglesia: result.nombreIglesia,
            fechaFallecimiento:
              result.estadoMiembro === 4
                ? fechaActual.toLocaleDateString()
                : null,
          });
          this.getImagen();
        } else {
        }
      }
    );
  }
  establecerMiembroComoFallecido() {
    if (this.validator.allValid()) {
      Alerts.QuestionYesNo(
        "¿Está seguro que desea establecer este miembro como fallecido?",
        "Administración de miembros",
        "question"
      ).then((resp) => {
        if (resp) {
          Alerts.loading_reload(true);
          HTTP.update(
            {
              codigoMiembro: this.props.match.params.codigo,
              estado: 4,
              fechaFallecimiento: this.state.fechaDefuncion,
            },
            "miembro",
            "miembros",
            "miembro/estado"
          )
            .then((result) => {
              if (result === true) Alerts.loading_reload(false);
              this.setState({ estado: 4 });
            })
            .finally(() => {
              Alerts.loading_reload(false);
              this.setState({ fechaFallecimiento: this.state.fechaDefuncion });
              this.toggle();
            });
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  desligarMiembro() {
    Alerts.QuestionYesNo(
      "¿Está seguro que desea establecer este miembro como desligado?",
      "Administración de miembros",
      "question"
    ).then((resp) => {
      if (resp) {
        Alerts.loading_reload(true);
        HTTP.update(
          { codigoMiembro: this.props.match.params.codigo, estado: 3 },
          "miembro",
          "miembros",
          "miembro/estado"
        )
          .then((result) => {
            if (result === true) Alerts.loading_reload(false);
            this.setState({ estado: 3 });
          })
          .finally(() => Alerts.loading_reload(false));
      }
    });
  }
  activarMiembro() {
    Alerts.QuestionYesNo(
      "¿Está seguro que desea establecer este miembro como desligado?",
      "Administración de miembros",
      "question"
    ).then((resp) => {
      if (resp) {
        Alerts.loading_reload(true);
        HTTP.update(
          { codigoMiembro: this.props.match.params.codigo, estado: 1 },
          "miembro",
          "miembros",
          "miembro/estado"
        )
          .then((result) => {
            if (result === true) Alerts.loading_reload(false);
            this.setState({ estado: 1 });
          })
          .finally(() => Alerts.loading_reload(false));
      }
    });
  }
  desactivarMiembro() {
    Alerts.QuestionYesNo(
      "¿Está seguro que desea establecer este miembro como desligado?",
      "Administración de miembros",
      "question"
    ).then((resp) => {
      if (resp) {
        Alerts.loading_reload(true);
        HTTP.update(
          { codigoMiembro: this.props.match.params.codigo, estado: 2 },
          "miembro",
          "miembros",
          "miembro/estado"
        )
          .then((result) => {
            if (result === true) Alerts.loading_reload(false);
            this.setState({ estado: 2 });
          })
          .finally(() => Alerts.loading_reload(false));
      }
    });
  }

  descargarInforme(codigo_miembro) {
    Alerts.loading_reload(true);
    Request.GET_PDF_FROM_EXTERNAL(`miembro/${codigo_miembro}`, "").then(
      async (result) => {
        Alerts.loading_reload(false);
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);

            await this.setState({
              archivo_informe: url,
            });
            saveAs(
              this.state.archivo_informe,
              `MIEMBRO_${codigo_miembro.toUpperCase()}.pdf`
            );
          }
        }
      }
    );
  }
  imprimirInforme(codigo_miembro) {
    Alerts.loading_reload(true);
    Request.GET_PDF_FROM_EXTERNAL(`miembro/${codigo_miembro}`, "").then(
      async (result) => {
        Alerts.loading_reload(false);
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);

            await this.setState({
              archivo_informe: url,
            });
            window.setTimeout(() => {
              document.querySelector("#archivo_informe").contentWindow.print();
            }, 500);
          }
        }
      }
    );
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/membresia" />;
    }
    return (
      <Fragment>
        <LayoutPanelTable
          titulo={"Perfil de miembro de " + this.state.nombreIglesia}
          titulo_panel="Información del miembro"
          buttons={
            <button
              type="button"
              className="btn btn-outline-inverse mr-2 "
              onClick={() => {
                this.setState({ redirect: true });
              }}
            >
              <i className="fa fa-arrow-left mr-2"></i>Salir
            </button>
          }
        >
          {/* <!-- Row --> */}
          <div className="row">
            {/* <!-- Column --> */}
            <div
              className={
                this.state.estado === 4
                  ? "col-lg-12 col-xlg-12 col-md-12"
                  : "col-lg-4 col-xlg-3 col-md-5"
              }
            >
              {this.state.dataLoaded === true ? (
                <div className="card">
                  <div className="card-body">
                    <center className="m-t-30">
                      {" "}
                      <img
                        src={this.state.profilePicture}
                        className="img-circle"
                        width="150"
                        alt="Foto de perfil"
                      />
                      <h4 className="card-title m-t-10">{this.state.nombre}</h4>
                      {this.state.edad !== null ? (
                        <h5>Edad: {this.state.edad} años</h5>
                      ) : (
                        ""
                      )}
                      {this.state.estado === 3 && (
                        <h5 className="card-subtitle">
                          <span class="badge badge-warning">
                            MIEMBRO DESLIGADO
                          </span>
                        </h5>
                      )}
                      {this.state.estado === 4 && (
                        <h5 className="card-subtitle">
                          <span class="badge badge-danger">
                            MIEMBRO FALLECIDO
                          </span>
                        </h5>
                      )}
                      {this.state.estado === 2 && (
                        <h5 className="card-subtitle">
                          <span class="badge badge-danger">
                            MIEMBRO INACTIVO
                          </span>
                        </h5>
                      )}
                      {this.state.estado === 1 && (
                        <h5 className="card-subtitle">
                          <span class="badge badge-success">
                            MIEMBRO ACTIVO
                          </span>
                        </h5>
                      )}
                      <div
                        class="btn-group-vertical"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          type="button"
                          className="btn  btn-outline-info mr-2"
                          onClick={this.descargarInforme.bind(
                            this,
                            this.props.match.params.codigo
                          )}
                        >
                          <i className="fa fa-download mr-2"></i>Descagar
                          Informe
                        </button>
                        <button
                          type="button"
                          className="btn  btn-outline-info mr-2"
                          onClick={this.imprimirInforme.bind(
                            this,
                            this.props.match.params.codigo
                          )}
                        >
                          <i className="fa fa-print mr-2"></i>Imprimir Informe
                        </button>
                      </div>
                    </center>
                  </div>
                  <div>
                    <hr />
                  </div>
                  <div className="card-body">
                    {this.state.fechaNacimiento !== null &&
                      this.state.fechaNacimiento !== "" && (
                        <small className="text-muted p-t-30 db">
                          Fecha de nacimiento
                        </small>
                      )}
                    {this.state.fechaNacimiento !== null &&
                      this.state.fechaNacimiento !== "" && (
                        <h6>{this.state.fechaNacimiento}</h6>
                      )}
                    {this.state.fechaFallecimiento !== null &&
                      this.state.fechaFallecimiento !== "" && (
                        <small className="text-muted p-t-30 db">
                          Fecha de fallecimiento
                        </small>
                      )}
                    {this.state.fechaFallecimiento !== null &&
                      this.state.fechaFallecimiento !== "" && (
                        <h6>{this.state.fechaFallecimiento}</h6>
                      )}

                    <small className="text-muted p-t-30 db">
                      Correo electrónico
                    </small>
                    <h6>
                      {this.state.correo === null ||
                      this.state.correo === "" ? (
                        <span className="label label-light-warning ">
                          No asignado
                        </span>
                      ) : (
                        this.state.correo
                      )}
                    </h6>
                    <small className="text-muted p-t-30 db">Telefono</small>
                    <h6>
                      {this.state.telefono === null ||
                      this.state.telefono === "" ? (
                        <span className="label label-light-warning ">
                          No asignado
                        </span>
                      ) : (
                        this.state.telefono
                      )}
                    </h6>
                    <small className="text-muted p-t-30 db">Dirección</small>
                    <h6>
                      {this.state.direccion === null ||
                      this.state.direccion === "" ? (
                        <span className="label label-light-warning ">
                          No asignado
                        </span>
                      ) : (
                        this.state.direccion
                      )}
                    </h6>
                    <small className="text-muted p-t-30 db">Género</small>
                    <h6>
                      {this.state.genero === null ||
                      this.state.genero === "" ? (
                        <span className="label label-light-warning ">
                          No asignado
                        </span>
                      ) : (
                        this.state.genero
                      )}
                    </h6>
                  </div>
                </div>
              ) : (
                this.loadingDataSide()
              )}
            </div>
            {/*   <!-- Column --> */}
            {/*<!-- Column --> */}

            <div className="col-lg-8 col-xlg-9 col-md-7">
              <div className="row">
                {this.state.estado !== 4 && this.UIAcciones()}
              </div>
            </div>
            {/* <!-- Column --> */}
          </div>
          {/* <!-- Row --> */}
        </LayoutPanelTable>
        {this.UIModal()}
        {this.state.archivo_informe !== null ? (
          <Iframe
            id="archivo_informe"
            url={`${this.state.archivo_informe}#toolbar=0&navpanes=0&statusbar=0`}
            width="90%"
            height="550px"
            display="none"
            position="relative"
          />
        ) : null}
      </Fragment>
    );
  }

  UICardEstablecerFallecido() {
    return (
      <Fragment>
        <div className="col-md-12">
          <div className="card card-outline-info">
            <div className="card-header">
              <h4 className="m-b-0 text-white">
                Establecer miembro como fallecido
              </h4>
            </div>
            <div className="card-body">
              <p className="card-text">
                Marcar este miembro como fallecido. Este miembro será catalogado
                como fallecido y no estará disponible para otras acciones
              </p>
              <button
                onClick={() => {
                  this.toggle();
                }}
                className="btn btn-danger"
              >
                Establecer como fallecido
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  UICardDesligar() {
    return (
      <Fragment>
        <div className="col-md-12">
          <div className="card card-outline-info">
            <div className="card-header">
              <h4 className="m-b-0 text-white">
                Desligar miembro de la iglesia
              </h4>
            </div>
            <div className="card-body">
              <p className="card-text">Desligar miembro de la iglesia actual</p>
              <button
                onClick={() => {
                  this.desligarMiembro();
                }}
                className="btn btn-danger"
              >
                Desligar miembro
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  UICardActivar() {
    return (
      <Fragment>
        <div className="col-md-12">
          <div className="card card-outline-info">
            <div className="card-header">
              <h4 className="m-b-0 text-white">Establecer como activo</h4>
            </div>
            <div className="card-body">
              <p className="card-text">
                Establecer como miembro activo a este miembro de la iglesia
              </p>
              <button
                onClick={() => {
                  this.activarMiembro();
                }}
                className="btn btn-success"
              >
                Marcar como activo
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  UICardDesactivar() {
    return (
      <Fragment>
        <div className="col-md-12">
          <div className="card card-outline-info">
            <div className="card-header">
              <h4 className="m-b-0 text-white">Establecer como inactivo</h4>
            </div>
            <div className="card-body">
              <p className="card-text">
                Marcar como miembro inactivo a este miembro de la iglesia
              </p>
              <button
                onClick={() => {
                  this.desactivarMiembro();
                }}
                className="btn btn-danger"
              >
                Marcar como inactivo
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  UIAcciones() {
    if (this.state.dataLoaded === false) {
      return (
        <Fragment>
          {this.loadingData()}
          {this.loadingData()}
          {this.loadingData()}
        </Fragment>
      );
    }
    return (
      <Fragment>
        {(this.state.estado === 2 || this.state.estado === 3) &&
          this.UICardActivar()}
        {this.state.estado === 1 && this.UICardDesactivar()}
        {(this.state.estado === 1 || this.state.estado === 2) &&
          this.UICardDesligar()}
        {this.UICardEstablecerFallecido()}
      </Fragment>
    );
  }
  toggle() {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  }
  UIModal() {
    return (
      <Modal
        size="sm"
        style={{ maxWidth: "1600px", width: "65%" }}
        isOpen={this.state.isModalOpen}
        centered={true}
      >
        <ModalHeader
          toggle={() => {
            this.toggle();
          }}
        >
          Establecer como fallecido
        </ModalHeader>
        <ModalBody>
          <div class="col-md-6">
            <label>Fecha de fallecimiento</label>
            <input
              onChange={(e) => {
                this.setState({ fechaDefuncion: e.target.value });
              }}
              type="date"
              class="form-control"
              placeholder="2017-06-04"
              value={this.state.fechaDefuncion}
            />
            {this.validator.message(
              "fecha de fallecimiento",
              this.state.fechaDefuncion,
              "required"
            ) && (
              <span className="label label-light-danger">
                {this.validator.message(
                  "fecha de fallecimiento",
                  this.state.fechaDefuncion,
                  "required"
                )}
              </span>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="text-right">
          <button
            onClick={() => {
              this.establecerMiembroComoFallecido();
            }}
            className="btn btn-danger"
          >
            Establecer como fallecido
          </button>{" "}
        </ModalFooter>
      </Modal>
    );
  }
  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={200} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="700" />
        </ContentLoader>
      </React.Fragment>
    );
  }
  loadingDataSide() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={600} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="700" />
        </ContentLoader>
      </React.Fragment>
    );
  }
}

export default MembresiaPerfil;
