import React, { Component } from "react";
import LayoutPanelFormulario from "../../components/layouts/panels/LayoutPanelFormulario";
import HTTP from "../../helpers/HTTP";
import Encrypt from "../../services/Encrypt";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import es from "../../helpers/ValidatorTranslate_es";
import Alerts from "../../services/Alerts";
export default class SoporteTecnico extends Component {
  constructor(props) {
    super(props);
    this.state = this.inicial_state;
    this.state.usuario = Encrypt.getSession("codigo_persona");
    this.init_validator();
  }

  inicial_state = {
    disabled_select_iglesia: true,
    iglesias: [],
    iglesia: "",
    loading: false,
  };

  componentDidMount() {
    this.setState({
      iglesias: this.getIglesias(),
    });
  }

  init_validator() {
    SimpleReactValidator.addLocale("esp", es);
    this.validator = new SimpleReactValidator({ locale: "esp" });
  }

  getIglesias() {
    let data = [];
    HTTP.findById(this.state.usuario, "usuarios/iglesias_asignadas").then(
      (result) => {
        this.setState({ disabled_select_iglesia: false });
        if (result !== false) {
          result.forEach((element) => {
            data.push({
              label: element.iglesia,
              value: element.codigo,
            });
          });
        }
      }
    );
    return data;
  }

  handleInputChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    this.setState({ [idComponente]: valorComponente });
  };

  enviar() {
    if (this.validator.allValid()) {
      Alerts.QuestionYesNo(
        "¿Desea enviar mensaje a soporte técnico?",
        "¡El mensaje será recibido por nuestro equipo y se comunicarán en breve con usted, para dar una pronta solución!",
        "question"
      ).then((resp) => {
        if (resp) {
          const data = {
            codigo_iglesia: this.state.iglesia?.value,
            nombre_iglesia: this.state.iglesia?.label,
            codigo_usuario: this.state.usuario,
            problema: this.state.problema,
          };

          Alerts.loading_reload_text(true, "Enviando Mensaje");
          HTTP.postRequest(data, "generales/send-soporte").then((result) => {
            Alerts.loading_reload_text(false, "Enviando Mensaje");

            if (result !== false) {
              this.setState({
                problema: "",
                iglesia: "",
              });
              this.validator.hideMessages();
              this.forceUpdate();
              Alerts.alertEmpty(
                "¡Mensaje enviado correctamente!",
                "En seguida uno de nuestros agentes tomará su caso para dar una pronta solución al problema presentado.",
                "success"
              );
            }
          });
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  render() {
    return (
      <div>
        <LayoutPanelFormulario
          titulo="Soporte Técnico"
          titulo_panel="Reportar un Problema"
          buttons={
            <div className="card-footer text-center">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-lg btn-info"
                  disabled={this.state.loading}
                  onClick={this.enviar.bind(this)}
                >
                  <i className="fa fa-save mr-2"></i>
                  {this.state.loading === false ? "ENVIAR" : "ENVIANDO..."}
                </button>
              </div>
            </div>
          }
        >
          <div className="row">
            <div className="col-lg-6 form-group">
              <label htmlFor="">Iglesia en la que presenta el problema:</label>
              <Select
                id="iglesia"
                name="iglesia"
                placeholder="Seleccione una opción"
                value={this.state.iglesia}
                isClearable={true}
                options={this.state.iglesias}
                isDisabled={this.state.disabled_select_iglesia}
                onChange={(e) => {
                  this.setState({ iglesia: e });
                }}
              />

              {this.validator.message(
                "iglesia",
                this.state.iglesia,
                "required"
              ) && (
                <span className="label label-light-danger">
                  {this.validator.message(
                    "iglesia",
                    this.state.iglesia,
                    "required"
                  )}
                </span>
              )}
            </div>

            <div className="col-lg-12 form-group">
              <label htmlFor="">
                Descripción del problema que está precentando:
              </label>

              <textarea
                type="text"
                className="form-control"
                placeholder="Escriba el problema que está presentado o solicitar ayuda para ser contactado por el equipo de soporte técnico"
                id="problema"
                rows="10"
                value={this.state.problema}
                onChange={this.handleInputChange}
              />
              {this.validator.message(
                "descripción del problema",
                this.state.problema,
                "required"
              ) && (
                <span className="label label-light-danger">
                  {this.validator.message(
                    "descripción del problema",
                    this.state.problema,
                    "required"
                  )}
                </span>
              )}
            </div>
          </div>
        </LayoutPanelFormulario>
      </div>
    );
  }
}
