import React, { Component } from "react";
import LayoutPanelTable from "../../../../../components/layouts/panels/LayoutPanelTable";
import HTTP from "../../../../../helpers/HTTP";
import Encrypt from "../../../../../services/Encrypt";
import { Tabs, Tab } from "react-bootstrap";
import TablaSearch from "../../../../../components/tablas/TablaSearch";
import { Redirect } from "react-router";
import Alerts from "../../../../../services/Alerts";
import Iframe from "react-iframe";
import { saveAs } from "file-saver";
import { isBrowser } from "react-device-detect";
import Request from "../../../../../services/Request";
import ContentLoader from "react-content-loader";

export default class HistoricoRecepcionInformesGestionesDetalleIglesias extends Component {
  constructor(props) {
    super(props);
    this.state = this.inicial_state;
    this.state.usuario = Encrypt.getSession("codigo_persona");
  }

  inicial_state = {
    usuario: "",
    tab_active: "iglesias_reportadas",
    iglesias_reportadas: [],
    iglesias_pendientes: [],
    gestion: null,
    informe: null,
    cargadas: false,
    redirect: false,
    archivo_informe: null,
    datos_gestion_cargados: false,
    datos_informe_cargados: false,
  };
  componentDidMount() {
    this.getGestiones();
    this.getInforme();
    this.getIglesiasReportadas();
    this.getIglesiasPendientes();
  }

  async handleChangeTab(key) {
    await this.setState({ tab_active: key });

    if (key === "iglesias_reportadas") {
      this.getIglesiasReportadas();
    } else if (key === "iglesias_pendientes") {
      this.getIglesiasPendientes();
    }
  }
  getGestiones() {
    HTTP.findById(
      this.props.match.params.gestion,
      "gestiones_historico/codigo"
    ).then((result) => {
      if (result !== false) {
        this.setState({
          gestion: result,
          datos_gestion_cargados: true,
        });
      } else {
        this.setState({ redirect: true });
      }
    });
  }

  getIglesiasReportadas() {
    HTTP.findById(
      this.props.match.params.id_informe,
      `gestiones_historico/informes/iglesias/enviados/${this.props.match.params.gestion}`
    ).then((result) => {
      if (result !== false) {
        this.setState({
          iglesias_reportadas: result,
        });
      } else {
        this.setState({ redirect: true });
      }
    });
  }

  getIglesiasPendientes() {
    HTTP.findById(
      this.props.match.params.id_informe,
      `gestiones_historico/informes/iglesias/no_enviados/${this.props.match.params.gestion}`
    ).then((result) => {
      if (result !== false) {
        this.setState({
          iglesias_pendientes: result,
        });
      } else {
        this.setState({ redirect: true });
      }
    });
  }
  getInforme() {
    HTTP.findById(this.props.match.params.id_informe, "maestro_informe").then(
      (result) => {
        if (result !== false) {
          this.setState({
            informe: result,
            datos_informe_cargados: true,
          });
        } else {
          this.setState({ redirect: true });
        }
      }
    );
  }
  columnsIglesiasReportadas = [
    {
      informe_ide: "id",
      text: "id",
      hidden: true,
    },
    {
      informe_ide: "informe_ide",
      text: "id",
      hidden: true,
    },
    {
      dataField: "codigo",
      text: "Código",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      informe_ide: "informe_maestro",
      text: "id",
      hidden: true,
    },
    {
      dataField: "identificador",
      text: "id",
      hidden: true,
    },
    {
      dataField: "nombre",
      text: "Nombre Iglesia",

      formatter: (cellContent, row) => {
        return <p className="ml-4">{cellContent.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "tipo_iglesia",
      text: "Tipo Iglesia",

      formatter: (cellContent, row) => {
        return <p>{cellContent.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "8%", textAlign: "center" };
      },
    },
    {
      dataField: "telefono",
      text: "Teléfono",

      formatter: (cellContent, row) => {
        if (cellContent !== "" && cellContent !== null) {
          return <p className="text-center">{cellContent}</p>;
        } else {
          return <p className="text-center">N/A</p>;
        }
      },
      headerStyle: () => {
        return { width: "8%", textAlign: "center" };
      },
    },
    {
      dataField: "fecha_envio",
      text: "Fecha Envío",

      formatter: (cellContent, row) => {
        return <p>{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "8%", textAlign: "center" };
      },
    },
    {
      dataField: "usuario_envio",
      text: "Usuario Envío",

      formatter: (cellContent, row) => {
        return <p>{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "Opciones",
      text: "Opciones",

      formatter: (cellContent, row) => {
        return (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-outline-info mr-2"
              onClick={this.descargarPreviaInforme.bind(
                this,
                row.informe_ide,
                row.codigo,
                row.nombre
              )}
            >
              <i className="fa fa-download mr-2"></i>Descargar
            </button>
            {isBrowser === true ? (
              <button
                type="button"
                className="btn btn-outline-info mr-2"
                onClick={this.imprimirPreviaInforme.bind(
                  this,
                  row.informe_ide,
                  row.codigo
                )}
              >
                <i className="fa fa-print mr-2"></i>Imprimir
              </button>
            ) : null}
          </div>
        );
      },
      headerStyle: () => {
        return { width: "25%", textAlign: "center" };
      },
    },
  ];

  columnsIglesiasPendientes = [
    {
      dataField: "codigo",
      text: "Código",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },

    {
      dataField: "nombre",
      text: "Nombre Iglesia",

      formatter: (cellContent, row) => {
        return <p className="ml-4">{cellContent.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "tipo_iglesia",
      text: "Tipo Iglesia",

      formatter: (cellContent, row) => {
        return <p>{cellContent.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "8%", textAlign: "center" };
      },
    },
    {
      dataField: "telefono",
      text: "Teléfono",

      formatter: (cellContent, row) => {
        if (cellContent !== "" && cellContent !== null) {
          return <p className="text-center">{cellContent}</p>;
        } else {
          return <p className="text-center">N/A</p>;
        }
      },
      headerStyle: () => {
        return { width: "8%", textAlign: "center" };
      },
    },

    // {
    //   dataField: "Opciones",
    //   text: "Opciones",

    //   formatter: (cellContent, row) => {
    //     return (
    //       <div className="text-center">
    //         <button type="button" className="btn btn-outline-info mr-2">
    //           <i className="fa fa-send mr-2"></i>Enviar Recordatorio
    //         </button>
    //       </div>
    //     );
    //   },
    //   headerStyle: () => {
    //     return { width: "25%", textAlign: "center" };
    //   },
    // },
  ];

  descargarPreviaInforme(ide_informe, iglesia_codigo, iglesia_name) {
    Alerts.loading_reload(true);

    const data = {
      codigoGestion: this.props.match.params.gestion,
      codigoIglesia: iglesia_codigo,
      codigoInforme: ide_informe,
    };
    Request.POST_GET_PDF(`gestion_historico/pdf/informes`, data).then(
      async (result) => {
        Alerts.loading_reload(false);
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);

            await this.setState({
              archivo_informe: url,
              nombre_informe_descarga: `${this.state.informe.nombre
                .toUpperCase()
                .replaceAll(" ", "_")}-${iglesia_name
                .toUpperCase()
                .replaceAll(" ", "_")}-${this.props.match.params.gestion}`,
            });
            saveAs(
              this.state.archivo_informe,
              `${this.state.nombre_informe_descarga}.pdf`
            );
          }
        }
      }
    );
  }

  imprimirPreviaInforme(ide_informe, iglesia_codigo) {
    Alerts.loading_reload(true);

    const data = {
      codigoGestion: this.props.match.params.gestion,
      codigoIglesia: iglesia_codigo,
      codigoInforme: ide_informe,
    };
    Request.POST_GET_PDF(`gestion_historico/pdf/informes`, data).then(
      async (result) => {
        Alerts.loading_reload(false);
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);

            await this.setState({
              archivo_informe: url,
            });
            window.setTimeout(() => {
              document.querySelector("#archivo_informe").contentWindow.print();
            }, 500);
          }
        }
      }
    );
  }
  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={200} width={"100%"}>
          <rect x="0" y="42" rx="15" ry="5" width="100%" height="200" />
        </ContentLoader>
      </React.Fragment>
    );
  }
  imprimirInforme() {
    if (this.state.iglesias_reportadas.length > 0) {
      Alerts.loading_reload(true);
      Request.GET_PDF(
        `gestion_historico/pdf/informes/unido/${this.props.match.params.gestion}/${this.props.match.params.id_informe}`
      ).then(async (result) => {
        Alerts.loading_reload(false);
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);

            await this.setState({
              archivo_informe: url,
            });
            window.setTimeout(() => {
              document.querySelector("#archivo_informe").contentWindow.print();
            }, 500);
          }
        }
      });
    }
  }
  render() {
    if (this.state.redirect === true) {
      return (
        <Redirect
          to={`/historico/recepcion_informes/iglesia/detalle/${this.props.match.params.gestion}`}
        />
      );
    }
    return (
      <div>
        <LayoutPanelTable
          titulo="Detalle de histórico de gestión de informe recibida"
          titulo_panel="Panel de Recepción de Informe"
          buttons={
            <button
              type="button"
              className="btn btn-secondary btn-lg mr-2"
              onClick={() => {
                this.setState({ redirect: true });
              }}
            >
              <i className="fa fa-arrow-left mr-2"></i>Salir
            </button>
          }
        >
          <div className="row">
            <div className="col-lg-12">
              {this.state.datos_gestion_cargados === true &&
              this.state.datos_informe_cargados === true ? (
                <div className="table-responsive">
                  <table className="table table-bordered table-bordered-table color-bordered-table muted-bordered-table">
                    <thead>
                      <tr>
                        <th className="text-center">Descripción Gestión</th>

                        <th className="text-center">Fecha Publicación</th>
                        <th className="text-center">Informe a Recibir</th>
                        <th className="text-center">Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {this.state.gestion !== null
                            ? this.state.gestion.descripcion
                            : "N/A"}
                        </td>
                        <td>
                          {this.state.gestion !== null
                            ? this.state.gestion.fecha_publicacion
                            : "N/A"}
                        </td>

                        <td>
                          {this.state.informe !== null
                            ? this.state.informe.nombre
                            : "N/A"}
                        </td>
                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-outline-info mr-2"
                            disabled={
                              this.state.iglesias_reportadas.length === 0
                            }
                            onClick={this.imprimirInforme.bind(this)}
                          >
                            <i className="fa fa-print mr-2"></i>Imprimir Informe
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                this.loadingData()
              )}
            </div>

            <div className="col-lg-12">
              {this.state.datos_gestion_cargados === true &&
              this.state.datos_informe_cargados === true ? (
                <Tabs
                  activeKey={this.state.tab_active}
                  onSelect={this.handleChangeTab.bind(this)}
                  id="tabs"
                >
                  <Tab
                    eventKey={"iglesias_reportadas"}
                    title={`IGLESIAS REPORTADAS: ${this.state.iglesias_reportadas.length}`}
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <h4 className="card-title m-t-10 mb-4">
                          Iglesias que han enviado el informe
                        </h4>
                        <TablaSearch
                          data={this.state.iglesias_reportadas}
                          identificador={"id"}
                          columns={this.columnsIglesiasReportadas}
                        />
                      </div>
                    </div>
                  </Tab>

                  <Tab
                    eventKey={"iglesias_pendientes"}
                    title={`IGLESIAS PENDIENTES: ${this.state.iglesias_pendientes.length}`}
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <h4 className="card-title m-t-10 mb-4">
                          Iglesias pendientes de enviar el informe
                        </h4>
                        <TablaSearch
                          data={this.state.iglesias_pendientes}
                          identificador={"codigo"}
                          columns={this.columnsIglesiasPendientes}
                        />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              ) : (
                this.loadingData()
              )}
            </div>
          </div>
        </LayoutPanelTable>

        {this.state.archivo_informe !== null ? (
          <Iframe
            id="archivo_informe"
            url={`${this.state.archivo_informe}#toolbar=0&navpanes=0&statusbar=0`}
            width="90%"
            height="550px"
            display="none"
            position="relative"
          />
        ) : null}
      </div>
    );
  }
}
