import React, { Component } from "react";
//import LayoutPanelTable from "../../../components/layouts/panels/LayoutPanelTable";
//import { Tabs, Tab } from "react-bootstrap";
//import LayoutPanelEmpty from "../../../components/layouts/panels/LayoutPanelEmpty";
import Request from "../../../services/Request";
import HTTP from "../../../helpers/HTTP";
import Alerts from "../../../services/Alerts";
import ContentLoader from "react-content-loader";
import LayoutPanelFormulario from "../../../components/layouts/panels/LayoutPanelFormulario";
import SimpleReactValidator from "simple-react-validator";
import es from "../../../helpers/ValidatorTranslate_es";
import Encrypt from "../../../services/Encrypt";
import { Redirect } from "react-router-dom";

export default class NombramientoPastorForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.inicial_state;
    this.init_validator();
  }

  inicial_state = {
    tab_active: "nombramientos_pastores",
    gestiones_nopublicadas: [],
    gestiones_publicadas: [],
    gestiones_publicadas_cargadas: false,
    gestiones_nopublicadas_cargadas: false,
    descripcion: "",
    fecha_inicio: "",
    fecha_fin: "",
    redirect: false,
  };
  async handleChangeTab(key) {
    await this.setState({ tab_active: key });

    if (key === "nombramientos_pastores") {
      this.getGestionesPublicadas();
    } else if (key === "supervisores_region") {
      this.getGestionesNoPublicadas();
    }
  }
  componentDidMount() {
    // this.getGestionesNoPublicadas();
    // this.getGestionesPublicadas();
    this.getNombramiento();
  }
  getNombramiento() {
    if (this.props.match.params.id) {
      HTTP.findById(
        this.props.match.params.id,
        "nombramientos_pastorales/header"
      ).then((result) => {
        if (result !== false) {
          this.setState(result);
        } else {
          this.setState({ redirect: true });
        }
      });
    }
  }
  actualizar(id) {
    this.props.history.push(
      `/informes_mensuales/gestiones_entrega/update/${id}`
    );
  }
  asignacion(id) {
    this.props.history.push(
      `/informes_mensuales/gestiones_entrega/asignacion_informes/${id}`
    );
  }

  asignados(id) {
    this.props.history.push(
      `/informes_mensuales/gestiones_entrega/asignados/${id}`
    );
  }
  getGestionesNoPublicadas() {
    this.setState({
      gestiones_nopublicadas_cargadas: false,
    });

    HTTP.findAll("gestiones/inactivas").then((result) => {
      if (result !== false) {
        this.setState({
          gestiones_nopublicadas: result,
          gestiones_nopublicadas_cargadas: true,
        });
      }
    });
  }
  getGestionesPublicadas() {
    this.setState({
      gestiones_publicadas_cargadas: false,
    });
    HTTP.findAll("gestiones/activas").then((result) => {
      if (result !== false) {
        this.setState({
          gestiones_publicadas: result,
          gestiones_publicadas_cargadas: true,
        });
      }
    });
  }
  delete(codigo) {
    const data = {
      codigoGestion: codigo,
    };
    HTTP.delete_disable(
      data,
      "gestión de informe",
      "gestiones de informe",
      "gestion"
    ).then((result) => {
      if (result !== false) {
        this.getGestionesNoPublicadas();
      }
    });
  }
  Publicar(codigo) {
    Alerts.QuestionYesNo(
      "¿Está seguro que desea publicar la gestión?",
      "Administración de gestiones de informe",
      "question"
    ).then((resp) => {
      if (resp) {
        const data = {
          codigoGestion: codigo,
        };
        Alerts.loading_reload(true);
        Request.PUT("gestion/publicar", data).then((result) => {
          Alerts.loading_reload(false);
          if (result !== false) {
            if (result.status === 200) {
              Alerts.alertEmpty(
                "¡Gestión publicada con éxito",
                "Administración de gestiones de informe",
                "success"
              );
              this.getGestionesNoPublicadas();
              this.getGestionesPublicadas();
              this.setState({
                tab_active: "nombramientos_pastores",
              });
            } else {
              Alerts.alertEmpty(
                "¡No se pudo publicar",
                "Administración de gestiones de informe",
                "error"
              );
            }
          } else {
            Alerts.alertEmpty(
              "¡No se pudo publicar",
              "Administración de gestiones de informe",
              "error"
            );
          }
        });
      }
    });
  }
  finalizar(codigo) {
    Alerts.QuestionYesNo(
      "¿Está seguro que desea finalizar la gestión?",
      "Administración de gestiones de informe",
      "question"
    ).then((resp) => {
      if (resp) {
        const data = {
          codigoGestion: codigo,
        };
        Alerts.loading_reload(true);
        Request.PUT("gestion/cerrar", data).then((result) => {
          Alerts.loading_reload(false);
          if (result !== false) {
            if (result.status === 200) {
              Alerts.alertEmpty(
                "¡Gestión finalizada con éxito",
                "Administración de gestiones de informe",
                "success"
              );

              this.getGestionesPublicadas();
              this.setState({
                tab_active: "nombramientos_pastores",
              });
            } else {
              Alerts.alertEmpty(
                "¡No se pudo finalizar",
                "Administración de gestiones de informe",
                "error"
              );
            }
          } else {
            Alerts.alertEmpty(
              "¡No se pudo finalizar",
              "Administración de gestiones de informe",
              "error"
            );
          }
        });
      }
    });
  }
  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={400} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="400" />
        </ContentLoader>
      </React.Fragment>
    );
  }

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      Alerts.loading_reload(true);
      if (this.props.match.params.id) {
        const data = {
          code: this.props.match.params.id,
          descripcion: this.state.descripcion,
          fecha_inicio: this.state.fecha_inicio,
          fecha_fin: this.state.fecha_fin,
        };
        HTTP.update(
          data,
          "nombramiento pastoral",
          "Nombramientos Pastorales",
          "nombramientos_pastorales/header"
        ).then((result) => {
          if (result !== false) {
            this.setState({ redirect: true });
          }
        });
      } else {
        const data = {
          descripcion: this.state.descripcion,
          fecha_inicio: this.state.fecha_inicio,
          fecha_fin: this.state.fecha_fin,
        };
        HTTP.create(
          data,
          "nombramiento pastoral",
          "Nombramientos Pastorales",
          "nombramientos_pastorales/header"
        ).then((result) => {
          if (result !== false) {
            Encrypt.setSession(
              "nombramiento_pastoral_actual",
              result.data.codigo
            );
            this.props.history.push(
              `/nombramientos/pastorales/gestion/${result.data.codigo}`
            );
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInputChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    this.setState({ [idComponente]: valorComponente });
  };
  init_validator() {
    SimpleReactValidator.addLocale("esp", es);
    this.validator = new SimpleReactValidator({ locale: "esp" });
  }

  hide_alert_validator() {
    this.validator.hideMessages();
    this.forceUpdate();
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/nombramientos/pastorales"} />;
    }
    return (
      <React.Fragment>
        <LayoutPanelFormulario
          titulo="Configuración de Nombramientos de Pastores y Ministros"
          titulo_panel="Datos de Nombramientos"
          submit={this.onSubmit}
          buttons={
            <div className="card-footer text-center">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-outline-inverse mr-2 "
                  onClick={() => {
                    this.setState({ redirect: true });
                  }}
                >
                  <i className="fa fa-close mr-2"></i>CANCELAR
                </button>

                <button type="submit" className="btn btn-info">
                  <i className="fa fa-check mr-2"></i>
                  Guardar y Asignar Nombramientos
                </button>
              </div>
            </div>
          }
        >
          <div className="form-body">
            <div className="row">
              <div className="col-lg-4 form-group">
                <label htmlFor="">Descripción:(*)</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Descripción Nombramiento"
                  id="descripcion"
                  value={this.state.descripcion}
                  onChange={this.handleInputChange}
                />
                {this.validator.message(
                  "descripción",
                  this.state.descripcion,
                  "required"
                ) && (
                  <span className="label label-light-danger">
                    {this.validator.message(
                      "descripción",
                      this.state.descripcion,
                      "required"
                    )}
                  </span>
                )}
              </div>

              <div className="col-lg-4 form-group">
                <label htmlFor="">Fecha inicio : (*)</label>

                <input
                  type="date"
                  className="form-control"
                  id="fecha_inicio"
                  value={this.state.fecha_inicio}
                  onChange={this.handleInputChange}
                />
                {this.validator.message(
                  "Fecha inicio",
                  this.state.fecha_inicio,
                  "required"
                ) && (
                  <span className="label label-light-danger">
                    {this.validator.message(
                      "Fecha inicio",
                      this.state.fecha_inicio,
                      "required"
                    )}
                  </span>
                )}
              </div>
              <div className="col-lg-4 form-group">
                <label htmlFor="">Fecha Fin : (*)</label>

                <input
                  type="date"
                  className="form-control"
                  id="fecha_fin"
                  value={this.state.fecha_fin}
                  onChange={this.handleInputChange}
                />
                {this.validator.message(
                  "Fecha Fin",
                  this.state.fecha_fin,
                  "required"
                ) && (
                  <span className="label label-light-danger">
                    {this.validator.message(
                      "Fecha Fin",
                      this.state.fecha_fin,
                      "required"
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
        </LayoutPanelFormulario>
      </React.Fragment>
    );
  }
}
