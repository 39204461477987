import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import LayoutPanelTable from "../../components/layouts/panels/LayoutPanelTable";
import Home_principal from "./Home_principal.png";

export default class Home extends Component {
  render() {
    return (
      <div>
        <LayoutPanelTable titulo="Bienvenidos al Sistema" titulo_panel="Inicio">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <img
                width={isMobile ? "80%" : "40%"}
                src={Home_principal}
                alt="Home"
              />
            </div>
          </div>
        </LayoutPanelTable>
      </div>
    );
  }
}
