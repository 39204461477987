import React, { Component } from "react";
import LayoutPanelTable from "../../../../../components/layouts/panels/LayoutPanelTable";
import TablaFilter from "../../../../../components/tablas/TablaFilter";

import Encrypt from "../../../../../services/Encrypt";

export default class HistoricoRecepcionInformesGestiones extends Component {
  constructor(props) {
    super();
    this.state = this.inicialState;
  }

  inicialState = {
    datos_iglesia_cargados: false,
    iglesia: null,
  };

  componentWillUnmount() {
    Encrypt.destroyItemSession(
      "historico_iglesia_recepcion_informe_cod_gestion"
    );
  }
  columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "codigo",
      text: "Código",
      hidden: false,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "8%", textAlign: "center" };
      },
    },
    {
      dataField: "descripcion",
      text: "Descripción",
      sort: true,
      formatter: (cellContent, row) => {
        return <p className="ml-4">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "fecha_publicacion",
      text: "Fecha Publicación",
      sort: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
    {
      dataField: "fecha_recibir_fin",
      text: "Fecha Limite Entrega",
      sort: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
    {
      dataField: "opciones",
      text: "Opciones",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="text-center">
            <button
              onClick={() => {
                Encrypt.setSession(
                  "historico_iglesia_recepcion_informe_cod_gestion",
                  row.codigo
                );
                this.props.history.push(
                  `/historico/recepcion_informes/iglesia/detalle/${row.codigo}`
                );
              }}
              className="btn btn-outline-info "
            >
              <i className="fa fa-eye mr-1"></i> Ver Detalles
            </button>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
  ];

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <LayoutPanelTable titulo={`Histórico de gestiones Recibidas`}>
          <div className="form-body">
            <div className="row p-t-20">
              <div className="col-12 form-group">
                <TablaFilter
                  ref="tabla"
                  ruta={`gestion_historico/general`}
                  rowEvents={this.rowEvents}
                  identificador={"id"}
                  columns={this.columns}
                  titulo_tabla="Escriba el registro que desea buscar"
                />
              </div>
            </div>
          </div>
        </LayoutPanelTable>
      </React.Fragment>
    );
  }
}
