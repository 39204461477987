import React, { Component } from "react";
import LayoutPanelFormulario from "../../components/layouts/panels/LayoutPanelFormulario";
import HTTP from "../../helpers/HTTP";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import es from "../../helpers/ValidatorTranslate_es";
import InputMask from "react-input-mask";
import Encrypt from "../../services/Encrypt";

export default class TesorerosForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.inicial_state;
    this.init_validator();
  }

  inicial_state = {
    title: this.props.match.params.id
      ? "Actualización de Tesorero"
      : "Registro de Tesorero",

    nombre: "",
    correo: "",
    telefono: "",
    direccion: "",
    loading: false,

    redirect: false,
  };
  handleInputChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    this.setState({ [idComponente]: valorComponente });
  };

  componentDidMount() {
   if (Encrypt.getSession("iglesia_tesorero_create")) {
     
     document.getElementById("nombre").focus();
   }

    this.getById();
  }
  componentWillUnmount() {
    Encrypt.destroyItemSession("iglesia_tesorero_update");
    Encrypt.destroyItemSession("iglesia_tesorero_create");
  }
  init_validator() {
    SimpleReactValidator.addLocale("esp", es);
    this.validator = new SimpleReactValidator({ locale: "esp" });
  }

  hide_alert_validator() {
    this.validator.hideMessages();
    this.forceUpdate();
  }

  getById() {
    if (this.props.match.params.id) {
      HTTP.findById(this.props.match.params.iglesia, "tesorero").then(
        (result) => {
          if (result !== false) {
            this.setState(result);
          } else {
            this.setState({ redirect: true });
          }
        }
      );
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ loading: true });

      if (this.props.match.params.id) {
        const data = {
          code: this.props.match.params.id,
          nombre: this.state.nombre,
          correo: this.state.correo,
          telefono: this.state.telefono,
          iglesia: this.props.match.params.iglesia,
          direccion: this.state.direccion,
        };
        HTTP.update(data, "tesorero", "tesoreros", "tesorero").then(
          (result) => {
            this.setState({ loading: false });
            if (result !== false) {
              this.setState({ redirect: true });
            }
          }
        );
      } else {
        const data = {
          nombre: this.state.nombre,
          correo: this.state.correo,
          telefono: this.state.telefono,
          iglesia: this.props.match.params.iglesia,
          direccion: this.state.direccion,
        };
        HTTP.create(data, "tesorero", "tesoreros", "tesorero").then(
          (result) => {
            this.setState({ loading: false });
            if (result !== false) {
              this.setState({ redirect: true });
            }
          }
        );
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/presentacion_informes/iglesia" />;
    }

    if (this.props.match.params.id) {
      if (Encrypt.getSession("iglesia_tesorero_update")) {
        if (
          Encrypt.getSession("iglesia_tesorero_update") !==
          this.props.match.params.iglesia
        ) {
          return <Redirect to="/presentacion_informes/iglesia" />;
        }
      } else {
        return <Redirect to="/presentacion_informes/iglesia" />;
      }
    } else {
      if (Encrypt.getSession("iglesia_tesorero_create")) {
        if (
          Encrypt.getSession("iglesia_tesorero_create") !==
          this.props.match.params.iglesia
        ) {
          return <Redirect to="/presentacion_informes/iglesia" />;
        }
      } else {
        return <Redirect to="/presentacion_informes/iglesia" />;
      }
    }
    return (
      <React.Fragment>
        <LayoutPanelFormulario
          titulo={this.state.title}
          submit={this.onSubmit}
          buttons={
            <div className="card-footer text-center">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-outline-inverse mr-2 "
                  onClick={() => {
                    this.setState({ redirect: true });
                  }}
                  disabled={this.state.loading}
                >
                  <i className="fa fa-close mr-2"></i>CANCELAR
                </button>

                <button
                  type="submit"
                  className="btn btn-info"
                  disabled={this.state.loading}
                >
                  <i className="fa fa-save mr-2"></i>
                  {this.state.loading === false ? "GUARDAR" : "GUARDANDO..."}
                </button>
              </div>
            </div>
          }
        >
          <div className="form-body">
            <div className="row p-t-20">
              <div className="col-lg-4 form-group">
                <label htmlFor="">Nombre: (*)</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre"
                  id="nombre"
                  value={this.state.nombre}
                  onChange={this.handleInputChange}
                />
                {this.validator.message(
                  "nombre ",
                  this.state.nombre,
                  "required"
                ) && (
                  <span className="label label-light-danger">
                    {this.validator.message(
                      "nombre",
                      this.state.nombre,
                      "required"
                    )}
                  </span>
                )}
              </div>
              <div className="col-lg-4 form-group">
                <label htmlFor="">Correo Electrónico:</label>

                <input
                  type="email"
                  className="form-control"
                  placeholder="E-Mail"
                  id="correo"
                  value={this.state.correo}
                  onChange={this.handleInputChange}
                />
                {this.validator.message(
                  "correo electrónico ",
                  this.state.correo,
                  "email"
                ) && (
                  <span className="label label-light-danger">
                    {this.validator.message(
                      "correo electrónico",
                      this.state.correo,
                      "email"
                    )}
                  </span>
                )}
              </div>
              <div className="col-lg-4 form-group">
                <label htmlFor="">Teléfono: </label>

                <InputMask
                  type="text"
                  id="telefono"
                  value={this.state.telefono}
                  className="form-control"
                  onChange={this.handleInputChange}
                  placeholder="Número de teléfono"
                  mask="9999-9999"
                ></InputMask>
              </div>

              <div className="col-lg-12 form-group">
                <label htmlFor="">Dirección: </label>

                <textarea
                  name="direccion"
                  id="direccion"
                  cols="30"
                  rows="5"
                  className="form-control"
                  value={this.state.direccion}
                  onChange={this.handleInputChange}
                  placeholder="Escriba la Dirección"
                ></textarea>
              </div>
            </div>
          </div>
        </LayoutPanelFormulario>
      </React.Fragment>
    );
  }
}
