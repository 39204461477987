import React, { Component } from "react";
import LayoutPanelTable from "../../../../../components/layouts/panels/LayoutPanelTable";
import HTTP from "../../../../../helpers/HTTP";
import Encrypt from "../../../../../services/Encrypt";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Alerts from "../../../../../services/Alerts";
import ContentLoader from "react-content-loader";
export default class HistoricoPresentacionInformesIglesias extends Component {
  constructor(props) {
    super(props);
    this.state = this.inicial_state;
    this.state.usuario = Encrypt.getSession("codigo_persona");
    this.ActionModalDetalle = this.ActionModalDetalle.bind(this);
  }

  inicial_state = {
    iglesias: [],
    iglesia_seleccionada: [],
    usuario: "",
    cargadas: false,
    modal_detalle: false,
  };
  componentDidMount() {
    this.getIglesias();

    Encrypt.destroyItemSession("historico_iglesia_presentacion_informe");
  }
  ActionModalDetalle(flag = true) {
    this.setState((prevState) => ({
      modal_detalle: !prevState.modal_detalle,
    }));
    if (flag) {
    } else {
      this.setState({
        iglesia_seleccionada: null,
      });
    }
  }
  getIglesias() {
    HTTP.findById(this.state.usuario, "usuarios/iglesias_asignadas").then(
      (result) => {
        this.setState({ cargadas: true });
        if (result !== false) {
          this.setState({ iglesias: result });
        }
      }
    );
  }

  getIglesia(id) {
    Alerts.loading_reload(true);
    HTTP.findById(id, "iglesias/detalle").then(async (result) => {
      Alerts.loading_reload(false);

      if (result !== false) {
        await this.setState({ iglesia_seleccionada: result });
        this.ActionModalDetalle();
      }
    });
  }

  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={400} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="300" />
        </ContentLoader>
      </React.Fragment>
    );
  }
  render() {
    return (
      <div>
        <LayoutPanelTable
          titulo="Historial de Presentación de informes"
          titulo_panel="Iglesias Asignadas"
        >
          {this.state.cargadas === true ? (
            <div className="row">
              {this.state.iglesias.length > 0 ? (
                this.state.iglesias.map((element) => {
                  return (
                    <div
                      className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mx-auto"
                      key={element.codigo}
                    >
                      <div className="card  border border-dark">
                        <div className="card-header">
                          <h4 className="m-b-0 text-black">
                            <strong>{element.iglesia.toUpperCase()}</strong>
                          </h4>
                        </div>
                        <div className="card-body">
                          <h3 className="card-title text-center">
                            <strong>Pastor:</strong>
                            <br />
                            {element.pastor !== "" && element.pastor !== null
                              ? element.pastor
                              : "SIN PASTOR NOMBRADO"}
                          </h3>

                          <h3 className="card-title text-center">
                            <strong>Tesorero:</strong>
                            <br />
                            {element.tesorero !== "" &&
                            element.tesorero !== null ? (
                              <button
                                className="btn btn-link"
                                title="Click para actualizar"
                                onClick={() => {
                                  Encrypt.setSession(
                                    "iglesia_tesorero_update",
                                    element.codigo
                                  );

                                  this.props.history.push(
                                    `/tesorero/update/${element.codigo}/${element.id_tesorero}`
                                  );
                                }}
                              >
                                {" "}
                                {element.tesorero}
                              </button>
                            ) : (
                              <button
                                className="btn btn-link"
                                onClick={() => {
                                  Encrypt.setSession(
                                    "iglesia_tesorero_create",
                                    element.codigo
                                  );

                                  this.props.history.push(
                                    `/tesorero/create/${element.codigo}`
                                  );
                                }}
                              >
                                <i className="fa fa-plus mr-2"></i> Agregar
                              </button>
                            )}
                          </h3>
                        </div>
                        <div className="card-footer text-center">
                          <div className="btn-group-vertical">
                            <button
                              onClick={this.getIglesia.bind(
                                this,
                                element.codigo
                              )}
                              className="btn btn-secondary mb-2"
                            >
                              <i className="fa fa-clipboard mr-1"></i> Ver
                              Detalle Iglesia
                            </button>

                            <button
                              onClick={() => {
                                Encrypt.setSession(
                                  "historico_iglesia_presentacion_informe",
                                  element.codigo
                                );
                                this.props.history.push(
                                  `/historico/presentacion_informes/gestiones/${element.codigo}`
                                );
                              }}
                              className="btn btn-outline-info "
                            >
                              <i className="fa fa-eye mr-1"></i> Ver Gestiones
                              Anteriores
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">
                  <h1>NO TIENE IGLESIAS ASIGNADAS</h1>
                  <span>
                    Si concidera que fue un error, comuniquese con el
                    administrador
                  </span>
                </div>
              )}
            </div>
          ) : (
            this.loadingData()
          )}
        </LayoutPanelTable>

        <Modal
          size="lg"
          style={{ maxWidth: "1600px", width: "75%" }}
          isOpen={this.state.modal_detalle}
        >
          <ModalHeader toggle={this.ActionModalDetalle.bind(this, false)}>
            Información de Iglesia
          </ModalHeader>
          <ModalBody>
            <h3 className="card-title">Información General</h3>
            <hr />
            <div className="row">
              <div className="col-lg-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Nombre</th>
                      <th>Pastor</th>
                      <th>Teléfono</th>
                      <th>Tipo Iglesia</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.codigo
                          : ""}
                      </td>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.nombre
                          : ""}
                      </td>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.pastor !== "" &&
                            this.state.iglesia_seleccionada.pastor !== null
                            ? this.state.iglesia_seleccionada.pastor
                            : "Sin Nombramiento"
                          : null}
                      </td>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.telefono !== "" &&
                            this.state.iglesia_seleccionada.telefono !== null
                            ? this.state.iglesia_seleccionada.telefono
                            : "No Registrado"
                          : null}
                      </td>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.tipo_iglesia
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <h3 className="card-title">Información Organizacional</h3>
            <hr />
            <div className="row">
              <div className="col-lg-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Región</th>
                      <th>Zona</th>
                      <th>Distrito</th>
                      <th>Fecha de Afiliación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {/* {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.codigo
                          : ""} */}
                      </td>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.zona
                          : ""}
                      </td>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.distrito
                          : ""}
                      </td>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada
                              .fecha_ordenamiento !== "" &&
                            this.state.iglesia_seleccionada
                              .fecha_ordenamiento !== null
                            ? this.state.iglesia_seleccionada.fecha_ordenamiento
                            : "Sin Fecha establecida"
                          : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h3 className="card-title">Información Geográfica</h3>
            <hr />
            <div className="row">
              <div className="col-lg-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Departamento</th>
                      <th>Municipio</th>
                      <th>Cantón</th>
                      <th>Dirección</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.departamento
                          : ""}
                      </td>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.municipio
                          : ""}
                      </td>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.canton !== "" &&
                            this.state.iglesia_seleccionada.canton !== null
                            ? this.state.iglesia_seleccionada.canton
                            : "N/A"
                          : null}
                      </td>
                      <td>
                        {this.state.iglesia_seleccionada !== null
                          ? this.state.iglesia_seleccionada.direccion
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="text-right"></ModalFooter>
        </Modal>
      </div>
    );
  }
}
