import React, { Component } from "react";
//import LayoutPanelTable from "../../../components/layouts/panels/LayoutPanelTable";
import { Tabs, Tab } from "react-bootstrap";
//import LayoutPanelEmpty from "../../../components/layouts/panels/LayoutPanelEmpty";
import Request from "../../../services/Request";
import HTTP from "../../../helpers/HTTP";
import Alerts from "../../../services/Alerts";
import ContentLoader from "react-content-loader";
import LayoutPanelFormulario from "../../../components/layouts/panels/LayoutPanelFormulario";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import es from "../../../helpers/ValidatorTranslate_es";
import { Redirect } from "react-router-dom";
import TablaFilter from "../../../components/tablas/TablaFilter";
import { Menu, Item, contextMenu, IconFont, Separator } from "react-contexify";
import Encrypt from "../../../services/Encrypt";
import DateValidator from "../../../services/DateValidator";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default class NombramientoPastoresGestion extends Component {
  constructor(props) {
    super(props);
    this.state = this.inicial_state;
    this.ActionModalIglesias = this.ActionModalIglesias.bind(this);
    this.init_validator();
  }
  async ActionModalIglesias(flag = true) {
    await this.setState((prevState) => ({
      modal_iglesia: !prevState.modal_iglesia,
    }));
    // if (flag) {
    // } else {
    //   this.setState({
    //     imagen_comprobante: null,
    //   });
    // }
  }
  inicial_state = {
    tab_active: "nombramientos_pastores",

    distritos: [],
    distrito: "",
    disabled_select_distrito: true,
    pastores: [],
    pastor: "",
    disabled_select_pastor: true,

    tipo: "",
    tipos: [],
    disabled_select_tipo: true,

    iglesias: [],
    iglesia: "",
    disabled_select_iglesia: true,

    zonas: [],
    zona: "",
    disabled_select_zona: true,

    regiones: [],
    region: "",
    disabled_select_region: true,
    pastores_ministros: [],
    disabled_select_pastor_ministros: true,
    redirect: false,
    datos_nombramiento_cargados: false,
    vigente: false,
    nombramiento: "",
    modal_iglesia: false,
  };
  async handleChangeTab(key) {
    await this.setState({ tab_active: key });

    if (key === "nombramientos_pastores") {
      this.limpiar();
      this.hide_alert_validator();
      this.refs.tabla_pastores.clear();
    } else if (key === "supervisores_region") {
      this.hide_alert_validator();
      this.limpiar();
      this.refs.tabla_supervisores_region.clear();
    } else if (key === "supervisores_distrito") {
      this.hide_alert_validator();
      this.limpiar();
      this.refs.tabla_supervisores_distrito.clear();
    }
  }

  limpiar() {
    this.setState({
      distritos: [],
      distrito: "",
      disabled_select_distrito: true,

      pastor: "",
      tipos: this.getTipos(),
      disabled_select_tipo: true,

      iglesias: [],
      iglesia: "",
      disabled_select_iglesia: true,

      zona: "",
      region: "",
    });
  }
  componentDidMount() {
    // this.getGestionesNoPublicadas();
    // this.getGestionesPublicadas();
    this.getNombramiento();
    this.setState({
      zonas: this.getZonas(),
      pastores: this.getPastores(),
      pastores_ministros: this.getPastoresMinistros(),
      tipos: this.getTipos(),
      regiones: this.getRegiones(),
    });
  }
  rowEvents = {
    onContextMenu: (e, row, rowIndex) => {
      e.preventDefault();

      if (this.state.vigente === true && row.estado === 1) {
        contextMenu.show({
          id: "menu_vigente",
          event: e,
          props: {
            id: row.id,
          },
        });
      } else if (this.state.vigente === false) {
        contextMenu.show({
          id: "menu_no_vigente",
          event: e,
          props: {
            id: row.id,
          },
        });
      }
    },
  };
  rowEventsRegion = {
    onContextMenu: (e, row, rowIndex) => {
      e.preventDefault();

      if (this.state.vigente === true && row.estado === 1) {
        contextMenu.show({
          id: "menu_vigente_region",
          event: e,
          props: {
            id: row.id,
          },
        });
      } else if (this.state.vigente === false) {
        contextMenu.show({
          id: "menu_no_vigente_region",
          event: e,
          props: {
            id: row.id,
          },
        });
      }
    },
  };
  rowEventsDistrito = {
    onContextMenu: (e, row, rowIndex) => {
      e.preventDefault();

      if (this.state.vigente === true && row.estado === 1) {
        contextMenu.show({
          id: "menu_vigente_distrito",
          event: e,
          props: {
            id: row.id,
          },
        });
      } else if (this.state.vigente === false) {
        contextMenu.show({
          id: "menu_no_vigente_distrito",
          event: e,
          props: {
            id: row.id,
          },
        });
      }
    },
  };

  async seleccionarIglesia(row) {
    Alerts.loading_reload_text(true, "Cargando Iglesia");
    await this.setState({
      zona: row.zona,
      distrito: row.distrito,
      iglesia: row.iglesia,
      distritos: this.getDistritos(row.zona_code),
      iglesias: this.getIglesias(
        row.zona_code,
        row.distrito_code,
        this.props.match.params.id,
        row.zona,
        row.distrito,
        true,
        false
      ),
    });
    this.ActionModalIglesias(false);
  }
  rowEventsIglesias = {
    onClick: (e, row) => {
      this.seleccionarIglesia(row);
    },
  };
  columnsNombramientoPastores = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "iglesia",
      text: "IGLESIA",
      hidden: false,
      formatter: (cellContent, row) => {
        return <p className="ml-3">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "pastor",
      text: "PASTOR",
      sort: true,
      formatter: (cellContent, row) => {
        return <p className="ml-3">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "20%", textAlign: "center" };
      },
    },
    {
      dataField: "tipo",
      text: "TIPO NOMBRAMIENTO",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === "OFICIAL") {
          return (
            <div className="text-center">
              <span className="label label-info">
                {cellContent?.toUpperCase()}
              </span>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <span className="label label-inverse">
                {cellContent?.toUpperCase()}
              </span>
            </div>
          );
        }
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },

    {
      dataField: "estado",
      text: "ESTADO",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === 1) {
          return (
            <p className="text-center">
              <span className="label label-light-info"> ACTIVO</span>
            </p>
          );
        } else if (cellContent === 0) {
          if (row.tipo === "OFICIAL") {
            return (
              <p className="text-center">
                <span className="label label-light-danger">
                  FINALIZADO CON ANTICIPACIÓN
                </span>
              </p>
            );
          } else {
            return (
              <p className="text-center">
                <span className="label label-light-danger">FINALIZADO</span>
              </p>
            );
          }
        }
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
  ];
  columnsNombramientoRegion = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "region",
      text: "REGIÓN",
      hidden: false,
      formatter: (cellContent, row) => {
        return <p className="ml-3">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "pastor",
      text: "SUPERVISOR",
      sort: true,
      formatter: (cellContent, row) => {
        return <p className="ml-3">{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "20%", textAlign: "center" };
      },
    },
    {
      dataField: "tipo",
      text: "TIPO NOMBRAMIENTO",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === "OFICIAL") {
          return (
            <div className="text-center">
              <span className="label label-info">
                {cellContent.toUpperCase()}
              </span>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <span className="label label-inverse">
                {cellContent.toUpperCase()}
              </span>
            </div>
          );
        }
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },

    {
      dataField: "estado",
      text: "ESTADO",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === 1) {
          return (
            <p className="text-center">
              <span className="label label-light-info"> ACTIVO</span>
            </p>
          );
        } else if (cellContent === 0) {
          return (
            <p className="text-center">
              <span className="label label-light-danger">
                FINALIZADO CON ANTICIPACIÓN
              </span>
            </p>
          );
        }
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
  ];
  columnsNombramientoDistrito = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "distrito",
      text: "DISTRITO",
      hidden: false,
      formatter: (cellContent, row) => {
        return <p className="ml-3">{cellContent.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "zona",
      text: "ZONA",
      hidden: false,
      formatter: (cellContent, row) => {
        return <p className="ml-3">{cellContent.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "pastor",
      text: "SUPERVISOR",
      sort: true,
      formatter: (cellContent, row) => {
        return <p className="ml-3">{cellContent.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "20%", textAlign: "center" };
      },
    },
    {
      dataField: "tipo",
      text: "TIPO NOMBRAMIENTO",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === "OFICIAL") {
          return (
            <div className="text-center">
              <span className="label label-info">
                {cellContent.toUpperCase()}
              </span>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <span className="label label-inverse">
                {cellContent.toUpperCase()}
              </span>
            </div>
          );
        }
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },

    {
      dataField: "estado",
      text: "ESTADO",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === 1) {
          return (
            <p className="text-center">
              <span className="label label-light-info"> ACTIVO</span>
            </p>
          );
        } else if (cellContent === 0) {
          return (
            <p className="text-center">
              <span className="label label-light-danger">
                FINALIZADO CON ANTICIPACIÓN
              </span>
            </p>
          );
        }
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
  ];

  columnsVisorIglesias = [
    {
      dataField: "zona",
      text: "id",
      hidden: true,
    },
    {
      dataField: "distrito",
      text: "id",
      hidden: true,
    },
    {
      dataField: "iglesia",
      text: "id",
      hidden: true,
    },

    {
      dataField: "distrito_code",
      text: "id",
      hidden: true,
    },
    {
      dataField: "zona_code",
      text: "id",
      hidden: true,
    },

    {
      dataField: "id",
      text: "id",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "codigo",
      text: "Código",
      hidden: false,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
    {
      dataField: "nombre",
      text: "Nombre Iglesia",
      hidden: false,
      formatter: (cellContent, row) => {
        return <p>{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "region",
      text: "Región",
      hidden: false,
      formatter: (cellContent, row) => {
        return <p>{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "zona_name",
      text: "Zona",
      hidden: false,
      formatter: (cellContent, row) => {
        return <p>{cellContent?.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "distrito_name",
      text: "Distrito",
      hidden: false,
      formatter: (cellContent, row) => {
        return <p>{cellContent.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "opciones",
      text: "Opciones",
      hidden: false,
      formatter: (cellContent, row) => {
        return (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-outline-info mb-3"
              onClick={this.seleccionarIglesia.bind(this, row)}
            >
              <i className="fa fa-check mr-2"></i>Seleccionar
            </button>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
    },
  ];
  getNombramiento() {
    if (this.props.match.params.id) {
      HTTP.findById(
        this.props.match.params.id,
        "nombramientos_pastorales/header"
      ).then((result) => {
        if (result !== false) {
          this.setState({
            nombramiento: result,
            datos_nombramiento_cargados: true,
          });
          if (result.estado === 2) {
            this.setState({ vigente: true });
          }
        } else {
          this.setState({ redirect: true });
        }
      });
    }
  }
  init_validator() {
    SimpleReactValidator.addLocale("esp", es);
    this.validatorPastores = new SimpleReactValidator({ locale: "esp" });
    this.validatorSupervisorDistrito = new SimpleReactValidator({
      locale: "esp",
    });
    this.validatorSupervisorRegion = new SimpleReactValidator({
      locale: "esp",
    });
  }

  hide_alert_validator() {
    this.validatorPastores.hideMessages();
    this.validatorSupervisorDistrito.hideMessages();
    this.validatorSupervisorRegion.hideMessages();
    this.forceUpdate();
  }

  getRegiones() {
    let data = [];

    HTTP.findAll(
      `generales/regiones/nombramiento/${this.props.match.params.id}`
    ).then((result) => {
      result.forEach((element) => {
        data.push({ label: element.nombre, value: element.id });
      });
      this.setState({ disabled_select_region: false });
    });

    return data;
  }
  getTipos() {
    let data = [];

    HTTP.findAll("generales/comodin/nombramiento_pastoral").then((result) => {
      result.forEach((element) => {
        data.push({ label: element.nombre, value: element.id });
        if (parseInt(element.defecto) === 1) {
          this.setState({ tipo: { label: element.nombre, value: element.id } });
        }
      });
      this.setState({ disabled_select_tipo: false });
    });

    return data;
  }
  //PARA OBTENER LAS ZONAS

  getZonasParam = (inputValue, callback) => {
    const tempArray = [];

    if (inputValue !== "" && inputValue !== null) {
      clearTimeout(this.timer_cuentas);
      this.timer_cuentas = setTimeout(() => {
        HTTP.findById(inputValue, "zonas/select").then((data) => {
          if (data !== false) {
            data.forEach((element) => {
              tempArray.push({
                label: element.nombre,
                value: element.id,
                codigo: element.codigo,
              });
            });
            callback(tempArray);
          } else {
            callback([]);
          }
        });
      }, 500);
    }
  };

  getZonas() {
    let data = [];

    HTTP.findAll("zonas/select").then((result) => {
      result.forEach((element) => {
        data.push({
          label: element.nombre,
          value: element.id,
          codigo: element.codigo,
        });
      });

      this.setState({ disabled_select_zona: false });
    });

    return data;
  }

  //PARA OBTENER LOS DISTRITOS
  timer_iglesias = null;
  getIglesiasParam = (inputValue, callback) => {
    const tempArray = [];

    if (
      this.state.zona !== "" &&
      this.state.zona !== null &&
      this.state.distrito !== "" &&
      this.state.distrito !== null
    ) {
      if (inputValue !== "" && inputValue !== null) {
        clearTimeout(this.timer_iglesias);
        this.timer_iglesias = setTimeout(() => {
          HTTP.findById(
            inputValue,
            `iglesias/select_nombramiento/${this.state.zona.codigo}/${this.state.distrito.codigo}/${this.props.match.params.id}`
          ).then((data) => {
            if (data !== false) {
              data.forEach((element) => {
                tempArray.push({
                  label: element.nombre,
                  value: element.id,
                  codigo: element.codigo,
                });
              });
              callback(tempArray);
            } else {
              callback([]);
            }
          });
        }, 500);
      }
    } else {
      callback([]);
    }
  };

  getIglesias(
    codigo_zona,
    codigo_distrito,
    nombramiento,
    zona,
    distrito,
    close_alert = false,
    load = false
  ) {
    let data = [];
    this.setState({ disabled_select_iglesia: true });
    if (load) {
      Alerts.loading_reload_text(true, "Obteniento Iglesias");
    }
    HTTP.findAll(
      `iglesias/select_nombramiento/${codigo_zona}/${codigo_distrito}/${nombramiento}`
    ).then((result) => {
      if (close_alert) {
        Alerts.loading_reload_text(false);
      }
      if (close_alert) {
        Alerts.loading_reload_text(false, "Obteniento Iglesias");
      }
      if (result.length > 0) {
        result.forEach((element) => {
          data.push({
            label: element.nombre,
            codigo: element.codigo,
            value: element.id,
          });
        });
        this.setState({ disabled_select_iglesia: false });
      } else {
        Alerts.alertEmpty(
          `¡No hay iglesias por nombrar para el ${distrito.label.toLowerCase()} de la ${zona.label.toLowerCase()}!`,
          "Administración de Nombramientos",
          "info"
        );
        this.setState({
          disabled_select_iglesia: true,
          iglesia: "",
          iglesias: [],
          distrito: "",
        });
      }
    });

    return data;
  }

  //PARA OBTENER LOS PASTORES
  timer_pastores = null;
  getPastoresMinistrosParam = (inputValue, callback) => {
    const tempArray = [];

    if (inputValue !== "" && inputValue !== null) {
      clearTimeout(this.timer_pastores);
      this.timer_pastores = setTimeout(() => {
        HTTP.findById(inputValue, `pastores/select_ministros_obispos`).then(
          (data) => {
            if (data !== false) {
              data.forEach((element) => {
                tempArray.push({
                  label: element.nombre,
                  value: element.id,
                  codigo: element.codigo,
                  persona: element.persona,
                });
              });
              callback(tempArray);
            } else {
              callback([]);
            }
          }
        );
      }, 500);
    }
  };

  getPastoresMinistros() {
    let data = [];

    HTTP.findAll(`pastores/select_ministros_obispos`).then((result) => {
      result.forEach((element) => {
        data.push({
          label: element.nombre,
          codigo: element.codigo,
          persona: element.persona,
          value: element.id,
        });
      });

      this.setState({ disabled_select_pastor_ministros: false });
    });

    return data;
  }

  //PARA OBTENER LOS PASTORES
  timer_pastores = null;
  getPastoresParam = (inputValue, callback) => {
    const tempArray = [];

    if (inputValue !== "" && inputValue !== null) {
      clearTimeout(this.timer_pastores);
      this.timer_pastores = setTimeout(() => {
        HTTP.findById(inputValue, `pastores/select`).then((data) => {
          if (data !== false) {
            data.forEach((element) => {
              tempArray.push({
                label: element.nombre,
                value: element.id,
                codigo: element.codigo,
                persona: element.persona,
              });
            });
            callback(tempArray);
          } else {
            callback([]);
          }
        });
      }, 500);
    }
  };

  getPastores() {
    let data = [];

    HTTP.findAll(`pastores/select`).then((result) => {
      result.forEach((element) => {
        data.push({
          label: element.nombre,
          codigo: element.codigo,
          persona: element.persona,
          value: element.id,
        });
      });

      this.setState({ disabled_select_pastor: false });
    });

    return data;
  }

  getDistritosParamNombramiento = (inputValue, callback) => {
    const tempArray = [];

    if (this.state.zona !== "" && this.state.zona !== null) {
      if (inputValue !== "" && inputValue !== null) {
        clearTimeout(this.timer_cuentas);
        this.timer_cuentas = setTimeout(() => {
          HTTP.findById(
            inputValue,
            `distritos/select_nombramiento/${this.state.zona.codigo}/${this.props.match.params.id}`
          ).then((data) => {
            if (data !== false) {
              data.forEach((element) => {
                tempArray.push({
                  label: element.nombre,
                  value: element.id,
                  codigo: element.codigo,
                });
              });
              callback(tempArray);
            } else {
              callback([]);
            }
          });
        }, 500);
      }
    } else {
      callback([]);
    }
  };

  getDistritosNombramiento(codigo_zona) {
    let data = [];

    HTTP.findAll(
      `distritos/select_nombramiento/${codigo_zona}/${this.props.match.params.id}`
    ).then((result) => {
      result.forEach((element) => {
        data.push({
          label: element.nombre,
          value: element.id,
          codigo: element.codigo,
        });
      });

      this.setState({ disabled_select_distrito: false });
    });

    return data;
  }

  //PARA OBTENER LOS DISTRITOS

  getDistritosParam = (inputValue, callback) => {
    const tempArray = [];

    if (this.state.zona !== "" && this.state.zona !== null) {
      if (inputValue !== "" && inputValue !== null) {
        clearTimeout(this.timer_cuentas);
        this.timer_cuentas = setTimeout(() => {
          HTTP.findById(
            inputValue,
            `distritos/select/${this.state.zona.codigo}`
          ).then((data) => {
            if (data !== false) {
              data.forEach((element) => {
                tempArray.push({
                  label: element.nombre,
                  value: element.id,
                  codigo: element.codigo,
                });
              });
              callback(tempArray);
            } else {
              callback([]);
            }
          });
        }, 500);
      }
    } else {
      callback([]);
    }
  };

  getDistritos(codigo_zona) {
    let data = [];

    HTTP.findAll(`distritos/select/${codigo_zona}`).then((result) => {
      result.forEach((element) => {
        data.push({
          label: element.nombre,
          value: element.id,
          codigo: element.codigo,
        });
      });

      this.setState({ disabled_select_distrito: false });
    });

    return data;
  }

  delete = async ({ event, props }) => {
    const data = {
      code: props.id,
    };

    HTTP.delete_disable(
      data,
      "nombramiento pastoral",
      "nombramientos pastorales",
      "nombramientos_pastorales/detalle"
    ).then((result) => {
      this.refs.tabla_pastores.clear();
      if (
        this.state.zona !== "" &&
        this.state.zona !== null &&
        this.state.distrito !== "" &&
        this.state.distrito !== null
      ) {
        this.setState({
          iglesia: "",
          iglesias: this.getIglesias(
            this.state.zona.codigo,
            this.state.distrito.codigo,
            this.props.match.params.id,
            this.state.zona,
            this.state.distrito,
            false
          ),
        });
      }
    });
  };

  finalizarDetalle = async ({ event, props }) => {
    const data = {
      code: props.id,
    };
    Alerts.QuestionYesNo(
      "¿Desea finalizar el nombramiento?",
      "Administracion de Nombramientos Pastorales",
      "question"
    ).then((resp) => {
      if (resp) {
        Alerts.loading_reload_text(true, "Finalizando Nombramiento");
        Request.PUT("nombramientos_pastorales/detalle/finalizar", data).then(
          (result) => {
            Alerts.loading_reload_text(false, "Finalizando Nombramiento");

            if (result !== false) {
              if (result.status === 200) {
                Alerts.alertEmpty(
                  "¡Nombramiento finalizado con éxito",
                  "Administracion de Nombramientos Pastorales",
                  "success"
                );
                this.refs.tabla_pastores.clear();
                if (
                  this.state.zona !== "" &&
                  this.state.zona !== null &&
                  this.state.distrito !== "" &&
                  this.state.distrito !== null
                ) {
                  this.setState({
                    iglesia: "",
                    iglesias: this.getIglesias(
                      this.state.zona.codigo,
                      this.state.distrito.codigo,
                      this.props.match.params.id,
                      this.state.zona,
                      this.state.distrito,
                      false
                    ),
                  });
                }
              } else {
                Alerts.alertEmpty(
                  "¡No se pudo finalizar",
                  "Administracion de Nombramientos Pastorales",
                  "error"
                );
              }
            } else {
              Alerts.alertEmpty(
                "¡No se pudo finalizar",
                "Administracion de Nombramientos Pastorales",
                "error"
              );
            }
          }
        );
      }
    });
  };
  deleteRegion = async ({ event, props }) => {
    const data = {
      code: props.id,
    };

    HTTP.delete_disable(
      data,
      "nombramiento pastoral",
      "nombramientos pastorales",
      "nombramientos_pastorales/detalle"
    ).then((result) => {
      this.refs.tabla_supervisores_region.clear();

      this.setState({
        region: "",
        regiones: this.getRegiones(),
      });
    });
  };
  deleteDistrito = async ({ event, props }) => {
    const data = {
      code: props.id,
    };

    HTTP.delete_disable(
      data,
      "nombramiento pastoral",
      "nombramientos pastorales",
      "nombramientos_pastorales/detalle"
    ).then((result) => {
      this.refs.tabla_supervisores_distrito.clear();

      if (this.state.zona !== "" && this.state.zona !== "") {
        this.setState({
          distrito: "",
          distritos: this.getDistritosNombramiento(this.state.zona.codigo),
        });
      }
    });
  };
  finalizarDetalleRegion = async ({ event, props }) => {
    const data = {
      code: props.id,
    };
    Alerts.QuestionYesNo(
      "¿Desea finalizar el nombramiento?",
      "Administracion de Nombramientos Pastorales",
      "question"
    ).then((resp) => {
      if (resp) {
        Alerts.loading_reload_text(true, "Finalizando Nombramiento");
        Request.PUT("nombramientos_pastorales/detalle/finalizar", data).then(
          (result) => {
            Alerts.loading_reload_text(false, "Finalizando Nombramiento");

            if (result !== false) {
              if (result.status === 200) {
                Alerts.alertEmpty(
                  "¡Nombramiento finalizado con éxito",
                  "Administracion de Nombramientos Pastorales",
                  "success"
                );
                this.refs.tabla_supervisores_region.clear();

                this.setState({
                  region: "",
                  regiones: this.getRegiones(),
                });
              } else {
                Alerts.alertEmpty(
                  "¡No se pudo finalizar",
                  "Administracion de Nombramientos Pastorales",
                  "error"
                );
              }
            } else {
              Alerts.alertEmpty(
                "¡No se pudo finalizar",
                "Administracion de Nombramientos Pastorales",
                "error"
              );
            }
          }
        );
      }
    });
  };

  finalizarDetalleDistrito = async ({ event, props }) => {
    const data = {
      code: props.id,
    };
    Alerts.QuestionYesNo(
      "¿Desea finalizar el nombramiento?",
      "Administracion de Nombramientos Pastorales",
      "question"
    ).then((resp) => {
      if (resp) {
        Alerts.loading_reload_text(true, "Finalizando Nombramiento");
        Request.PUT("nombramientos_pastorales/detalle/finalizar", data).then(
          (result) => {
            Alerts.loading_reload_text(false, "Finalizando Nombramiento");

            if (result !== false) {
              if (result.status === 200) {
                Alerts.alertEmpty(
                  "¡Nombramiento finalizado con éxito",
                  "Administracion de Nombramientos Pastorales",
                  "success"
                );
                this.refs.tabla_supervisores_distrito.clear();

                if (this.state.zona !== "" && this.state.zona !== "") {
                  this.setState({
                    distrito: "",
                    distritos: this.getDistritosNombramiento(
                      this.state.zona.codigo
                    ),
                  });
                }
              } else {
                Alerts.alertEmpty(
                  "¡No se pudo finalizar",
                  "Administracion de Nombramientos Pastorales",
                  "error"
                );
              }
            } else {
              Alerts.alertEmpty(
                "¡No se pudo finalizar",
                "Administracion de Nombramientos Pastorales",
                "error"
              );
            }
          }
        );
      }
    });
  };
  Publicar(codigo) {
    Alerts.QuestionYesNo(
      "¿Está seguro que desea publicar la gestión?",
      "Administración de gestiones de informe",
      "question"
    ).then((resp) => {
      if (resp) {
        const data = {
          codigoGestion: codigo,
        };
        Alerts.loading_reload(true);
        Request.PUT("gestion/publicar", data).then((result) => {
          Alerts.loading_reload(false);
          if (result !== false) {
            if (result.status === 200) {
              Alerts.alertEmpty(
                "¡Gestión publicada con éxito",
                "Administración de gestiones de informe",
                "success"
              );
              this.getGestionesNoPublicadas();
              this.getGestionesPublicadas();
              this.setState({
                tab_active: "nombramientos_pastores",
              });
            } else {
              Alerts.alertEmpty(
                "¡No se pudo publicar",
                "Administración de gestiones de informe",
                "error"
              );
            }
          } else {
            Alerts.alertEmpty(
              "¡No se pudo publicar",
              "Administración de gestiones de informe",
              "error"
            );
          }
        });
      }
    });
  }

  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={400} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="400" />
        </ContentLoader>
      </React.Fragment>
    );
  }
  loadingNav() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 60" height={"25"} width={"100%"}>
          <rect x="0" y="3" rx="5" ry="5" width="100%" height="25" />
        </ContentLoader>
      </React.Fragment>
    );
  }

  agregarNombramientoPastoral() {
    if (this.validatorPastores.allValid()) {
      Alerts.loading_reload_text(true, "Agregando Nombramiento");

      const data = {
        persona:
          this.state.pastor !== "" && this.state.pastor !== null
            ? this.state.pastor.persona
            : null,
        pastor:
          this.state.pastor !== "" && this.state.pastor !== null
            ? this.state.pastor.codigo
            : null,
        nombramiento: this.props.match.params.id,
        iglesia:
          this.state.iglesia !== "" && this.state.iglesia !== null
            ? this.state.iglesia.codigo
            : null,
        cargo_ministerial:
          this.state.tipo !== "" && this.state.tipo !== null
            ? this.state.tipo.value === 1
              ? process.env.REACT_APP_CARGO_PASTOR_OFICIAL
              : process.env.REACT_APP_CARGO_PASTOR_TEMPORAL
            : null,
        tipo:
          this.state.tipo !== "" && this.state.tipo !== null
            ? this.state.tipo.value
            : null,
        fecha_inicio:
          this.state.vigente === true
            ? DateValidator.validarFechas(new Date())
            : this.state.nombramiento.fecha_inicio,
      };
      Request.POST("nombramientos_pastorales/detalle", data).then(
        async (result) => {
          Alerts.loading_reload(false);
          if (result !== false) {
            switch (result.status) {
              case 201:
                await this.setState({
                  iglesia: "",
                  pastor: "",
                  tipos: this.getTipos(),
                  iglesias: this.getIglesias(
                    this.state.zona.codigo,
                    this.state.distrito.codigo,
                    this.props.match.params.id,
                    this.state.zona,
                    this.state.distrito,
                    false
                  ),
                });
                Alerts.toast("¡Nombramiento guardado con éxito!", "success");
                this.refs.tabla_pastores.clear();
                break;
              case 409:
                if (!result.data?.error) {
                  Alerts.alertEmpty(
                    "¡La Iglesia seleccionada ya se ha asignado nombramiento de pastor!",
                    "Administración de Nombramientos",
                    "warning"
                  );
                } else {
                  Alerts.alertEmpty(
                    "¡El ministro seleccionado ya cuenta con un nombramiento oficial!",
                    "Administración de Nombramientos",
                    "warning"
                  );
                }

                break;
              default:
                Alerts.alertEmpty(
                  "¡No se ha podido guardar el nombramiento!",
                  "Administración de Nombramientos",
                  "error"
                );
                break;
            }
          } else {
            Alerts.alertEmpty(
              "¡No se ha podido guardar el nombramiento!",
              "Administración de Nombramientos",
              "error"
            );
          }
        }
      );
    } else {
      this.validatorPastores.showMessages();
      this.forceUpdate();
    }
  }

  agregarNombramientoSupervisorRegion() {
    if (this.validatorSupervisorRegion.allValid()) {
      Alerts.loading_reload_text(true, "Agregando Nombramiento");

      const data = {
        persona:
          this.state.pastor !== "" && this.state.pastor !== null
            ? this.state.pastor.persona
            : null,
        pastor:
          this.state.pastor !== "" && this.state.pastor !== null
            ? this.state.pastor.codigo
            : null,
        nombramiento: this.props.match.params.id,
        region:
          this.state.region !== "" && this.state.region !== null
            ? this.state.region.value
            : null,
        cargo_ministerial: process.env.REACT_APP_CARGO_SUPERVISOR_REGION,

        tipo:
          this.state.tipo !== "" && this.state.tipo !== null
            ? this.state.tipo.value
            : null,
        fecha_inicio:
          this.state.vigente === true
            ? DateValidator.validarFechas(new Date())
            : this.state.nombramiento.fecha_inicio,
      };
      Request.POST("nombramientos_pastorales/detalle", data).then(
        async (result) => {
          if (result !== false) {
            switch (result.status) {
              case 201:
                await this.setState({
                  tipos: this.getTipos(),
                  pastor: "",
                  region: "",
                  regiones: this.getRegiones(),
                });
                Alerts.toast("¡Nombramiento guardado con éxito!", "success");
                this.refs.tabla_supervisores_region.clear();
                break;
              case 409:
                Alerts.alertEmpty(
                  "¡La region seleccionada ya se ha asignado nombramiento de pastor!",
                  "Administración de Nombramientos",
                  "warning"
                );

                break;
              default:
                Alerts.alertEmpty(
                  "¡No se ha podido guardar el nombramiento!",
                  "Administración de Nombramientos",
                  "error"
                );
                break;
            }
          } else {
            Alerts.alertEmpty(
              "¡No se ha podido guardar el nombramiento!",
              "Administración de Nombramientos",
              "error"
            );
          }
        }
      );
    } else {
      this.validatorSupervisorRegion.showMessages();
      this.forceUpdate();
    }
  }

  agregarNombramientoSupervisorDistrito() {
    if (this.validatorSupervisorDistrito.allValid()) {
      Alerts.loading_reload_text(true, "Agregando Nombramiento");

      const data = {
        persona:
          this.state.pastor !== "" && this.state.pastor !== null
            ? this.state.pastor.persona
            : null,
        pastor:
          this.state.pastor !== "" && this.state.pastor !== null
            ? this.state.pastor.codigo
            : null,
        nombramiento: this.props.match.params.id,
        distrito:
          this.state.distrito !== "" && this.state.distrito !== null
            ? this.state.distrito.codigo
            : null,
        cargo_ministerial: process.env.REACT_APP_CARGO_SUPERVISOR_DISTRITO,

        tipo:
          this.state.tipo !== "" && this.state.tipo !== null
            ? this.state.tipo.value
            : null,
        fecha_inicio:
          this.state.vigente === true
            ? DateValidator.validarFechas(new Date())
            : this.state.nombramiento.fecha_inicio,
      };
      Request.POST("nombramientos_pastorales/detalle", data).then(
        async (result) => {
          if (result !== false) {
            switch (result.status) {
              case 201:
                await this.setState({
                  pastor: "",
                  tipos: this.getTipos(),
                  distrito: "",
                  distritos_nombramiento: this.getDistritosNombramiento(
                    this.state.zona.codigo
                  ),
                });
                Alerts.toast("¡Nombramiento guardado con éxito!", "success");
                this.refs.tabla_supervisores_distrito.clear();
                break;
              case 409:
                Alerts.alertEmpty(
                  "¡La region seleccionada ya se ha asignado nombramiento de pastor!",
                  "Administración de Nombramientos",
                  "warning"
                );

                break;
              default:
                Alerts.alertEmpty(
                  "¡No se ha podido guardar el nombramiento!",
                  "Administración de Nombramientos",
                  "error"
                );
                break;
            }
          } else {
            Alerts.alertEmpty(
              "¡No se ha podido guardar el nombramiento!",
              "Administración de Nombramientos",
              "error"
            );
          }
        }
      );
    } else {
      this.validatorSupervisorDistrito.showMessages();
      this.forceUpdate();
    }
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/nombramientos/pastorales" />;
    }
    if (!Encrypt.getSession("nombramiento_gestion")) {
      return <Redirect to="/nombramientos/pastorales" />;
    } else {
      if (
        Encrypt.getSession("nombramiento_gestion") !==
        this.props.match.params.id
      ) {
        Encrypt.destroyItemSession("nombramiento_gestion");

        return <Redirect to="/nombramientos/pastorales" />;
      }
    }
    return (
      <React.Fragment>
        <LayoutPanelFormulario
          titulo="Configuración de Nombramientos de Pastores y Ministros"
          titulo_panel={
            this.state.datos_nombramiento_cargados === true
              ? `${
                  this.state.nombramiento !== null
                    ? this.state.nombramiento.descripcion
                    : null
                }`
              : this.loadingNav()
          }
          buttons={
            <div className="card-footer text-center">
              <button
                type="button"
                className="btn btn-outline-inverse mr-2 "
                onClick={() => {
                  this.setState({ redirect: true });
                }}
              >
                <i className="fa fa-arrow-left mr-2"></i>Salir
              </button>
            </div>
          }
          tools={
            this.state.datos_nombramiento_cargados === true ? (
              this.state.vigente === true ? (
                <span className="label label-info font-18">
                  NOMBRAMIENTO VIGENTE
                </span>
              ) : (
                <span className="label label-inverse font-18">
                  NOMBRAMIENTO NO VIGENTE
                </span>
              )
            ) : null
          }
        >
          <div className="form-body">
            <div className="row">
              <div className="col-12 form-group ">
                <Tabs
                  activeKey={this.state.tab_active}
                  onSelect={this.handleChangeTab.bind(this)}
                  id="tabas"
                >
                  <Tab
                    eventKey="nombramientos_pastores"
                    title="Nombramientos Pastorales"
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="pull-left">
                            <h3 className="box-title">
                              Formulario Nombramiento Pastoral
                            </h3>
                          </div>
                          <div className="pull-right">
                            <button
                              type="button"
                              className="btn btn-outline-info mb-3"
                              onClick={this.ActionModalIglesias.bind(this)}
                            >
                              <i className="fa fa-search mr-2"></i>Buscar
                              Iglesia
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-0 mb-4"></hr>
                      <div className="row">
                        <div className="col-lg-2 form-group">
                          <label htmlFor="">Tipo: (*)</label>

                          <Select
                            id="tipo"
                            name="tipo"
                            placeholder="Elija una opción"
                            value={this.state.tipo}
                            isClearable={true}
                            options={this.state.tipos}
                            isDisabled={this.state.disabled_select_tipo}
                            onChange={(e) => {
                              this.setState({
                                tipo: e,
                              });
                            }}
                          />
                          {this.validatorPastores.message(
                            "tipo",
                            this.state.tipo,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorPastores.message(
                                "tipo",
                                this.state.tipo,
                                "required"
                              )}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-2 form-group">
                          <label htmlFor="">Zona: (*)</label>

                          <AsyncSelect
                            id="zona"
                            name="zona"
                            placeholder="Elija una opción"
                            value={this.state.zona}
                            isClearable={true}
                            loadOptions={this.getZonasParam}
                            defaultOptions={this.state.zonas}
                            isDisabled={this.state.disabled_select_zona}
                            onChange={(e) => {
                              this.setState({
                                zona: e,
                                distrito: "",
                                distritos: [],
                                iglesia: "",
                                iglesias: [],
                                disabled_select_distrito: true,
                                disabled_select_iglesia: true,
                              });
                              this.refs.select_distrito.blur();
                              this.refs.select_iglesia.blur();

                              if (e !== null) {
                                this.setState({
                                  distritos: this.getDistritos(e.codigo),
                                });
                              } else {
                                this.setState({
                                  distrito: "",
                                  distritos: [],
                                  iglesia: "",
                                  iglesias: [],
                                  disabled_select_distrito: true,
                                  disabled_select_iglesia: true,
                                });
                                this.refs.select_distrito.blur();
                                this.refs.select_iglesia.blur();
                              }
                            }}
                            noOptionsMessage={(e) => {
                              return "No existen datos";
                            }}
                          />
                          {this.validatorPastores.message(
                            "zona",
                            this.state.zona,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorPastores.message(
                                "zona",
                                this.state.zona,
                                "required"
                              )}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-2 form-group">
                          <label htmlFor="">Distrito: (*)</label>

                          <AsyncSelect
                            id="distrito"
                            name="distrito"
                            placeholder="Elija una opción"
                            value={this.state.distrito}
                            isClearable={true}
                            loadOptions={this.getDistritosParam}
                            defaultOptions={this.state.distritos}
                            isDisabled={this.state.disabled_select_distrito}
                            ref={"select_distrito"}
                            onChange={(e) => {
                              // this.handleFocusSelect(true)
                              this.setState({
                                distrito: e,
                                iglesia: "",
                                iglesias: [],
                                disabled_select_iglesia: true,
                              });
                              this.refs.select_iglesia.blur();
                              if (e !== null) {
                                this.setState({
                                  iglesias: this.getIglesias(
                                    this.state.zona.codigo,
                                    e.codigo,
                                    this.props.match.params.id,
                                    this.state.zona,
                                    e,
                                    true,
                                    true
                                  ),
                                });
                              } else {
                                this.setState({
                                  iglesia: "",
                                  iglesias: [],
                                  disabled_select_iglesia: true,
                                });
                              }
                            }}
                            noOptionsMessage={() => {
                              return "No existen datos";
                            }}
                          />
                          {this.validatorPastores.message(
                            "distrito",
                            this.state.distrito,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorPastores.message(
                                "distrito",
                                this.state.distrito,
                                "required"
                              )}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Iglesia: (*)</label>

                          <AsyncSelect
                            id="iglesia"
                            name="iglesia"
                            placeholder="Elija una opción"
                            value={this.state.iglesia}
                            isClearable={true}
                            loadOptions={this.getIglesiasParam}
                            defaultOptions={this.state.iglesias}
                            isDisabled={this.state.disabled_select_iglesia}
                            ref={"select_iglesia"}
                            onChange={(e) => {
                              this.setState({
                                iglesia: e,
                              });
                            }}
                            noOptionsMessage={(e) => {
                              return "No existen datos";
                            }}
                          />
                          {this.validatorPastores.message(
                            "iglesia",
                            this.state.iglesia,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorPastores.message(
                                "iglesia",
                                this.state.iglesia,
                                "required"
                              )}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Pastor: (*)</label>

                          <AsyncSelect
                            id="pastor"
                            name="pastor"
                            placeholder="Elija una opción"
                            value={this.state.pastor}
                            isClearable={true}
                            loadOptions={this.getPastoresParam}
                            defaultOptions={this.state.pastores}
                            isDisabled={this.state.disabled_select_pastor}
                            onChange={(e) => {
                              this.setState({
                                pastor: e,
                              });
                            }}
                            noOptionsMessage={(e) => {
                              return "No existen datos";
                            }}
                          />
                          {this.validatorPastores.message(
                            "pastor",
                            this.state.pastor,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorPastores.message(
                                "pastor",
                                this.state.pastor,
                                "required"
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          <div className="card-footer">
                            <button
                              type="button"
                              className="btn btn-lg btn-info"
                              onClick={this.agregarNombramientoPastoral.bind(
                                this
                              )}
                            >
                              <i className="fa fa-plus"></i>Agregar
                            </button>
                          </div>
                        </div>
                      </div>
                      <h3 className="box-title">Pastores Nombrados</h3>
                      <hr className="mt-0 mb-4"></hr>
                      <div className="row">
                        <div className="col-12">
                          <TablaFilter
                            ref="tabla_pastores"
                            ruta={`nombramientos_pastorales/detalle/all/${this.props.match.params.id}`}
                            rowEvents={this.rowEvents}
                            identificador={"id"}
                            columns={this.columnsNombramientoPastores}
                            titulo_tabla="Escriba el registro que desea buscar"
                          />
                          <Menu id={"menu_no_vigente"}>
                            <Item onClick={this.delete}>
                              <IconFont className="fa fa-trash" />
                              ELIMINAR
                            </Item>
                          </Menu>
                          <Menu id={"menu_vigente"}>
                            <Item onClick={this.finalizarDetalle}>
                              <IconFont className="fa fa-close" />
                              FINALIZAR
                            </Item>
                            {JSON.parse(Encrypt.getSession("modulos")).includes(
                              "5a53a086-e426-4a55-8e69-e1020957fe00"
                            ) ? (
                              <Separator />
                            ) : null}
                            {JSON.parse(Encrypt.getSession("modulos")).includes(
                              "5a53a086-e426-4a55-8e69-e1020957fe00"
                            ) ? (
                              <Item onClick={this.delete}>
                                <IconFont className="fa fa-trash" />
                                ELIMINAR
                              </Item>
                            ) : null}
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="supervisores_region"
                    title="Nombramientos de Supervisores de Región"
                  >
                    <div className="card-body">
                      <h3 className="box-title">
                        Formulario Nombramiento Supervisores de Región
                      </h3>
                      <hr className="mt-0 mb-4"></hr>
                      <div className="row">
                        <div className="col-lg-4 form-group">
                          <label htmlFor="">Tipo: (*)</label>

                          <Select
                            id="tipo"
                            name="tipo"
                            placeholder="Elija una opción"
                            value={this.state.tipo}
                            isClearable={true}
                            options={this.state.tipos}
                            isDisabled={this.state.disabled_select_tipo}
                            onChange={(e) => {
                              this.setState({
                                tipo: e,
                              });
                            }}
                          />
                          {this.validatorSupervisorRegion.message(
                            "tipo",
                            this.state.tipo,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorSupervisorRegion.message(
                                "tipo",
                                this.state.tipo,
                                "required"
                              )}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-4 form-group">
                          <label htmlFor="">Región: (*)</label>
                          <Select
                            id="region"
                            name="region"
                            placeholder="Elija una opción"
                            value={this.state.region}
                            isClearable={true}
                            options={this.state.regiones}
                            isDisabled={this.state.disabled_select_region}
                            onChange={(e) => {
                              this.setState({
                                region: e,
                              });
                            }}
                          />
                          {this.validatorSupervisorRegion.message(
                            "región",
                            this.state.region,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorSupervisorRegion.message(
                                "región",
                                this.state.region,
                                "required"
                              )}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-4 form-group">
                          <label htmlFor="">Ministro u Obispo: (*)</label>

                          <AsyncSelect
                            id="pastor"
                            name="pastor"
                            placeholder="Elija una opción"
                            value={this.state.pastor}
                            isClearable={true}
                            loadOptions={this.getPastoresMinistrosParam}
                            defaultOptions={this.state.pastores_ministros}
                            isDisabled={
                              this.state.disabled_select_pastor_ministros
                            }
                            onChange={(e) => {
                              this.setState({
                                pastor: e,
                              });
                            }}
                            noOptionsMessage={(e) => {
                              return "No existen datos";
                            }}
                          />
                          {this.validatorSupervisorRegion.message(
                            "Ministro u Obispo",
                            this.state.pastor,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorSupervisorRegion.message(
                                "Ministro u Obispo",
                                this.state.pastor,
                                "required"
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          <div className="card-footer">
                            <button
                              type="button"
                              className="btn btn-lg btn-info"
                              onClick={this.agregarNombramientoSupervisorRegion.bind(
                                this
                              )}
                            >
                              <i className="fa fa-plus"></i>Agregar
                            </button>
                          </div>
                        </div>
                      </div>
                      <h3 className="box-title">
                        Supervisores de Región Nombrados
                      </h3>
                      <hr className="mt-0 mb-4"></hr>
                      <div className="row">
                        <div className="col-12">
                          <TablaFilter
                            ref="tabla_supervisores_region"
                            ruta={`nombramientos_pastorales/detalle/region/all/${this.props.match.params.id}`}
                            rowEvents={this.rowEventsRegion}
                            identificador={"id"}
                            columns={this.columnsNombramientoRegion}
                            titulo_tabla="Escriba el registro que desea buscar"
                          />
                          <Menu id={"menu_no_vigente_region"}>
                            <Item onClick={this.deleteRegion}>
                              <IconFont className="fa fa-trash" />
                              ELIMINAR
                            </Item>
                          </Menu>
                          <Menu id={"menu_vigente_region"}>
                            <Item onClick={this.finalizarDetalleRegion}>
                              <IconFont className="fa fa-close" />
                              FINALIZAR
                            </Item>
                            {JSON.parse(Encrypt.getSession("modulos")).includes(
                              "5a53a086-e426-4a55-8e69-e1020957fe00"
                            ) ? (
                              <Separator />
                            ) : null}
                            {JSON.parse(Encrypt.getSession("modulos")).includes(
                              "5a53a086-e426-4a55-8e69-e1020957fe00"
                            ) ? (
                              <Item onClick={this.deleteRegion}>
                                <IconFont className="fa fa-trash" />
                                ELIMINAR
                              </Item>
                            ) : null}
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab
                    eventKey="supervisores_distrito"
                    title="Nombramientos de Supervisores de Distrito"
                  >
                    <div className="card-body">
                      <h3 className="box-title">
                        Formulario Nombramiento Supervisores de Distrito
                      </h3>
                      <hr className="mt-0 mb-4"></hr>
                      <div className="row">
                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Tipo: (*)</label>

                          <Select
                            id="tipo"
                            name="tipo"
                            placeholder="Elija una opción"
                            value={this.state.tipo}
                            isClearable={true}
                            options={this.state.tipos}
                            isDisabled={this.state.disabled_select_tipo}
                            onChange={(e) => {
                              this.setState({
                                tipo: e,
                              });
                            }}
                          />
                          {this.validatorSupervisorDistrito.message(
                            "tipo",
                            this.state.tipo,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorSupervisorDistrito.message(
                                "tipo",
                                this.state.tipo,
                                "required"
                              )}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Zona: (*)</label>

                          <AsyncSelect
                            id="zona"
                            name="zona"
                            placeholder="Elija una opción"
                            value={this.state.zona}
                            isClearable={true}
                            loadOptions={this.getZonasParam}
                            defaultOptions={this.state.zonas}
                            isDisabled={this.state.disabled_select_zona}
                            onChange={(e) => {
                              this.setState({
                                zona: e,
                                distrito: "",
                                distritos_nombramiento: [],

                                disabled_select_distrito: true,
                                disabled_select_iglesia: true,
                              });
                              this.refs.select_distrito.blur();
                              this.refs.select_iglesia.blur();

                              if (e !== null) {
                                this.setState({
                                  distritos_nombramiento:
                                    this.getDistritosNombramiento(e.codigo),
                                });
                              } else {
                                this.setState({
                                  distrito: "",
                                  distritos_nombramiento: [],

                                  disabled_select_distrito: true,
                                  disabled_select_iglesia: true,
                                });
                                this.refs.select_distrito.blur();
                                this.refs.select_iglesia.blur();
                              }
                            }}
                            noOptionsMessage={(e) => {
                              return "No existen datos";
                            }}
                          />
                          {this.validatorSupervisorDistrito.message(
                            "zona",
                            this.state.zona,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorSupervisorDistrito.message(
                                "zona",
                                this.state.zona,
                                "required"
                              )}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Distrito: (*)</label>

                          <AsyncSelect
                            id="distrito"
                            name="distrito"
                            placeholder="Elija una opción"
                            value={this.state.distrito}
                            isClearable={true}
                            loadOptions={this.getDistritosParamNombramiento}
                            defaultOptions={this.state.distritos_nombramiento}
                            isDisabled={this.state.disabled_select_distrito}
                            ref={"select_distrito"}
                            onChange={(e) => {
                              // this.handleFocusSelect(true)
                              this.setState({
                                distrito: e,

                                disabled_select_iglesia: true,
                              });
                              this.refs.select_iglesia.blur();
                            }}
                            noOptionsMessage={() => {
                              return "No existen datos";
                            }}
                          />
                          {this.validatorSupervisorDistrito.message(
                            "distrito",
                            this.state.distrito,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorSupervisorDistrito.message(
                                "distrito",
                                this.state.distrito,
                                "required"
                              )}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-3 form-group">
                          <label htmlFor="">Ministro u Obispo: (*)</label>

                          <AsyncSelect
                            id="pastor"
                            name="pastor"
                            placeholder="Elija una opción"
                            value={this.state.pastor}
                            isClearable={true}
                            loadOptions={this.getPastoresMinistrosParam}
                            defaultOptions={this.state.pastores_ministros}
                            isDisabled={
                              this.state.disabled_select_pastor_ministros
                            }
                            onChange={(e) => {
                              this.setState({
                                pastor: e,
                              });
                            }}
                            noOptionsMessage={(e) => {
                              return "No existen datos";
                            }}
                          />
                          {this.validatorSupervisorDistrito.message(
                            "Ministro u Obispo",
                            this.state.pastor,
                            "required"
                          ) && (
                            <span className="label label-light-danger">
                              {this.validatorSupervisorDistrito.message(
                                "Ministro u Obispo",
                                this.state.pastor,
                                "required"
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          <div className="card-footer">
                            <button
                              type="button"
                              className="btn btn-lg btn-info"
                              onClick={this.agregarNombramientoSupervisorDistrito.bind(
                                this
                              )}
                            >
                              <i className="fa fa-plus"></i>Agregar
                            </button>
                          </div>
                        </div>
                      </div>
                      <h3 className="box-title">
                        Supervisores de Distrito Nombrados
                      </h3>
                      <hr className="mt-0 mb-4"></hr>
                      <div className="row">
                        <div className="col-12">
                          <TablaFilter
                            ref="tabla_supervisores_distrito"
                            ruta={`nombramientos_pastorales/detalle/distrito/all/${this.props.match.params.id}`}
                            rowEvents={this.rowEventsDistrito}
                            identificador={"id"}
                            columns={this.columnsNombramientoDistrito}
                            titulo_tabla="Escriba el registro que desea buscar"
                          />
                          <Menu id={"menu_no_vigente_distrito"}>
                            <Item onClick={this.deleteDistrito}>
                              <IconFont className="fa fa-trash" />
                              ELIMINAR
                            </Item>
                          </Menu>
                          <Menu id={"menu_vigente_distrito"}>
                            <Item onClick={this.finalizarDetalleDistrito}>
                              <IconFont className="fa fa-close" />
                              FINALIZAR
                            </Item>
                            {JSON.parse(Encrypt.getSession("modulos")).includes(
                              "5a53a086-e426-4a55-8e69-e1020957fe00"
                            ) ? (
                              <Separator />
                            ) : null}
                            {JSON.parse(Encrypt.getSession("modulos")).includes(
                              "5a53a086-e426-4a55-8e69-e1020957fe00"
                            ) ? (
                              <Item onClick={this.deleteDistrito}>
                                <IconFont className="fa fa-trash" />
                                ELIMINAR
                              </Item>
                            ) : null}
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </LayoutPanelFormulario>

        {/*MODAL PARA IGLESIA */}
        <Modal
          size="lg"
          style={{ maxWidth: "1800px", width: "80%" }}
          isOpen={this.state.modal_iglesia}
          returnFocusAfterClose={false}
        >
          <ModalHeader toggle={this.ActionModalIglesias.bind(this, false)}>
            Visor Iglesias
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <TablaFilter
                  ref="tablaIglesias"
                  ruta={`iglesias/select_nombramiento/visor/${this.props.match.params.id}`}
                  rowEvents={this.rowEventsIglesias}
                  identificador={"id"}
                  columns={this.columnsVisorIglesias}
                  titulo_tabla="Escriba el registro que desea buscar"
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="text-right"></ModalFooter>
        </Modal>
        {/*FIN MODAL */}
      </React.Fragment>
    );
  }
}
