import React, { Component } from "react";
import Swal from "sweetalert2";
import { Fragment } from "react";
import { Redirect } from "react-router-dom";
import LayoutPanelTable from "../../../components/layouts/panels/LayoutPanelTable";
import DefaultImage from "../../../components/utils/images/default.png";
import HTTP from "../../../helpers/HTTP";
import Alerts from "../../../services/Alerts";
import Request from "../../../services/Request";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ExtFile from "../../../services/ExtFile";
import es from "../../../helpers/ValidatorTranslate_es";
import SimpleReactValidator from "simple-react-validator";
import Encrypt from "../../../services/Encrypt";
import ContentLoader from "react-content-loader";
class UsuarioPerfil extends Component {
  constructor(props) {
    super(props);
    SimpleReactValidator.addLocale("esp", es);
    this.validator = new SimpleReactValidator({
      locale: "esp",
      element: (message) => (
        <span className="label label-light-danger">{message}</span>
      ),
    });
    this.passwordValidator = new SimpleReactValidator({
      locale: "esp",
      element: (message) => (
        <span className="label label-light-danger">{message}</span>
      ),
    });
    this.toggleImageForm = this.toggleImageForm.bind(this);
    this.state = {
      profilePicture: DefaultImage,
      alias: "",
      redirect: false,
      modalImageIsOpen: false,
      fotografia: null,
      dataLoaded: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.getUsuarioData();
  }
  getImagen() {
    Request.GET_IMAGE("usuarios/profile", Encrypt.getSession("usuario")).then(
      (result) => {
        this.setState({ dataLoaded: true });
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], {
                type: result.headers["content-type"],
              }),
              url = window.URL.createObjectURL(blob);

            this.setState({ profilePicture: url });
          }
        }
      }
    );
  }
  handleInputChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    this.setState({ [idComponente]: valorComponente });
  };
  getUsuarioData() {
    HTTP.findById(Encrypt.getSession("usuario"), "usuarios").then(
      async (result) => {
        if (result !== false) {
          this.setState({
            nombre: result.persona.label,
            codigoPersona: result.persona.codigo,
            iglesia: result.iglesia,
            alias: result.alias,
            correo: result.correo_electronico,
            rol: result.rol.label,
          });
          this.getImagen();
        } else {
        }
      }
    );
  }
  updateAlias = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      Alerts.QuestionYesNo(
        "¿Esta seguro de cambiar el alias de su usuario?",
        "Administración de usuarios",
        "question"
      ).then((resp) => {
        if (resp) {
          this.setState({ loading: true });
          const data = {
            code: Encrypt.getSession("usuario"),
            alias: this.state.alias,
          };
          HTTP.update(data, "usuario", "usuarios", "usuarios/alias")
            .then((result) => {
              if (result !== false) {
                Encrypt.setSession("alias", this.state.alias);
              }
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  updatePassword = async (e) => {
    e.preventDefault();
    if (this.passwordValidator.allValid()) {
      Alerts.QuestionYesNo(
        "¿Esta seguro de cambiar la contraseña de su usuario?",
        "Administración de usuarios",
        "question"
      ).then((resp) => {
        if (resp) {
          this.setState({ loading: true });
          const data = {
            newPassword: this.state.nuevaPass1,
            oldPassword: this.state.antiguaPass,
          };
          Request.PUT("usuarios/password", data, "")
            .then((result) => {
              if (result !== false) {
                if (result.status === 200 || result.status === 201) {
                  Alerts.alertSuccessUpdate("usuario", "usuarios");
                } else if (result.status === 403) {
                  Swal.fire({
                    icon: "error",
                    title: "Contraseña incorrecta",
                    text: "La contraseña actual que ingresó es incorrecta",
                    confirmButtonText: '<i class="fa fa-check"></i> Aceptar',
                    showCloseButton: true,
                    focusConfirm: true,
                    timer: 3000,
                    footer: process.env.REACT_APP_FOOTER_ALERTAS,
                  });
                } else {
                  Alerts.alertErrorUpdate("usuarios", "usuarios");
                }
              } else {
                resp = false;
              }
            })
            .finally(this.setState({ loading: false }));
        }
      });
    } else {
      this.passwordValidator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <Fragment>
        <LayoutPanelTable
          titulo="Perfil de usuario "
          titulo_panel="Información"
          buttons={
            <button
              type="button"
              className="btn btn-outline-inverse mr-2 "
              onClick={() => {
                this.setState({ redirect: true });
              }}
            >
              <i className="fa fa-arrow-left mr-2"></i>Salir
            </button>
          }
        >
          {/* <!-- Row --> */}
          <div className="row">
            {/* <!-- Column --> */}
            <div className="col-lg-4 col-xlg-3 col-md-5">
              {this.state.dataLoaded === true ? (
                <div className="card">
                  <div className="card-body">
                    <center className="m-t-30">
                      {" "}
                      <img
                        src={this.state.profilePicture}
                        className="img-circle"
                        width="150"
                        alt="Foto de perfil"
                      />
                      <h4 className="card-title m-t-10">{this.state.nombre}</h4>
                    </center>
                  </div>
                  <div>
                    <hr />
                  </div>
                  <div className="card-body">
                    <small className="text-muted p-t-30 db">Rol</small>
                    <h6>
                      {this.state.rol === null || this.state.rol === "" ? (
                        <span className="label label-light-warning ">
                          No asignado
                        </span>
                      ) : (
                        this.state.rol
                      )}
                    </h6>

                    <small className="text-muted p-t-30 db">Alias</small>
                    <h6>
                      {this.state.alias === null || this.state.alias === "" ? (
                        <span className="label label-light-warning ">
                          No asignado
                        </span>
                      ) : (
                        this.state.alias
                      )}
                    </h6>
                    <small className="text-muted p-t-30 db">
                      Correo electrónico
                    </small>
                    <h6>
                      {this.state.correo === null ||
                      this.state.correo === "" ? (
                        <span className="label label-light-warning ">
                          No asignado
                        </span>
                      ) : (
                        this.state.correo
                      )}
                    </h6>
                  </div>
                </div>
              ) : (
                this.loadingDataSide()
              )}
            </div>
            {/*   <!-- Column --> */}
            {/*<!-- Column --> */}

            <div className="col-lg-8 col-xlg-9 col-md-7">
              <div className="row">
                {this.state.dataLoaded === true
                  ? this.UICardActualizarAlias()
                  : this.loadingData()}
                {this.state.dataLoaded === true
                  ? this.UICambiarFoto()
                  : this.loadingData()}
                {this.state.dataLoaded === true
                  ? this.UICardActualizarPassword()
                  : this.loadingData()}
              </div>
            </div>
            {/* <!-- Column --> */}
          </div>
          {/* <!-- Row --> */}
        </LayoutPanelTable>
        {this.state.modalImageIsOpen && this.UIChangeFotoModal()}
      </Fragment>
    );
  }

  UICambiarFoto() {
    return (
      <Fragment>
        <div className="col-md-12">
          <div className="card card-outline-info">
            <div className="card-header">
              <h4 className="m-b-0 text-white">Actualizar foto de perfil</h4>
            </div>
            <div className="card-body">
              <p className="card-text">Cambiar la foto de perfil de usuario.</p>
              <button
                disabled={this.state.loading}
                onClick={this.toggleImageForm}
                className="btn btn-outline-info"
              >
                <i className="fa fa-edit mr-2"></i>
                Actualizar
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  UICardActualizarAlias() {
    return (
      <Fragment>
        <div className="col-md-12">
          <div className="card card-outline-info">
            <div className="card-header">
              <h4 className="m-b-0 text-white">Cambiar Alias</h4>
            </div>
            <div className="card-body">
              <div className="form-body">
                <div className="col-lg-6 form-group">
                  <label htmlFor="">Alias</label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="alias"
                    id="alias"
                    value={this.state.alias}
                    onChange={this.handleInputChange}
                  />
                  {this.validator.message(
                    "Alias",
                    this.state.alias,
                    "required"
                  )}
                </div>
                <div className=" form-group">
                  <button
                    disabled={this.state.loading}
                    onClick={this.updateAlias.bind(this)}
                    className="btn btn-outline-info"
                  >
                    <i className="fa fa-edit mr-2"></i>
                    Cambiar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  UICardActualizarPassword() {
    return (
      <Fragment>
        <div className="col-md-12">
          <div className="card card-outline-info">
            <div className="card-header">
              <h4 className="m-b-0 text-white">Cambiar contraseña</h4>
            </div>
            <div className="card-body">
              <div className="form-body">
                <div className="col-lg-6 form-group">
                  <label htmlFor="">Contraseña actual</label>

                  <input
                    type="password"
                    className="form-control"
                    placeholder="contraseña actual"
                    id="antiguaPass"
                    value={this.state.antiguaPass}
                    onChange={this.handleInputChange}
                  />
                  {this.passwordValidator.message(
                    "Contraseña actual",
                    this.state.antiguaPass,
                    "required"
                  )}
                </div>
                <div className="col-lg-6 form-group">
                  <label htmlFor="">Contraseña nueva</label>

                  <input
                    type="password"
                    className="form-control"
                    placeholder="contraseña nueva"
                    id="nuevaPass1"
                    value={this.state.nuevaPass1}
                    onChange={this.handleInputChange}
                  />
                  {this.passwordValidator.message(
                    "Contraseña nueva",
                    this.state.nuevaPass1,
                    "required|min:6",
                    { messages: { min: "Debe tener al menos 6 caracteres" } }
                  )}
                </div>
                <div className="col-lg-6 form-group">
                  <label htmlFor="">Confirma contraseña nueva</label>

                  <input
                    type="password"
                    className="form-control"
                    placeholder="contraseña nueva"
                    id="nuevaPass2"
                    value={this.state.nuevaPass2}
                    onChange={this.handleInputChange}
                  />
                  {this.passwordValidator.message(
                    "Confirma contraseña nueva",
                    this.state.nuevaPass2,
                    `required|in:${this.state.nuevaPass1}|min:6`,
                    {
                      messages: {
                        in: "Las contraseñas no coinciden",
                        min: "Debe tener al menos 6 caracteres",
                      },
                    }
                  )}
                </div>

                <div className=" form-group">
                  <button
                    disabled={this.state.loading}
                    onClick={this.updatePassword.bind(this)}
                    className="btn btn-outline-info"
                  >
                    <i className="fa fa-edit mr-2"></i>
                    Cambiar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  UIChangeFotoModal() {
    return (
      <Modal
        size="lg"
        style={{ maxWidth: "80%", width: "50%" }}
        isOpen={this.state.modalImageIsOpen}
        centered={true}
      >
        <ModalHeader toggle={this.toggleImageForm}>
          Cambiar foto de perfil
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label for="fotoPerfil">Nueva foto de perfil</label>
              <input
                type="file"
                accept=".png,.jpeg,.jpg"
                className="form-control"
                placeholder="Imagen"
                id="fotoPerfil"
                name="fotoPerfil"
                onChange={this.handleFileChange}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="text-right">
          <button
            type="button"
            className="btn btn-info"
            onClick={this.changeFoto}
            disabled={this.state.loading || this.state.fotografia === null}
          >
            <i className="fa fa-edit mr-2"></i>
            {this.state.loading ? "GUARDANDO" : "GUARDAR"}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
  toggleImageForm() {
    this.setState((prevState) => ({
      modalImageIsOpen: !prevState.modalImageIsOpen,
      fotografia: null,
    }));
  }
  save = (keyElemento, valorElemento) => {
    this.setState({ [keyElemento]: valorElemento });
  };
  handleFileChange = (e) => {
    // const idComponente = e.target.id;
    if (e.target.files[0] !== undefined) {
      let name = e.target.files[0].name;

      if (ExtFile.isImage(name)) {
        this.save("fotografia", e.target.files[0]);
      } else {
        Alerts.alertEmpty(
          "¡El archivo seleccionado no es válido!",
          "Solo se permiten archivos PNG, JPG, JPEG",
          "warning"
        );
        e.target.value = null;
        this.save("fotografia", null);
      }
    } else {
      this.save("fotografia", null);
    }
  };
  changeFoto = async (e) => {
    Alerts.QuestionYesNo(
      "¿Esta seguro de cambiar la foto de su usuario?",
      "Administración de usuarios",
      "question"
    ).then((resp) => {
      if (resp) {
        this.setState({ loading: true });
        const data = new FormData();
        data.append("fotoPerfil", this.state.fotografia);
        //Añadimos el id de la persona
        data.append("userId", Encrypt.getSession("codigo_persona"));
        HTTP.create(data, "usuario", "usuarios", "personas/avatar").finally(
          () => {
            this.setState({ loading: false });
            this.toggleImageForm();
            this.getImagen();
          }
        );
      }
    });
  };
  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={200} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="700" />
        </ContentLoader>
      </React.Fragment>
    );
  }
  loadingDataSide() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={600} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="700" />
        </ContentLoader>
      </React.Fragment>
    );
  }
}

export default UsuarioPerfil;
