import React, { Component } from "react";
import LayoutPanelTable from "../../../components/layouts/panels/LayoutPanelTable";
import { Tabs, Tab } from "react-bootstrap";
import LayoutPanelEmpty from "../../../components/layouts/panels/LayoutPanelEmpty";
import Request from "../../../services/Request";
import HTTP from "../../../helpers/HTTP";
import Alerts from "../../../services/Alerts";
import ContentLoader from "react-content-loader";
import Encrypt from "../../../services/Encrypt";
import { saveAs } from "file-saver";
import Iframe from "react-iframe";

export default class NombramientoPastores extends Component {
  constructor(props) {
    super(props);
    this.state = this.inicial_state;
  }

  inicial_state = {
    tab_active: "nombramiento_activo",
    nombramientos_no_vigentes: [],
    nombramiento_vigente: [],
    nombramiento_vigente_cargado: false,
    nombramientos_no_vigentes_cargados: false,
    archivo_informe:null
  };
  async handleChangeTab(key) {
    await this.setState({ tab_active: key });

    if (key === "nombramiento_activo") {
      this.getNombramientoVigente();
    } else if (key === "nombramientos_pendientes") {
      this.getNombramientosNoVigentes();
    }
  }
  componentDidMount() {
    this.getNombramientosNoVigentes();
    this.getNombramientoVigente();
    Encrypt.destroyItemSession("nombramiento_gestion");
  }

  actualizar(id) {
    this.props.history.push(`/nombramientos/pastorales/update/${id}`);
  }

  asignados(id) {
    this.props.history.push(
      `/informes_mensuales/gestiones_entrega/asignados/${id}`
    );
  }

  PastoresNombrados(id) {
    Encrypt.setSession("nombramiento_gestion", id);
    this.props.history.push(`/nombramientos/pastorales/gestion/${id}`);
  }
  getNombramientosNoVigentes() {
    this.setState({
      nombramientos_no_vigentes_cargados: false,
    });

    HTTP.findAll("nombramientos_pastorales/header/all/1").then((result) => {
      if (result !== false) {
        this.setState({
          nombramientos_no_vigentes: result,
          nombramientos_no_vigentes_cargados: true,
        });
      }
    });
  }
  getNombramientoVigente() {
    this.setState({
      nombramiento_vigente_cargado: false,
    });
    HTTP.findAll("nombramientos_pastorales/header/all/2").then((result) => {
      if (result !== false) {
        this.setState({
          nombramiento_vigente: result,
          nombramiento_vigente_cargado: true,
        });
      }
    });
  }
  delete(codigo) {
    const data = {
      code: codigo,
    };
    HTTP.delete_disable(
      data,
      "nombramiento pastoral",
      "nombramientos pastorales",
      "nombramientos_pastorales/header"
    ).then((result) => {
      if (result !== false) {
        this.getNombramientosNoVigentes();
      }
    });
  }
  Publicar(codigo) {
    Alerts.QuestionYesNo(
      "¿Está seguro que desea publicar el nombramiento?",
      "Administración de Nombramientos Pastorales",
      "question"
    ).then((resp) => {
      if (resp) {
        const data = {
          code: codigo,
        };
        Alerts.loading_reload(true);
        Request.PUT("nombramientos_pastorales/procesar", data).then(
          (result) => {
            Alerts.loading_reload(false);
            if (result !== false) {
              if (result.status === 200) {
                Alerts.alertEmpty(
                  "Nombramiento publicado con éxito",
                  "Administración de Nombramientos Pastorales",
                  "success"
                );
                this.getNombramientosNoVigentes();
                this.getNombramientoVigente();
                this.setState({
                  tab_active: "nombramiento_activo",
                });
              } else if (result.status === 409) {
                Alerts.alertEmpty(
                  "Ya existe un nombramiento vigente",
                  "Administración de Nombramientos Pastorales",
                  "error"
                );
              } else {
                Alerts.alertEmpty(
                  "¡No se pudo publicar!",
                  "Administración de Nombramientos Pastorales",
                  "error"
                );
              }
            } else {
              Alerts.alertEmpty(
                "¡No se pudo publicar",
                "Administración de Nombramientos Pastorales",
                "error"
              );
            }
          }
        );
      }
    });
  }
  finalizar(codigo) {
    Alerts.QuestionYesNo(
      "¿Está seguro que desea finalizar el nombramiento?",
      "Administración de Nombramientos Pastorales",
      "question"
    ).then((resp) => {
      if (resp) {
        const data = {
          code: codigo,
        };
        Alerts.loading_reload(true);
        Request.PUT("nombramientos_pastorales/finalizar", data).then(
          (result) => {
            Alerts.loading_reload(false);
            if (result !== false) {
              if (result.status === 200) {
                Alerts.alertEmpty(
                  "¡Nombramiento finalizado con éxito",
                  "Administración de Nombramientos Pastorales",
                  "success"
                );

                this.getNombramientoVigente();
                this.setState({
                  tab_active: "nombramiento_activo",
                });
              } else {
                Alerts.alertEmpty(
                  "¡No se pudo finalizar",
                  "Administración de Nombramientos Pastorales",
                  "error"
                );
              }
            } else {
              Alerts.alertEmpty(
                "¡No se pudo finalizar",
                "Administración de Nombramientos Pastorales",
                "error"
              );
            }
          }
        );
      }
    });
  }
  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={400} width={"100%"}>
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="400" />
        </ContentLoader>
      </React.Fragment>
    );
  }
  descargarInformeNombramientosPastorales(codigo_nombramiento) {
    Alerts.loading_reload(true);
    Request.GET_PDF_FROM_EXTERNAL(`nombramiento/${codigo_nombramiento}`, "").then(
      async (result) => {
        Alerts.loading_reload(false);
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);

            await this.setState({
              archivo_informe: url});
            saveAs(
              this.state.archivo_informe,
              `NOMBRAMIENTOS_PASTORALES_${codigo_nombramiento.toUpperCase()}.pdf`
            );
          }
        }
      }
    );
  }
  imprimirInformeNombramientosPastorales(codigo_miembro) {
    Alerts.loading_reload(true);
    Request.GET_PDF_FROM_EXTERNAL(`nombramiento/${codigo_miembro}`, "").then(
      async (result) => {
        Alerts.loading_reload(false);
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);

            await this.setState({
              archivo_informe: url});
              window.setTimeout(() => {
                document.querySelector("#archivo_informe").contentWindow.print();
              }, 500);
          }
        }
      }
    );
   
  }
  descargarInformeNombramientosSupervisores(codigo_nombramiento) {
    Alerts.loading_reload(true);
    Request.GET_PDF_FROM_EXTERNAL(`nombramiento/supervisores/${codigo_nombramiento}`, "").then(
      async (result) => {
        Alerts.loading_reload(false);
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);

            await this.setState({
              archivo_informe: url});
            saveAs(
              this.state.archivo_informe,
              `NOMBRAMIENTOS_SUPERVISORES_${codigo_nombramiento.toUpperCase()}.pdf`
            );
          }
        }
      }
    );
  }
  imprimirInformeNombramientosSupervisores(codigo_miembro) {
    Alerts.loading_reload(true);
    Request.GET_PDF_FROM_EXTERNAL(`nombramiento/supervisores/${codigo_miembro}`, "").then(
      async (result) => {
        Alerts.loading_reload(false);
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);

            await this.setState({
              archivo_informe: url});
              window.setTimeout(() => {
                document.querySelector("#archivo_informe").contentWindow.print();
              }, 500);
          }
        }
      }
    );
   
  }
  render() {
    return (
      <React.Fragment>
        <LayoutPanelTable
          titulo="Nombramientos de Pastores y Ministros"
          rutacreate="/nombramientos/pastorales/create"
        >
          <div className="form-body">
            <div className="row">
              <div className="col-12 form-group ">
                <Tabs
                  activeKey={this.state.tab_active}
                  onSelect={this.handleChangeTab.bind(this)}
                  id="tabas"
                >
                  <Tab
                    eventKey="nombramiento_activo"
                    title="Nombramiento Vigente"
                  >
                    <div className="row">
                      {this.state.nombramiento_vigente_cargado === true ? (
                        <div className="col-lg-12 mt-4 ">
                          {this.state.nombramiento_vigente.length > 0 ? (
                            this.state.nombramiento_vigente.map((element) => {
                              return (
                                <LayoutPanelEmpty
                                  titulo_panel={`Nombramiento Pastoral Código: ${element.codigo}`}
                                  key={element.id}
                                  buttons={
                                    <React.Fragment>
                                      <button
                                        onClick={this.PastoresNombrados.bind(
                                          this,
                                          element.codigo
                                        )}
                                        className="btn btn-outline-info mr-2"
                                      >
                                        <i className="fa fa-file-text-o mr-2"></i>
                                        Detalles Nombramiento Pastoral
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-outline-inverse"
                                        onClick={this.finalizar.bind(
                                          this,
                                          element.codigo
                                        )}
                                      >
                                        <i className="fa fa-close"></i>{" "}
                                        Finalizar
                                      </button>
                                    </React.Fragment>
                                  }
                                >
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="table-responsive">
                                        <table className="table table-bordered table-bordered-table color-bordered-table muted-bordered-table">
                                          <thead>
                                            <tr>
                                              <th className="text-center">
                                                Descripción
                                              </th>

                                              <th className="text-center">
                                                Fecha Inicio
                                              </th>
                                              <th className="text-center">
                                                Fecha Fin
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                {element.descripcion.toUpperCase()}
                                              </td>

                                              <td>{element.fecha_inicio}</td>
                                              <td>{element.fecha_fin}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>

                                  <h3 className="box-title">
                                    Panel de Impresión de Informes
                                  </h3>
                                  <hr className="mt-0 mb-4"></hr>
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="table-responsive">
                                        <table className="table table-bordered table-bordered-table color-bordered-table muted-bordered-table">
                                          <thead>
                                            <tr>
                                              <th className="text-center">
                                                INFORMES
                                              </th>

                                              <th className="text-center">
                                                OPCIONES
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                INFORME DE NOMBRAMIENTOS
                                                PASTORALES
                                              </td>

                                              <td className="text-center">
                                                <button
                                                  type="button"
                                                  className="btn  btn-outline-info mr-2"
                                                  onClick={this.descargarInformeNombramientosPastorales.bind(this,element.codigo)}
                                                >
                                                  <i className="fa fa-download mr-2"></i>
                                                  Descagar
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn  btn-outline-info mr-2"
                                                  onClick={this.imprimirInformeNombramientosPastorales.bind(this,element.codigo)}
                                                >
                                                  <i className="fa fa-print mr-2"></i>
                                                  Imprimir
                                                </button>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                INFORME DE SUPERVISORES
                                              </td>

                                              <td className="text-center">
                                                <button
                                                  type="button"
                                                  className="btn  btn-outline-info mr-2"
                                                  onClick={this.descargarInformeNombramientosSupervisores.bind(this,element.codigo)}
                                                >
                                                  <i className="fa fa-download mr-2"></i>
                                                  Descagar
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn  btn-outline-info mr-2"
                                                  onClick={this.imprimirInformeNombramientosSupervisores.bind(this,element.codigo)}
                                                >
                                                  <i className="fa fa-print mr-2"></i>
                                                  Imprimir
                                                </button>
                                              </td>
                                            </tr>
                                            
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </LayoutPanelEmpty>
                              );
                            })
                          ) : (
                            <div className="col-lg-12 ">
                              <LayoutPanelEmpty titulo_panel="Administración de Nombramientos Pastorales">
                                <div className="row">
                                  <div className="col-12 text-center">
                                    <h3>NO HAY NOMBRAMIENTO VIGENTE</h3>
                                  </div>
                                </div>
                              </LayoutPanelEmpty>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="col-12">{this.loadingData()}</div>
                      )}
                    </div>
                  </Tab>
                  <Tab
                    eventKey="nombramientos_pendientes"
                    title="Nombramientos en configuración"
                  >
                    <div className="row">
                      {this.state.nombramientos_no_vigentes_cargados ===
                      true ? (
                        <div className="col-lg-12 mt-4 ">
                          {this.state.nombramientos_no_vigentes.length > 0 ? (
                            this.state.nombramientos_no_vigentes.map(
                              (element) => {
                                return (
                                  <LayoutPanelEmpty
                                    key={element.id}
                                    titulo_panel={`Nombramiento Pastoral Código: ${element.codigo}`}
                                    buttons={
                                      <React.Fragment>
                                        <button
                                          onClick={this.actualizar.bind(
                                            this,
                                            element.codigo
                                          )}
                                          className="btn btn-outline-info mr-2"
                                        >
                                          <i className="fa fa-pencil mr-2"></i>
                                          Actualizar
                                        </button>
                                        <button
                                          onClick={this.PastoresNombrados.bind(
                                            this,
                                            element.codigo
                                          )}
                                          className="btn btn-outline-info mr-2"
                                        >
                                          <i className="fa fa-file-text-o mr-2"></i>
                                          Pastores Nombrados
                                        </button>
                                        <button
                                          type="button"
                                          onClick={this.Publicar.bind(
                                            this,
                                            element.codigo
                                          )}
                                          className="btn btn-outline-info mr-2"
                                        >
                                          <i className="fa fa-check mr-2"></i>
                                          Procesar
                                        </button>
                                        <button
                                          type="button"
                                          onClick={this.delete.bind(
                                            this,
                                            element.codigo
                                          )}
                                          className="btn btn-outline-danger"
                                        >
                                          <i className="fa fa-pencil mr-2"></i>
                                          Eliminar
                                        </button>
                                      </React.Fragment>
                                    }
                                  >
                                    <div className="row">
                                      <div className="col-lg-12 ">
                                        <div className="table-responsive">
                                          <table className="table table-bordered table-bordered-table color-bordered-table muted-bordered-table">
                                            <thead>
                                              <tr>
                                                <th className="text-center">
                                                  Descripción
                                                </th>

                                                <th className="text-center">
                                                  Fecha Inicio
                                                </th>
                                                <th className="text-center">
                                                  Fecha Fin
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {element.descripcion.toUpperCase()}
                                                </td>

                                                <td>{element.fecha_inicio}</td>
                                                <td>{element.fecha_fin}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </LayoutPanelEmpty>
                                );
                              }
                            )
                          ) : (
                            <div className="col-lg-12 ">
                              <LayoutPanelEmpty titulo_panel="Administración de Nombramiento Pastorales">
                                <div className="row">
                                  <div className="col-12 text-center">
                                    <h3>NO HAY NOMBRAMIENTOS CREADOS</h3>
                                  </div>
                                </div>
                              </LayoutPanelEmpty>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="col-12">{this.loadingData()}</div>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </LayoutPanelTable>
        {this.state.archivo_informe !== null ? (
          <Iframe
            id="archivo_informe"
            url={`${this.state.archivo_informe}#toolbar=0&navpanes=0&statusbar=0`}
            width="90%"
            height="550px"
            display="none"
            position="relative"
          />
        ) : null}
      </React.Fragment>
    );
  }
}
