import React, { Component } from "react";
import LayoutPanelTable from "../../../../../components/layouts/panels/LayoutPanelTable";
import HTTP from "../../../../../helpers/HTTP";
import Encrypt from "../../../../../services/Encrypt";
import TablaFilter from "../../../../../components/tablas/TablaFilter";
import Alerts from "../../../../../services/Alerts";
import Request from "../../../../../services/Request";
import { saveAs } from "file-saver";
import Iframe from "react-iframe";
import ContentLoader from "react-content-loader";
import { Redirect } from "react-router-dom";
export default class HistoricoRecepcionInformesGestionesDetalle extends Component {
  constructor(props) {
    super(props);
    this.state = this.inicial_state;
    this.state.usuario = Encrypt.getSession("codigo_persona");
  }

  inicial_state = {
    usuario: "",
    tab_active: "",
    gestiones: [],
    cargadas: false,
    archivo_informe: null,
    gestion_cargadas: false,
    redirect: false,
  };
  componentDidMount() {
    this.getGestion();
  }

  descargarInforme(id_informe, informe_nombre) {
    Alerts.loading_reload(true);

    Request.GET_PDF(
      `gestion_historico/pdf/informes/unido/${this.state.gestiones.codigo}/${id_informe}`,
      ""
    ).then(async (result) => {
      Alerts.loading_reload(false);
      if (result !== false) {
        if (result.status === 200) {
          let blob = new Blob([result.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);

          await this.setState({
            archivo_informe: url,
            nombre_informe_descarga: `${
              this.state.gestiones.codigo
            }-${informe_nombre.toUpperCase()}`,
          });
          saveAs(
            this.state.archivo_informe,
            `${this.state.nombre_informe_descarga}.pdf`
          );
        }
      }
    });
  }
  imprimirInforme(id_informe) {
    Alerts.loading_reload(true);
    Request.GET_PDF(
      `gestion_historico/pdf/informes/unido/${this.state.gestiones.codigo}/${id_informe}`
    ).then(async (result) => {
      Alerts.loading_reload(false);
      if (result !== false) {
        if (result.status === 200) {
          let blob = new Blob([result.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);

          await this.setState({
            archivo_informe: url,
          });
          window.setTimeout(() => {
            document.querySelector("#archivo_informe").contentWindow.print();
          }, 500);
        }
      }
    });
  }

  async handleChangeTab(key) {
    await this.setState({ tab_active: key });

    this.refs[key].clear();
  }
  getGestion() {
    HTTP.findById(
      this.props.match.params.gestion,
      "gestiones_historico/codigo"
    ).then((result) => {
      if (result !== false) {
        this.setState({
          gestiones: result,
          cargadas: true,
          gestion_cargadas: true,
        });
      } else {
        this.setState({ redirect: true });
      }
    });
  }
  columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "informe_id",
      text: "id",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="text-center">{cellContent}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "recibidos",
      text: "id",
      hidden: true,
    },
    {
      dataField: "identificador",
      text: "id",
      hidden: true,
    },
    {
      dataField: "informe_nombre",
      text: "Nombre Informe",

      formatter: (cellContent, row) => {
        return <p className="ml-4">{cellContent.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "mes_nombre",
      text: "Mes",

      formatter: (cellContent, row) => {
        return <p className="ml-4">{cellContent.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "8%", textAlign: "center" };
      },
    },

    {
      dataField: "total",
      text: "Iglesias Reportadas",

      formatter: (cellContent, row) => {
        if (parseInt(row.recibidos) === parseInt(cellContent)) {
          return (
            <div className="text-center">
              <span className="label label-light-success font-16">
                {row.recibidos}/{cellContent}
              </span>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <span className="label label-light-danger font-16">
                {row.recibidos}/{cellContent}
              </span>
            </div>
          );
        }
      },
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
    },
    {
      dataField: "fecha_agregacion",
      text: "Fecha Asignación",
      hidden: true,
      formatter: (cellContent, row) => {
        return <p className="ml-4">{cellContent.toUpperCase()}</p>;
      },
      headerStyle: () => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "Opciones",
      text: "Opciones",

      formatter: (cellContent, row) => {
        if (parseInt(row.recibidos) > 0) {
          return (
            <div className="text-center">
              <button
                type="button"
                className="btn btn-outline-info mr-2"
                onClick={this.descargarInforme.bind(
                  this,
                  row.informe_id,
                  row.informe_nombre
                )}
              >
                <i className="fa fa-download mr-2"></i>Descargar Todo
              </button>
              <button
                type="button"
                className="btn btn-outline-info mr-2"
                onClick={this.imprimirInforme.bind(this, row.informe_id)}
              >
                <i className="fa fa-print mr-2"></i>Imprimir Todo
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary mr-2"
                onClick={() => {
                  this.props.history.push(
                    `/historico/recepcion_informes/iglesia/detalle_informe_recibido/${this.state.gestiones.codigo}/${row.informe_id}`
                  );
                }}
              >
                <i className="fa fa-clipboard mr-2"></i>Detalles
              </button>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <button
                type="button"
                className="btn btn-outline-secondary mr-2"
                onClick={() => {
                  this.props.history.push(
                    `/historico/recepcion_informes/iglesia/detalle_informe_recibido/${this.state.gestiones.codigo}/${row.informe_id}`
                  );
                }}
              >
                <i className="fa fa-clipboard mr-2"></i>Detalles
              </button>
            </div>
          );
        }
      },
      headerStyle: () => {
        return { width: "25%", textAlign: "center" };
      },
    },
  ];

  loadingData() {
    return (
      <React.Fragment>
        <ContentLoader viewBox="0 0 100% 650" height={300} width={"100%"}>
          <rect x="0" y="42" rx="15" ry="5" width="100%" height="300" />
        </ContentLoader>
      </React.Fragment>
    );
  }
  descargarInformeComprobantes(codigo) {
    Alerts.loading_reload(true);
    Request.GET_PDF_FROM_EXTERNAL(`historico_gestiones/${codigo}/comprobantes`, "").then(
      async (result) => {
        Alerts.loading_reload(false);
        if (result !== false) {
          if (result.status === 200) {
            let blob = new Blob([result.data], { type: "application/pdf" }),
              url = window.URL.createObjectURL(blob);

            await this.setState({
              archivo_informe: url,
            });
            saveAs(
              this.state.archivo_informe,
              `HISTORICO_COMPROBANTES_${codigo.toUpperCase()}.pdf`
            );
          } else if (result.status === 404) {
            Alerts.alertEmptyResponse(
              "No se puede descargar el archivo",
              "No hay archivos de comprobantes registrados",
              "error"
            );
          }
        }
      }
    );
  }
  enviarRecodatorios(codigo, nombre, fecha) {
    Alerts.QuestionYesNo(
      "¿Desea enviar recordatorios?",
      "¡A los administradores de las Iglesia que no han enviado informes para la gestión seleccionada se les enviará un correo solicitando el envío!",
      "question"
    ).then((resp) => {
      if (resp) {
        const data = {
          codigoGestion: codigo,
          nombreGestion: nombre,
          fechaFin: fecha,
        };
        Alerts.loading_reload_text(true, "Enviando correos");
        Request.POST("gestiones/recordatorio", data).then((result) => {
          Alerts.loading_reload_text(false, "Enviando correos");

          if (result !== false) {
            if (result.status === 200) {
              Alerts.alertEmpty(
                "¡Recordatorios enviados con éxito!",
                "Administración de informes",
                "success"
              );
            }
          }
        });
      }
    });
  }
  render() {
    if (this.state.redirect === true) {
      return <Redirect to={`/historico/recepcion_informes/iglesia`} />;
    }
    return (
      <div>
        <LayoutPanelTable
          titulo="Histórico de Recepción de informes de Iglesias Locales"
          titulo_panel="Gestiones recibida"
          buttons={
            <button
              type="button"
              className="btn btn-secondary btn-lg mr-2"
              onClick={() => {
                this.setState({ redirect: true });
              }}
            >
              <i className="fa fa-arrow-left mr-2"></i>Salir
            </button>
          }
        >
          {this.state.cargadas === true ? (
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table table-bordered table-bordered-table color-bordered-table muted-bordered-table">
                    <thead>
                      <tr>
                        <th className="text-center">Código Gestión</th>
                        <th className="text-center">Descripción</th>

                        <th className="text-center">Fecha Publicación</th>
                        <th className="text-center">
                          Fecha de Inicio para Envío
                        </th>
                        <th className="text-center">Fecha Límite para Envío</th>
                        <th className="text-center">Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{this.state.gestiones.codigo}</td>
                        <td>
                          {this.state.gestiones.descripcion?.toUpperCase()}
                        </td>
                        <td>{this.state.gestiones.fecha_publicacion}</td>

                        <td>{this.state.gestiones.fecha_recibir_inicio}</td>
                        <td>{this.state.gestiones.fecha_recibir_fin}</td>
                        <td>
                          {" "}
                          <div className="text-center">
                            <div className="btn-group-vertical">
                              <button
                                type="button"
                                className="btn btn-outline-secondary mr-2"
                                onClick={this.descargarInformeComprobantes.bind(
                                  this,
                                  this.state.gestiones.codigo
                                )}
                              >
                                <i className="fa fa-download mr-2"></i>
                                Descargar comprobantes
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-12">
                <h4 className="card-title m-t-10">Informes De Gestión</h4>
                <TablaFilter
                  buscador={false}
                  ref={"tabla"}
                  ruta={`gestiones_historico/informes/${this.props.match.params.gestion}`}
                  rowEvents={this.rowEvents}
                  identificador={"id"}
                  columns={this.columns}
                  titulo_tabla="Escriba el registro que desea buscar"
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 text-center">{this.loadingData()}</div>
            </div>
          )}
        </LayoutPanelTable>
        {this.state.archivo_informe !== null ? (
          <Iframe
            id="archivo_informe"
            url={`${this.state.archivo_informe}#toolbar=0&navpanes=0&statusbar=0`}
            width="90%"
            height="550px"
            display="none"
            position="relative"
          />
        ) : null}
      </div>
    );
  }
}
